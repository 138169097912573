var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid m-0 p-0 bfont shadow d-none d-lg-inline-block"},[_c('nav',{staticClass:"navbar navbar-expand-lg ",class:_vm.$cons.MODE === 'production'
        ? ("navcolor-" + (_vm.$store.getters['loginActions/GET_BU']))
        : ("navcolor-uat-" + (_vm.$store.getters['loginActions/GET_BU']))},[_c('div',{staticClass:"collapse navbar-collapse"},[_c('ul',{staticClass:"navbar-nav mr-auto row w-100 justify-content-around"},_vm._l((_vm.$store.getters['loginActions/GET_MENU']),function(e,i){return _c('li',{key:i + 'h',staticClass:"nav-item"},[_c('div',{staticClass:"m-0 p-0   align-items-center",class:[
              e.item.length > 0 ? 'dropdown dropdown-toggle' : '',
              ("navtitle-" + (_vm.$store.getters['loginActions/GET_BU'])) ],attrs:{"type":"button"},on:{"click":function($event){e.item.length == 0 ? _vm.goto(e.routename) : ''}}},[_c('span',{staticClass:"mx-1",domProps:{"innerHTML":_vm._s(e.icon)}}),_c('span',{staticClass:"m-0 p-0"},[_vm._v(_vm._s(e.name))]),_c('div',{staticClass:"dropdown-content m-0 p-0"},_vm._l((e.item),function(e1,i1){return _c('div',{key:i1 + 'b',staticClass:"m-0 px-0 py-2 ",class:("item-" + (_vm.$store.getters['loginActions/GET_BU']))},[_c('div',{staticClass:"m-0 p-0  ",class:[
                    e1.item.length > 0 ? 'dropdown-menu w-100' : '',
                    ("item-" + (_vm.$store.getters['loginActions/GET_BU'])) ],on:{"click":function($event){e1.item.length == 0 ? _vm.goto(e1.routename) : ''}}},[_c('span',{staticClass:"px-3 py-2 w-100"},[(_vm.$nav_menu.length / 2 <= i && e1.item.length > 0)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.icon.l)}}):_vm._e(),_vm._v(" "+_vm._s(e1.name)),(_vm.$nav_menu.length / 2 > i && e1.item.length > 0)?_c('span',{staticClass:"text-right",domProps:{"innerHTML":_vm._s(_vm.icon.r)}}):_vm._e()]),_c('div',{class:_vm.$nav_menu.length / 2 <= i
                        ? 'dropdown-menu-content-lelt'
                        : 'dropdown-menu-content-rigth'},_vm._l((e1.item),function(e2,i2){return _c('div',{key:i2 + 'body',staticClass:"m-0 p-0"},[_c('div',{staticClass:"m-0 py-2 px-3  ",class:("item-" + (_vm.$store.getters['loginActions/GET_BU'])),on:{"click":function($event){return _vm.goto(e2.routename)}}},[_vm._v(" "+_vm._s(e2.name)+" ")])])}),0)])])}),0)])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }