import jwt_decode from "jwt-decode";
import XLSX from "xlsx";
export const Base64 = {
  encode: function (s) {
    return btoa(unescape(encodeURIComponent(s)));
  },
  decode: function (s) {
    return decodeURIComponent(escape(atob(s)));
  },
};
export function saveCookie(name, value, days) {
  var date = new Date();
  if (days) date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  //n days
  else date.setTime(date.getTime() + 10 * 60 * 60 * 1000); //106 hour

  var expires = "; expires=" + date.toUTCString();
  //	var expires = "";
  document.cookie = name + "=" + value + expires + "; path=/";
}

export function saveCookiemin(name, value, min) {
  var date = new Date();

  date.setTime(date.getTime() + min * 60 * 1000);

  var expires = "; expires=" + date.toUTCString();
  //	var expires = "";
  document.cookie = name + "=" + value + expires + "; path=/";
}
export function saveCookiemintoken(name, value, min) {
  var date = new Date();

  date.setTime(date.getTime() + min * 60 * 1000);

  var expires = "; expires=" + date.toUTCString();
  //	var expires = "";
  document.cookie = name + "=" + Base64.encode(value) + expires + "; path=/";
}

export function readCookietoken(name) {
  try {
    let read = readCookie(name);

    if (read == null) {
      return null;
    }
    let de = Base64.decode(read);

    let getDataToken = jwt_decode(de);
    let getDate = Date.now();
    let getexp = getDataToken.exp * 1000;
    if (getDate >= getexp) {
      return null;
    }
    return getDataToken;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function check_exp(token) {
  try {
    let getDataToken = jwt_decode(token);
    let getDate = Date.now();
    let getexp = getDataToken.exp * 1000;
    if (getDate >= getexp) {
      return null;
    }
    return getDataToken;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function readCookie(name) {
  try {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  } catch (error) {
    return null;
  }
}
export function readCookieEncrypt(name) {
  try {
    let read = Base64.decode(readCookie(name));
    return read;
  } catch (error) {
    return null;
  }
}
export function saveCookieEncrypt(name, value, days) {
  try {
    saveCookie(name, Base64.encode(value), days);
    return;
  } catch (error) {
    return null;
  }
}
export function saveCookieEncryptMin(name, value, min) {
  try {

    saveCookiemin(name, Base64.encode(value), min);
    return;
  } catch (error) {
    return null;
  }
}
export const parseJwtByLocalStorage = (token) => {
  try {
    // let getDataToken = JSON.parse(atob(atob(atob(localStorage.getItem(token))).split('.')[1]));
    let getDataToken = jwt_decode(atob(atob(localStorage.getItem(token))));
    let getDate = Date.now();
    let getexp = getDataToken.exp * 1000;
    if (getDate >= getexp) {
      return null;
    }
    return getDataToken;
  } catch (e) {
    return null;
  }
};

export const decodeJwt = (token) => {
  try {
    //console.log(token);
    let getDataToken = jwt_decode(token);
    //console.log(getDataToken);
    return getDataToken;
  } catch (error) {
    return null;
  }
};

export const chechnull = (data) => {
  let check =
    data !== "" &&
      data !== "null" &&
      data !== null &&
      data !== undefined &&
      data !== "undefined"
      ? data
      : "";
  return check;
};
export const youtube = (v) => {
  let _chechnull = chechnull(v);

  if (_chechnull !== "") {
    let id;
    let position = String(v).includes("watch?v");
    if (position) {
      id = `${v.split("v=")[1].split("&")[0]}`;
    } else {
      id = `${v.split("/")[v.split("/").length - 1]}`;
    }

    return id;
  } else return "";
};

export const split_name = (data) => {
  let name = "";
  let _chechnull = chechnull(data);
  if (_chechnull !== "") {
    name = `${data.split(" ")[0]} ${data.split(" ")[1].substring(0, 1)}`;
  }

  return name;
};

export const parseJwtByCookie = (token) => {
  try {
    let getDataToken = jwt_decode(readCookie(token));
    let getDate = Date.now();
    let getexp = getDataToken.exp * 1000;
    if (getDate >= getexp) {
      return null;
    }
    return getDataToken;
  } catch (e) {
    return null;
  }
};

export function removeCookie(name) {
  saveCookie(name, "", -1);
}

export function showWarningAlert(_this, para_title, para_message) {
  _this.$swal.fire({
    icon: "warning",
    type: "warning",
    title: para_title,
    html: para_message,
  });
}
export function showWarningAlert1(_this, para_message) {
  _this.$swal.fire({
    icon: "warning",
    type: "warning",
    text: para_message,
  });
}

export function showSuccessAlert(_this, para_message) {
  _this.$swal.fire({
    icon: "success",
    title: "Success",
    text: para_message,
  });
}

export function showSuccessAlertWithTime(_this, para_message, para_time) {
  _this.$swal.fire({
    icon: "success",
    title: "Success",
    html: `<h2>${para_message}</h2>`,
    showConfirmButton: false,
    timer: para_time,
  });
}
export function showErrorAlert(_this, para_message) {
  console.log(para_message);
  _this.$swal.fire({
    icon: "error",
    title: "Oops...",
    text: para_message,
  });
}

export function showRejectionAlert(_this, Title, value1, value2) {
  return new Promise((resolve, reject) => {
    _this.$swal
      .fire({
        icon: "warning",
        title: Title,
        html: `<div style="text-align: left"> 
               <h5><b>ข้อมูลที่ขาด / ขอข้อมูลเพิ่มเติม</b></h5><hr>
               <div>${value1}</div>
               <br>
               <h5><b>ข้อมูลไม่สมบูรณ์</b></h5><hr>
               <div >${value2}</div>
               </div>`,
        confirmButtonColor: "#0064b7",
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function showConfirmAlert(_this, para_message) {
  return new Promise((resolve, reject) => {
    _this.$swal
      .fire({
        icon: "question",
        iconHtml: "?",
        text: para_message,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0064b7",
        cancelButtonColor: "#9b0000",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      })
      .then((result) => {
        if (result.value) {
          resolve(result.value);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function generateUID() {
  var seed = Date.now();
  if (window.performance && typeof window.performance.now === "function") {
    seed += performance.now();
  }

  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
    c
  ) {
    var r = (seed + Math.random() * 16) % 16 | 0;
    seed = Math.floor(seed / 16);

    return (c === "x" ? r : r & (0x3 | 0x8)).toString(16);
  });

  return uuid;
}

export function getMCID(para_MCID) {
  let getUUID = localStorage.getItem(para_MCID);
  if (!getUUID) {
    getUUID = generateUID();
    localStorage.setItem(para_MCID, getUUID);
  }
  return getUUID;
}
export function getStoreData(para_KEYSTORE) {
  let locationsTEMP = readCookieEncrypt(para_KEYSTORE).split("|");
  return locationsTEMP.map((e) => e.split(","));
}
export function Delay(time) {
  new Promise((resolve) => {
    setTimeout(() => {
      resolve("OK");
    }, time);
  });
}

export async function ReadExcel(file) {
  return new Promise(async (resolve, reject) => {
    try {
      let fname = file.name;
      fname = fname.toLowerCase();

      if (fname.indexOf(".xls") < 0) {
        throw `กรุณาตรวจสอบ format ไฟล์!!! file  ${fname}  is not excel file (.xls or .xlsx).`;
      }

      const reader = new FileReader();

      reader.onload = async (e) => {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        let rows = await XLSX.utils.sheet_to_row_object_array(ws);
        resolve(rows);
      };
      reader.readAsBinaryString(file);
    } catch (error) {
      reject(error);
    }
  });
}

export async function JsonToExcat(json, name) {
  return new Promise(async (resolve, reject) => {
    try {
      const dataWS = XLSX.utils.json_to_sheet(json);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      await XLSX.writeFile(wb, name);
      resolve("OK");
    } catch (error) {
      reject(error);
    }
  });
}

export async function JsonToExcatMulSheet(json, name) {
  return new Promise(async (resolve, reject) => {
    try {
      let arr = await split_object(json, 50000)
      let sheet = 1
      const wb = XLSX.utils.book_new();
      for await (let i of arr) {
        let dataWS = XLSX.utils.json_to_sheet(i);

        XLSX.utils.book_append_sheet(wb, dataWS, `Sheet${sheet}`);
        sheet++
      }

      await XLSX.writeFile(wb, name);
      resolve("OK");
    } catch (error) {
      reject(error);
    }
  });
}


export async function split_object(object, max) {
  //console.log(params);
  return new Promise(async (resolve, reject) => {
    const chunkSize = max;
    let chunk = []

    var array = Object.keys(object)
      .map(function (key) {
        return object[key];
      });

    for (let i = 0; i < array.length; i += chunkSize) {
      chunk.push(array.slice(i, i + chunkSize))
      // do whatever

    }
    resolve(chunk)

  })
}

export async function CheckDateFormat(date) {
  return new Promise(async (resolve, reject) => {
    let check = new Date(date);
    if (check == "Invalid Date") {
      resolve(false);
    }
    resolve(true);
  });
}

export async function CalDiffdate(start_date, end_date) {
  return new Promise(async (resolve, reject) => {
    let data;
    if (CheckDateFormat(start_date) && CheckDateFormat(end_date)) {
      let dt1 = new Date(start_date);
      let dt2 = new Date(end_date);

      let diff = Math.floor(
        (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
      );

      data = { diff: diff };
    }

    resolve(data);
  });
}
