<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <g id="freepik--Floor--inject-54">
      <ellipse
        id="freepik--floor--inject-54"
        cx="249.05"
        cy="350.02"
        rx="235.61"
        ry="136.03"
        style="fill:#f0f0f0"
      ></ellipse>
    </g>
    <g id="freepik--Shadow--inject-54">
      <path
        id="freepik--shadow--inject-54"
        d="M384.36,397.22c-15.45-8.92-15.45-23.38,0-32.3s40.5-8.92,55.95,0,15.45,23.38,0,32.3S399.81,406.14,384.36,397.22Z"
        style="fill:#e0e0e0"
      ></path>
      <path
        id="freepik--shadow--inject-54"
        d="M70.12,398.46,29.48,375c-2.29-1.32-2.29-3.47,0-4.79L265.87,233.73a9.17,9.17,0,0,1,8.29,0l40.65,23.46c2.29,1.32,2.29,3.47,0,4.79L78.42,398.46A9.19,9.19,0,0,1,70.12,398.46Z"
        style="fill:#e0e0e0"
      ></path>
    </g>
    <g id="freepik--Device--inject-54">
      <g id="freepik--device--inject-54">
        <path
          d="M216.2,356.64l34.94-20.2c3.6-2.08,5.4-4.8,5.4-7.53s0-4.86,0-4.86l-36.24-20.44a7.64,7.64,0,0,1-3.48-6V254.52l-61,35.26v43.07a7.7,7.7,0,0,0,3.48,6l30.84,17.76C197.32,360.8,209,360.8,216.2,356.64Z"
          style="fill:currentColor"
        ></path>
        <g style="opacity:0.6000000000000001">
          <path
            d="M216.2,356.64l34.94-20.2c3.6-2.08,5.4-4.8,5.4-7.53s0-4.86,0-4.86l-36.24-20.44a7.64,7.64,0,0,1-3.48-6V254.52l-61,35.26v43.07a7.7,7.7,0,0,0,3.48,6l30.84,17.76C197.32,360.8,209,360.8,216.2,356.64Z"
          ></path>
        </g>
        <path
          d="M170.45,340.48v4.84l-11.18-6.45a7.67,7.67,0,0,1-3.47-6V289.78l11.62-6.71c.24,2.95.5,6,.75,9L160,296.77v33.65a7.63,7.63,0,0,0,3.48,6Z"
          style="opacity:0.30000000000000004"
        ></path>
        <path
          d="M160,330.43a7.68,7.68,0,0,0,3.48,6l26.62,15.34c7.2,4.15,18.88,4.16,26.09,0l35-20.2c7.21-4.16,7.21-10.9,0-15.06l-26.64-15.34a7.69,7.69,0,0,1-3.47-6V261.51l-61,35.26Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M160,330.43a7.68,7.68,0,0,0,3.48,6l26.62,15.34c7.2,4.15,18.88,4.16,26.09,0l35-20.2c7.21-4.16,7.21-10.9,0-15.06l-26.64-15.34a7.69,7.69,0,0,1-3.47-6V261.51l-61,35.26Z"
          style="opacity:0.30000000000000004"
        ></path>
        <path
          d="M231.6,305.28h0l-7.09-4.09a7.69,7.69,0,0,1-3.47-6V261.51l-61,35.26v33.66a7.68,7.68,0,0,0,3.48,6l7,4Z"
          style="opacity:0.5"
        ></path>
        <path
          d="M256.55,324.06v4.86c0,2.73-1.81,5.44-5.4,7.53l-35,20.19a26.23,26.23,0,0,1-12.45,3.09v-4.84a26.23,26.23,0,0,0,12.45-3.09l35-20.21C254.74,329.52,256.55,326.79,256.55,324.06Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M256.55,324.06v4.86c0,2.73-1.81,5.44-5.4,7.53l-35,20.19a26.23,26.23,0,0,1-12.45,3.09v-4.84a26.23,26.23,0,0,0,12.45-3.09l35-20.21C254.74,329.52,256.55,326.79,256.55,324.06Z"
          style="opacity:0.5"
        ></path>
        <path
          d="M62,372.26a9.35,9.35,0,0,0,8.43-.15l236-136.25a9.62,9.62,0,0,0,4.35-7.53V37.41A9.32,9.32,0,0,0,306.72,30a9.3,9.3,0,0,0-8.42.16L62.3,166.45A9.58,9.58,0,0,0,58,174V364.89A9.32,9.32,0,0,0,62,372.26Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M70.46,372.1l236-136.25a9.59,9.59,0,0,0,4.35-7.53V37.41c0-2.77-1.95-3.9-4.35-2.51l-236,136.25a9.61,9.61,0,0,0-4.35,7.53V369.59C66.11,372.36,68.06,373.49,70.46,372.1Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M70.46,372.1l236-136.25a9.59,9.59,0,0,0,4.35-7.53V37.41c0-2.77-1.95-3.9-4.35-2.51l-236,136.25a9.61,9.61,0,0,0-4.35,7.53V369.59C66.11,372.36,68.06,373.49,70.46,372.1Z"
          style="opacity:0.5"
        ></path>
        <path
          d="M69.82,377.06A9.34,9.34,0,0,1,62,376.9,9.32,9.32,0,0,1,58,369.51V352.13l8.15,4.72v17.38C66.1,376.76,67.71,377.91,69.82,377.06Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M69.82,377.06A9.34,9.34,0,0,1,62,376.9,9.32,9.32,0,0,1,58,369.51V352.13l8.15,4.72v17.38C66.1,376.76,67.71,377.91,69.82,377.06Z"
          style="opacity:0.5"
        ></path>
        <path
          d="M66.1,356.85v17.38c0,2.77,2,3.89,4.35,2.51l236-136.25A9.62,9.62,0,0,0,310.8,233V215.54Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M66.11,356.81v17.42c0,2.77,2,3.89,4.35,2.51l236-136.25A9.62,9.62,0,0,0,310.8,233V215.54Z"
          style="opacity:0.30000000000000004"
        ></path>
        <path
          d="M74.26,335V181.39a5.75,5.75,0,0,1,2.6-4.52L300,48c1.44-.83,2.61-.16,2.61,1.5V203.19a5.76,5.76,0,0,1-2.61,4.52L76.87,336.55C75.43,337.38,74.26,336.7,74.26,335Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M74.26,335V181.39a5.75,5.75,0,0,1,2.6-4.52L300,48c1.44-.83,2.61-.16,2.61,1.5V203.19a5.76,5.76,0,0,1-2.61,4.52L76.87,336.55C75.43,337.38,74.26,336.7,74.26,335Z"
          style="fill:#263238"
        ></path>
        <path
          d="M188.45,291.73a9,9,0,0,0-4.08,7.06c0,2.6,1.83,3.66,4.08,2.36a9,9,0,0,0,4.08-7.07C192.53,291.48,190.7,290.43,188.45,291.73Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M67.39,174.38a9.25,9.25,0,0,0-1.29,4.29V356.85L58,352.13V174a8.9,8.9,0,0,1,1.28-4.3Z"
          style="opacity:0.7000000000000001"
        ></path>
        <path
          d="M310.76,36.69c-.31-2.24-2.12-3-4.31-1.79l-236,136.25a8.94,8.94,0,0,0-3.07,3.23l-8.16-4.71a9,9,0,0,1,3.08-3.23L298.3,30.2a9.32,9.32,0,0,1,8.43-.16A9.37,9.37,0,0,1,310.76,36.69Z"
          style="opacity:0.30000000000000004"
        ></path>
      </g>
    </g>
    <g id="freepik--Window--inject-54">
      <g id="freepik--window--inject-54">
        <g id="freepik--window--inject-54">
          <g id="freepik--window--inject-54">
            <path
              d="M323.49,60.19a2.16,2.16,0,0,0-2.22.17L98.59,189a6.71,6.71,0,0,0-3,5.27V346.94a3,3,0,0,0,1.29,2.65c.49.27,3.11,1.79,3.89,2.21a1.75,1.75,0,0,0,1.76-.1L325.16,223.06a6.71,6.71,0,0,0,3-5.27V64.33a2.1,2.1,0,0,0-.94-2C326.72,62,324.05,60.52,323.49,60.19Z"
              style="fill:currentColor"
            ></path>
            <path
              d="M102.48,191.16,325.17,62.57c1.68-1,3-.18,3,1.76V217.79a6.71,6.71,0,0,1-3,5.27L102.48,351.7c-1.68,1-3-.61-3-2.55V196.43A6.73,6.73,0,0,1,102.48,191.16Z"
              style="fill:#fafafa"
            ></path>
            <path
              d="M326.53,63.15h0c.59,0,.68.74.68,1.18V217.79a5.68,5.68,0,0,1-2.55,4.4L102,350.83a1,1,0,0,1-.5.16c-.56,0-1-.86-1-1.84V196.43A5.74,5.74,0,0,1,103,192L325.67,63.43a1.77,1.77,0,0,1,.86-.28m0-1a2.8,2.8,0,0,0-1.36.42L102.48,191.16a6.73,6.73,0,0,0-3,5.27V349.15c0,1.54.86,2.84,2,2.84a2,2,0,0,0,1-.29L325.16,223.06a6.71,6.71,0,0,0,3-5.27V64.33c0-1.38-.68-2.18-1.68-2.18Z"
              style="fill:#e0e0e0"
            ></path>
            <path
              d="M96.82,349.59c.33.17,2.7,1.52,3.74,2.12a3.11,3.11,0,0,1-1.12-2.56V206.4l-3.9-2.28V346.94A3,3,0,0,0,96.82,349.59Z"
              style="fill:#f0f0f0"
            ></path>
            <path
              d="M328.21,64.3c0-1.92-1.37-2.7-3-1.73L102.48,191.16a6.73,6.73,0,0,0-3,5.27v10L328.21,74.3Z"
              style="fill:currentColor"
            ></path>
            <g style="opacity:0.4">
              <path
                d="M325.17,62.57,102.48,191.15a6.25,6.25,0,0,0-2.14,2.27l-3.87-2.25A6,6,0,0,1,98.59,189L321.27,60.36a2.16,2.16,0,0,1,2.22-.18c.54.34,3.07,1.77,3.72,2.12A2.2,2.2,0,0,0,325.17,62.57Z"
                style="fill:#fff"
              ></path>
            </g>
            <path
              d="M95.54,204.13v-9.91a6.25,6.25,0,0,1,.93-3.05l3.88,2.24a6.08,6.08,0,0,0-.91,3v10Z"
              style="opacity:0.15"
            ></path>
          </g>
        </g>
        <path
          d="M281.25,179.42l-133.37,77a4.91,4.91,0,0,0-2.23,3.85v38.24a4.91,4.91,0,0,0,2.23,3.85l3.89,2.24a4.94,4.94,0,0,0,4.46,0l133.35-77a4.93,4.93,0,0,0,2.23-3.86l0-38.25a4.91,4.91,0,0,0-2.23-3.85l-3.9-2.24A4.91,4.91,0,0,0,281.25,179.42Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M291.81,185.15c-.16-1.15-1.09-1.57-2.21-.92l-133.37,77a4.49,4.49,0,0,0-1.57,1.66l-8.35-4.82a4.57,4.57,0,0,1,1.56-1.64l133.38-77a4.91,4.91,0,0,1,4.45,0l3.9,2.24A5,5,0,0,1,291.81,185.15Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M155.85,304.79a5,5,0,0,1-4.07-.18l-3.9-2.24a4.93,4.93,0,0,1-2.23-3.85V260.28a4.63,4.63,0,0,1,.66-2.21l8.35,4.82a4.48,4.48,0,0,0-.66,2.19v38.25C154,304.6,154.8,305.19,155.85,304.79Z"
          style="fill:#263238"
        ></path>
        <path
          d="M156.23,261.23l133.37-77c1.23-.71,2.23-.14,2.23,1.28l0,38.25a4.93,4.93,0,0,1-2.23,3.86l-133.35,77c-1.23.71-2.23.13-2.23-1.29V265.09A4.93,4.93,0,0,1,156.23,261.23Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M245.89,199.84l-98,56.59a4.57,4.57,0,0,0-1.56,1.64l8.35,4.82a4.49,4.49,0,0,1,1.57-1.66l98-56.58Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M254.23,204.65l-98,56.58a4.93,4.93,0,0,0-2.23,3.86v38.23c0,1.42,1,2,2.23,1.29l98-56.58Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M178.83,256.57l50.57-29.19c2.09-1.21,3.79-.35,3.79,1.92a8.13,8.13,0,0,1-3.8,6.3l-50.56,29.2c-2.09,1.21-3.8.35-3.8-1.92A8.16,8.16,0,0,1,178.83,256.57Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M189.19,266.24a.1.1,0,0,1,.15,0,.37.37,0,0,1,.06.23v5.69a9.54,9.54,0,0,1-.14,1.64,6.35,6.35,0,0,1-.42,1.44,5.11,5.11,0,0,1-.7,1.18,3.67,3.67,0,0,1-1,.83,1.85,1.85,0,0,1-1,.3,1,1,0,0,1-.7-.37,2.25,2.25,0,0,1-.42-1,6.91,6.91,0,0,1-.13-1.47v-5.7a.72.72,0,0,1,.06-.29.36.36,0,0,1,.15-.2l.63-.37a.11.11,0,0,1,.15,0,.35.35,0,0,1,.06.23v5.62a3.79,3.79,0,0,0,.09.92,1,1,0,0,0,.24.52.45.45,0,0,0,.37.16,1,1,0,0,0,.48-.16,2.16,2.16,0,0,0,.48-.39,2.37,2.37,0,0,0,.38-.6,3.77,3.77,0,0,0,.25-.8,5.28,5.28,0,0,0,.08-1v-5.63a.73.73,0,0,1,.07-.29.36.36,0,0,1,.15-.2Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M193.11,271.62a.36.36,0,0,0-.05-.21.22.22,0,0,0-.19-.07,1.37,1.37,0,0,0-.37,0l-.58.11a1.18,1.18,0,0,1-.57,0,.69.69,0,0,1-.36-.27,1.43,1.43,0,0,1-.19-.53,4.52,4.52,0,0,1,0-1.62,4.49,4.49,0,0,1,.32-.89,4.32,4.32,0,0,1,.5-.8,2.51,2.51,0,0,1,.68-.59,1.73,1.73,0,0,1,.69-.24.84.84,0,0,1,.51.11.83.83,0,0,1,.33.38,1.59,1.59,0,0,1,.13.59.49.49,0,0,1-.06.29.36.36,0,0,1-.15.2l-.6.35a.18.18,0,0,1-.18,0l-.13-.1a.33.33,0,0,0-.18-.07.82.82,0,0,0-.36.13,1.54,1.54,0,0,0-.41.36.87.87,0,0,0-.18.55.63.63,0,0,0,0,.23.23.23,0,0,0,.17.1,1,1,0,0,0,.33,0l.56-.1c.47-.09.79,0,1,.26a2.1,2.1,0,0,1,.29,1.23,3.51,3.51,0,0,1-.12.88,4.27,4.27,0,0,1-.34.9,3.8,3.8,0,0,1-.55.82,2.83,2.83,0,0,1-.73.61,1.63,1.63,0,0,1-.73.25.9.9,0,0,1-.53-.15.92.92,0,0,1-.34-.43,1.5,1.5,0,0,1-.12-.6.54.54,0,0,1,.06-.3.36.36,0,0,1,.15-.2l.6-.35c.08,0,.14-.05.18,0l.13.14a.3.3,0,0,0,.21.1.64.64,0,0,0,.39-.13,1.87,1.87,0,0,0,.48-.39,1.24,1.24,0,0,0,.17-.27A.6.6,0,0,0,193.11,271.62Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M195.15,268.91a8.93,8.93,0,0,1,.13-1.55,7.46,7.46,0,0,1,.39-1.41,4.8,4.8,0,0,1,.6-1.15,2.57,2.57,0,0,1,.8-.75,1.09,1.09,0,0,1,.79-.17,1,1,0,0,1,.6.41,2.5,2.5,0,0,1,.39.9,5.58,5.58,0,0,1,.14,1.28v.62a.6.6,0,0,1-.07.3.36.36,0,0,1-.15.2l-2.6,1.51a1.56,1.56,0,0,0,.07.52.65.65,0,0,0,.2.29.42.42,0,0,0,.29.09.82.82,0,0,0,.34-.12,1.45,1.45,0,0,0,.4-.32,1.83,1.83,0,0,0,.26-.38,2.65,2.65,0,0,1,.14-.23.64.64,0,0,1,.15-.13l.62-.36a.12.12,0,0,1,.15,0,.28.28,0,0,1,.06.23,1.93,1.93,0,0,1-.12.57,4.34,4.34,0,0,1-.34.8,4.59,4.59,0,0,1-.56.82,3,3,0,0,1-.76.65,1.21,1.21,0,0,1-.8.19,1,1,0,0,1-.6-.42,2.65,2.65,0,0,1-.39-1A6.8,6.8,0,0,1,195.15,268.91Zm1.92-3.19a1.17,1.17,0,0,0-.39.34,1.92,1.92,0,0,0-.27.46,3.39,3.39,0,0,0-.17.51,3.27,3.27,0,0,0-.07.48l1.76-1a2.9,2.9,0,0,0-.06-.41.66.66,0,0,0-.14-.33.38.38,0,0,0-.26-.16A.6.6,0,0,0,197.07,265.72Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M202.13,262.93a1.61,1.61,0,0,0-.68.76,3.44,3.44,0,0,0-.21,1.32v3.61a.73.73,0,0,1-.06.3.36.36,0,0,1-.15.2l-.59.34a.1.1,0,0,1-.15,0,.37.37,0,0,1-.06-.23v-6.47a.8.8,0,0,1,.06-.3.41.41,0,0,1,.15-.2l.59-.34a.11.11,0,0,1,.15,0,.32.32,0,0,1,.06.22v.32a4.12,4.12,0,0,1,.44-.76,1.82,1.82,0,0,1,.6-.53l.36-.21a.1.1,0,0,1,.14,0,.37.37,0,0,1,.07.23v.91a.73.73,0,0,1-.07.3.41.41,0,0,1-.14.2Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M209.59,262.45a.1.1,0,0,1,.15,0,.37.37,0,0,1,.06.23v1a.8.8,0,0,1-.06.3.52.52,0,0,1-.15.19l-3.42,2a.11.11,0,0,1-.15,0,.34.34,0,0,1-.06-.22v-9a.8.8,0,0,1,.06-.3.38.38,0,0,1,.15-.19l.63-.37a.11.11,0,0,1,.15,0,.34.34,0,0,1,.06.22v7.61Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M212.66,255.05a1.2,1.2,0,0,1,.77-.21.92.92,0,0,1,.61.3,1.92,1.92,0,0,1,.4.74,4.28,4.28,0,0,1,.18,1.09c0,.08,0,.19,0,.32v.77c0,.12,0,.23,0,.32a7,7,0,0,1-.18,1.29,6.26,6.26,0,0,1-.4,1.2,4.46,4.46,0,0,1-.61,1,2.76,2.76,0,0,1-.77.69,1.31,1.31,0,0,1-.78.21.91.91,0,0,1-.6-.3,1.7,1.7,0,0,1-.4-.73,4.28,4.28,0,0,1-.18-1.09c0-.08,0-.18,0-.31v-.77c0-.13,0-.24,0-.32a6.35,6.35,0,0,1,.17-1.3,6.62,6.62,0,0,1,.41-1.21,4.83,4.83,0,0,1,.6-1A2.66,2.66,0,0,1,212.66,255.05Zm.94,2.58a2,2,0,0,0-.11-.65.62.62,0,0,0-.22-.32.43.43,0,0,0-.29-.07.87.87,0,0,0-.32.13,1.41,1.41,0,0,0-.32.24,1.79,1.79,0,0,0-.29.4,2.19,2.19,0,0,0-.22.58,4,4,0,0,0-.12.78c0,.08,0,.17,0,.28v.71c0,.11,0,.2,0,.26a2.36,2.36,0,0,0,.12.65.61.61,0,0,0,.22.32.37.37,0,0,0,.29.06.9.9,0,0,0,.32-.12,1.67,1.67,0,0,0,.32-.24,2.17,2.17,0,0,0,.29-.4,2.6,2.6,0,0,0,.22-.58,3.57,3.57,0,0,0,.11-.78,2.18,2.18,0,0,0,0-.28v-.7A2.1,2.1,0,0,0,213.6,257.63Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M217.68,260.9a1.42,1.42,0,0,0,.34-.29,2.45,2.45,0,0,0,.31-.46,3.08,3.08,0,0,0,.23-.63,3,3,0,0,0,.09-.75v-.51c0,.1-.1.22-.17.36a3,3,0,0,1-.24.42,4,4,0,0,1-.34.4,1.76,1.76,0,0,1-.43.33.92.92,0,0,1-.71.14,1,1,0,0,1-.54-.41,2.72,2.72,0,0,1-.35-.84,5.75,5.75,0,0,1-.14-1.15c0-.13,0-.29,0-.48s0-.35,0-.49a8.45,8.45,0,0,1,.14-1.31,7.89,7.89,0,0,1,.35-1.25,5,5,0,0,1,.54-1,2.28,2.28,0,0,1,.71-.68,1.41,1.41,0,0,1,.43-.17.93.93,0,0,1,.34,0,.62.62,0,0,1,.24.13.91.91,0,0,1,.17.17v-.32a.8.8,0,0,1,.06-.3.38.38,0,0,1,.15-.19l.59-.35a.11.11,0,0,1,.15,0,.37.37,0,0,1,.07.23V258a6.86,6.86,0,0,1-.53,2.84,3.63,3.63,0,0,1-1.46,1.7,1.54,1.54,0,0,1-.74.23,1,1,0,0,1-.59-.15,1.35,1.35,0,0,1-.4-.48,2,2,0,0,1-.16-.72.54.54,0,0,1,.06-.3.36.36,0,0,1,.15-.2l.53-.3a.13.13,0,0,1,.19,0,1,1,0,0,1,.13.17.47.47,0,0,0,.28.22C217.25,261.1,217.43,261,217.68,260.9Zm-.94-4.85q0,.4,0,.78a1.7,1.7,0,0,0,.32,1.12.49.49,0,0,0,.64,0,1.72,1.72,0,0,0,.65-.78,3.62,3.62,0,0,0,.3-1.32c0-.15,0-.33,0-.54s0-.39,0-.52a1.53,1.53,0,0,0-.3-1,.48.48,0,0,0-.65,0,1.73,1.73,0,0,0-.64.77A3.83,3.83,0,0,0,216.74,256.05Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M222,247.17a.11.11,0,0,1,.14,0,.32.32,0,0,1,.07.23v1.09a.66.66,0,0,1-.07.3.43.43,0,0,1-.14.19l-.67.39a.1.1,0,0,1-.15,0,.29.29,0,0,1-.07-.22v-1.09a.66.66,0,0,1,.07-.3.38.38,0,0,1,.15-.19Zm.17,9.39a.72.72,0,0,1-.06.29.49.49,0,0,1-.15.2l-.6.34a.09.09,0,0,1-.14,0,.32.32,0,0,1-.07-.23v-6.47a.66.66,0,0,1,.07-.3.47.47,0,0,1,.14-.2l.6-.34a.11.11,0,0,1,.15,0,.34.34,0,0,1,.06.22Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M227.43,253.51a.8.8,0,0,1-.06.3.38.38,0,0,1-.15.19l-.6.35a.1.1,0,0,1-.14,0,.32.32,0,0,1-.07-.23v-3.52a2.45,2.45,0,0,0-.22-1.21c-.14-.25-.36-.29-.67-.11a1.64,1.64,0,0,0-.65.87,4.2,4.2,0,0,0-.24,1.47v3.53a.8.8,0,0,1-.06.3.36.36,0,0,1-.15.2l-.59.34a.1.1,0,0,1-.15,0,.32.32,0,0,1-.06-.22v-6.47a.73.73,0,0,1,.06-.3.36.36,0,0,1,.15-.2l.59-.34a.1.1,0,0,1,.15,0,.37.37,0,0,1,.06.23V249a5.15,5.15,0,0,1,.48-.87,1.88,1.88,0,0,1,.65-.61,1.09,1.09,0,0,1,.79-.2.81.81,0,0,1,.52.41,2.54,2.54,0,0,1,.28.87,9,9,0,0,1,.08,1.2Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M277.87,224.4,268.16,230l1.51-13.62c-1.17-.62-1.91-2.13-1.91-4.3,0-3.87,2.36-8.36,5.26-10s5.25.1,5.25,4a13.26,13.26,0,0,1-1.9,6.5Z"
          style="fill:#455a64"
        ></path>
        <polygon
          points="277.88 224.4 269.33 219.47 268.16 230.01 277.88 224.4"
          style="fill:#37474f"
        ></polygon>
        <path
          d="M273,202.05a11.45,11.45,0,0,0-4.48,5.95l7.83,4.52h0a13.26,13.26,0,0,0,1.9-6.5C278.27,202.15,275.92,200.38,273,202.05Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M225.8,156.7c-3.33-1.92-7.92-1.65-13,1.28-10.14,5.86-18.36,20.09-18.36,31.8,0,5.88,2.08,10,5.42,11.9l6.47,3.76c3.32,1.92,7.91,1.64,13-1.29,10.14-5.85,18.36-20.09,18.36-31.8,0-5.85-2.06-10-5.38-11.87"
          style="fill:currentColor"
        ></path>
        <path
          d="M219.32,161.75c-10.14,5.86-18.36,20.1-18.36,31.8s8.22,16.46,18.36,10.6,18.37-20.09,18.36-31.8S229.45,155.9,219.32,161.75Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M229.45,187.73v1.34a29.53,29.53,0,0,1-10.13,10.62c-3.95,2.27-7.52,2.52-10.14,1.07V198a18,18,0,0,1,8.26-14.31l2.67-1.54c2.36-1.36,4.66-1.54,6.46-.49S229.45,184.92,229.45,187.73Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M225.8,156.7l6.5,3.78c-3.32-1.93-7.91-1.66-13,1.27-6.64,3.84-12.46,11.27-15.68,19.23l-6.51-3.77c3.23-8,9-15.39,15.68-19.23C217.89,155.06,222.48,154.78,225.8,156.7Z"
          style="fill:#fff;opacity:0.4"
        ></path>
        <path
          d="M206.1,205.29l-6.23-3.61c-3.34-1.9-5.42-6-5.42-11.9l6.51,3.78C201,199.26,202.92,203.32,206.1,205.29Z"
          style="opacity:0.2"
        ></path>
        <path
          d="M197.13,177.21l6.51,3.77A34.11,34.11,0,0,0,201,193.55h0l-6.51-3.78A34.23,34.23,0,0,1,197.13,177.21Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M217,181.52a3.49,3.49,0,0,1-1.77-.46,4.33,4.33,0,0,1-1.91-4,11.42,11.42,0,0,1,5.24-9.08h0a3.92,3.92,0,0,1,6.32,3.66,11.44,11.44,0,0,1-5.24,9.08A5.29,5.29,0,0,1,217,181.52Z"
          style="fill:#fff"
        ></path>
        <path
          d="M226.57,181.65c-1.8-1.05-4.1-.87-6.46.49l-2.67,1.54A18,18,0,0,0,209.18,198v2.77c2.62,1.45,6.19,1.2,10.14-1.07a29.53,29.53,0,0,0,10.13-10.62v-1.34C229.45,184.92,228.41,182.71,226.57,181.65Z"
          style="fill:#fff"
        ></path>
      </g>
    </g>
    <g id="freepik--Character--inject-54">
      <g id="freepik--character--inject-54">
        <path
          d="M397.37,226.23c-4.2,7.1-8.24,13.27-11.08,16.23-5.39,2.5-18.05,6.36-23,7a3.65,3.65,0,0,1-2.48-.58c-1.95-1.28-3.46-1.49-5.51-2.2-1.61-.55-2.29-1.32-2.59-1a1.83,1.83,0,0,0,.17,2.55,13.52,13.52,0,0,0,2.54,1.9,15,15,0,0,1-6.7.12c-4.29-1.13-6.31.93-3.41,5.59,1,1.66,5.12,4.33,13.4,3.61a75,75,0,0,0,8.81-1c7.94-1.7,16.77-3.34,22.2-5a14,14,0,0,0,5.28-3.66c9.13-10.41,12.16-17.83,16.16-24.57l-.34-11C404.34,214,400.88,220.29,397.37,226.23Z"
          style="fill:#ffa8a7"
        ></path>
        <path
          d="M411.93,214a10.41,10.41,0,0,0-9.9,4.23c-4.44,5.69-12.3,19.35-12.3,19.35s2.57,6.85,9.51,8l11.15-15.06S415.67,220.06,411.93,214Z"
          style="fill:#455a64"
        ></path>
        <rect
          x="428.77"
          y="367.54"
          width="7.27"
          height="12.96"
          style="fill:#ffa8a7"
        ></rect>
        <polygon
          points="410.84 366.85 403.58 367.11 403.21 353.39 410.84 353.13 410.84 366.85"
          style="fill:#ffa8a7"
        ></polygon>
        <path
          d="M437.06,255.35c0,7.22-1.06,65.39-1.06,65.39.16,2.08,2,5.92,1.63,13.91-.55,10.85-1.28,39.37-1.28,39.37a9.24,9.24,0,0,1-8.09.16s-5.9-40-7.2-50.71C419.92,314.08,417,283,417,283l-3.93,36.74a45.23,45.23,0,0,1,.9,12c-.34,5-2.68,30.88-2.68,30.88a9.61,9.61,0,0,1-8.39.67s-4.14-39-4.22-43.58c-.1-5.2,2.42-64.42,2.42-64.42Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M428.77,376.76v-1.08c-.7.31-2.31,6.73-5.2,9.47-2.29,2.18-5.41,4.69-6.21,7.12s4.35,3.92,6.5,3.53c2.48-.46,6.8-2.29,7.88-4s1.59-4.19,2.47-5.43,2.91-2.62,3.38-4.06a9.62,9.62,0,0,0-.41-4c-.34-1.34-.7-2.81-1.14-2.68v.82a5.64,5.64,0,0,1-3.64,1.28C431.34,377.82,428.76,377.6,428.77,376.76Z"
          style="fill:#263238"
        ></path>
        <path
          d="M403.5,364.77c-.38,0-.69.57-1.52,1.51a16,16,0,0,1-4.27,3.2c-2.72,1.42-8,3.6-10.41,4.73-1.5.7-1.43,2.58.12,3.65s5.28,1.84,9.76.8c2.44-.57,5.62-2.92,7.64-2.79s5.77.09,7.26-1,.89-3.35.47-5.3c-.46-2.14-1-5.47-1.71-5.32V365c-.87,1.38-5.38,1.94-7.3.93Z"
          style="fill:#263238"
        ></path>
        <path
          d="M417,283a26.72,26.72,0,0,1-10.12-6.05s2,3.81,7.76,7.36l-1.24,32.81Z"
          style="fill:#263238"
        ></path>
        <path
          d="M433,215.06s6,1.38,5.57,12.22l-1.25,17.25,0,25.9c-5.34,6.06-29,5.62-37.15-.48,0,0,.92-30.65,1.47-42.45.39-8.38,2.32-12.6,10.56-13.52l10.13.44Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M425.36,192.54a11,11,0,0,1-10.82,11.26c-6,0-9.52-5.18-10.82-11.26-2.4-11.13,4.84-11.27,10.82-11.27A11.05,11.05,0,0,1,425.36,192.54Z"
          style="fill:#263238"
        ></path>
        <path
          d="M430.88,197.09s-1.66,8.12-2.33,9.46a3.53,3.53,0,0,1-2,1.71l.12-5.71Z"
          style="fill:#263238"
        ></path>
        <path
          d="M428.79,185.46s1.19-.3,2.46,1.68c1.08,1.69,1.33,5.49-.37,10l-3.13.38Z"
          style="fill:#263238"
        ></path>
        <path
          d="M429.94,187.11l3.84.07a1.81,1.81,0,0,0-1.75-2A2.13,2.13,0,0,0,429.94,187.11Z"
          style="fill:#263238"
        ></path>
        <path
          d="M430.41,186.2l2.84-2.73a1.83,1.83,0,0,0-2.69-.11A2.1,2.1,0,0,0,430.41,186.2Z"
          style="fill:#263238"
        ></path>
        <path
          d="M426.81,197.47c.63.39,1.52-.86,2.3-1.71s3.2-1.63,4.46,1.13-.89,5.49-2.69,6.39c-3.23,1.6-4.25-.73-4.25-.73l-.27,12.86c-4.54,7-14.84,6.78-11.13-.34v-3.91a20.54,20.54,0,0,1-4.62.24c-2.52-.4-4.1-2.47-4.87-5.31-1.22-4.56-1.69-8.24-.65-17.21,1.14-9.82,12.12-9.93,18-6S426.81,197.47,426.81,197.47Z"
          style="fill:#ffa8a7"
        ></path>
        <path
          d="M413.13,187.35c2.62-.26,5.21-.8,7.84-1a5.1,5.1,0,0,1,1.7.08c2,.55,1.76,2.89,2.08,4.52.55,2.82,1,7.59,2.06,7.61.32,0,1.52-2.07,2.3-2.81a4.13,4.13,0,0,0,.7-2.15c.2-1.16.3-2.35.39-3.53.08-1,.18-2,.18-3a14.08,14.08,0,0,0-.27-3.37,5.89,5.89,0,0,0-1.4-2.52l-.2-.22a13.27,13.27,0,0,0-4.69-3.16,28.33,28.33,0,0,0-7.94-1.73,78,78,0,0,1-8.09-1,.84.84,0,0,0-.47,0,.74.74,0,0,0-.38.6,3.34,3.34,0,0,0,1.35,3.3c-1.81.14-3.89-.8-5.63-1.28a1.14,1.14,0,0,0-.89,0,.88.88,0,0,0-.35.51,3.18,3.18,0,0,0-.05,1.44,8.28,8.28,0,0,0,1.56,4,9.76,9.76,0,0,0,5.4,3.53A15.76,15.76,0,0,0,413.13,187.35Z"
          style="fill:#263238"
        ></path>
        <path
          d="M415.24,211.16s5.62-1.18,7.58-2.27a6.62,6.62,0,0,0,2.72-2.8,9.35,9.35,0,0,1-1.55,3.3c-1.43,1.92-8.75,3.32-8.75,3.32Z"
          style="fill:#f28f8f"
        ></path>
        <path
          d="M416.59,196a1.17,1.17,0,0,0,1.12,1.21,1.22,1.22,0,1,0-1.12-1.21Z"
          style="fill:#263238"
        ></path>
        <path
          d="M418.39,191.15l2.41,1.39a1.53,1.53,0,0,0-.56-2A1.32,1.32,0,0,0,418.39,191.15Z"
          style="fill:#263238"
        ></path>
        <path
          d="M415.74,204.11l-2.57,1a1.34,1.34,0,0,0,1.75.88A1.5,1.5,0,0,0,415.74,204.11Z"
          style="fill:#f28f8f"
        ></path>
        <path
          d="M406.25,192.18l2.2-1.76a1.3,1.3,0,0,0-1.91-.31A1.57,1.57,0,0,0,406.25,192.18Z"
          style="fill:#263238"
        ></path>
        <path
          d="M407.22,195.67a1.13,1.13,0,1,0,1.13-1.22A1.17,1.17,0,0,0,407.22,195.67Z"
          style="fill:#263238"
        ></path>
        <polygon
          points="413.25 194.73 413.12 201.84 409.08 200.92 413.25 194.73"
          style="fill:#f28f8f"
        ></polygon>
        <polyline
          points="317.31 254.28 323.58 250.66 326.06 247.65 319.8 251.27 317.31 254.28"
          style="fill:currentColor"
        ></polyline>
        <polyline
          points="317.31 254.28 323.58 250.66 326.06 247.65 319.8 251.27 317.31 254.28"
          style="opacity:0.4"
        ></polyline>
        <polyline
          points="334.36 264.12 340.63 260.5 345.86 255.87 339.6 259.48 334.36 264.12"
          style="fill:currentColor"
        ></polyline>
        <polyline
          points="334.36 264.12 340.63 260.5 345.86 255.87 339.6 259.48 334.36 264.12"
          style="opacity:0.4"
        ></polyline>
        <path
          d="M387.62,276.61a13.29,13.29,0,0,1,2.59,6.82A13.43,13.43,0,0,1,387.62,276.61Zm14.57.19c-2.11-14.35-9.82-28.54-19-33.85-4.41-2.55-8.49-2.64-11.76-.73l-5.9,3.4-.33.2a7.48,7.48,0,0,0-.76.48,10.24,10.24,0,0,0-1.36,1.12l-.2.19c-.18.18-.37.39-.54.59s-.43.51-.63.78a14.83,14.83,0,0,0-1.15,1.76c-.18.31-.35.64-.51,1L308.52,222l-6.26,3.61v4l.53.3.5.29V231l-1,.59v2.32l7.57,16,7.48,4.33,2.49-3,6.6,3.95,3.79,6.49,4.18,2.41,5.23-4.64,6.42,3.71,2.16,8.91L359,278.31a58.69,58.69,0,0,0,2,6.69c.07.22.16.45.25.7,3.58,9.4,9.27,17.45,15.69,21.16a16.09,16.09,0,0,0,2.41,1.17,12.56,12.56,0,0,0,1.35.45q.6.16,1.2.27a10.65,10.65,0,0,0,3.82,0l.52-.13a4.68,4.68,0,0,0,.73-.21l.56-.22a3.29,3.29,0,0,0,.57-.27,5.44,5.44,0,0,0,.54-.27l.26-.16a.42.42,0,0,0,.13-.07c1.43-.82,4.95-2.86,5.91-3.4,4.76-2.76,7.84-9.74,7.84-19.61A51.47,51.47,0,0,0,402.19,276.8Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M385.79,288.72c-2.48-1.43-4.48-5.67-4.48-9.47s2-5.73,4.48-4.31,4.47,5.67,4.47,9.48S388.26,290.15,385.79,288.72Zm10.13-8.31c-2.1-14.34-9.81-28.54-19-33.84-4.26-2.46-8.2-2.64-11.4-.95l-.33.2a7.48,7.48,0,0,0-.76.48,10.24,10.24,0,0,0-1.36,1.12l-.2.19c-.18.18-.37.39-.54.59s-.43.51-.63.78a14.83,14.83,0,0,0-1.15,1.76c-.18.31-.35.64-.51,1a24.33,24.33,0,0,0-2,6.09l-55.79-32.22v4l.53.3.5.29V231l-1,.59v2.32l7.57,16,7.48,4.33,2.49-3,6.6,3.95,3.79,6.49,4.18,2.41,5.23-4.64,6.42,3.71,2.16,8.91L359,278.31a58.69,58.69,0,0,0,2,6.69c.07.22.16.45.25.7,3.58,9.4,9.27,17.45,15.69,21.16a16.09,16.09,0,0,0,2.41,1.17,12.56,12.56,0,0,0,1.35.45q.6.16,1.2.27a10.65,10.65,0,0,0,3.82,0l.52-.13a4.68,4.68,0,0,0,.73-.21l.56-.22a3.29,3.29,0,0,0,.57-.27,5.44,5.44,0,0,0,.54-.27l.26-.16a.42.42,0,0,0,.13-.07c4.56-2.88,7.49-9.76,7.49-19.39A53.13,53.13,0,0,0,395.92,280.41Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M390.26,284.41c0,3.81-2,5.74-4.47,4.31s-4.48-5.66-4.48-9.47,2-5.74,4.48-4.31a6.38,6.38,0,0,1,1.83,1.68,13.38,13.38,0,0,0,2.59,6.82A7.88,7.88,0,0,1,390.26,284.41Z"
          style="opacity:0.4"
        ></path>
        <path
          d="M402.76,284.4c0,9.87-3.08,16.85-7.84,19.61-1,.54-4.48,2.58-5.91,3.4,4.56-2.88,7.49-9.76,7.49-19.39a53.13,53.13,0,0,0-.58-7.61c-2.1-14.34-9.81-28.54-19-33.84-4.26-2.46-8.2-2.64-11.4-.95l5.9-3.4c3.27-1.91,7.35-1.82,11.76.73,9.19,5.31,16.9,19.5,19,33.85A51.47,51.47,0,0,1,402.76,284.4Z"
          style="fill:#fff;opacity:0.4"
        ></path>
        <path
          d="M396.5,288c0,9.63-2.93,16.51-7.49,19.39,1.43-.82,4.95-2.86,5.91-3.4,4.76-2.76,7.84-9.74,7.84-19.61,0-.36,0-.73,0-1.1l-6.26,3.62C396.49,287.29,396.5,287.65,396.5,288Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M396.5,288c0,9.63-2.93,16.51-7.49,19.39,1.43-.82,4.95-2.86,5.91-3.4,4.76-2.76,7.84-9.74,7.84-19.61,0-.36,0-.73,0-1.1l-6.26,3.62C396.49,287.29,396.5,287.65,396.5,288Z"
          style="opacity:0.1"
        ></path>
        <polygon
          points="358.28 261.92 358.28 263.95 302.26 231.61 303.29 231.01 303.29 230.17 358.28 261.92"
          style="opacity:0.15"
        ></polygon>
        <polygon
          points="358.28 262.76 358.28 263.95 302.26 231.61 303.29 231.01 303.29 231 358.28 262.76"
          style="opacity:0.15"
        ></polygon>
        <path
          d="M382.64,304a12,12,0,0,1-6-1.79c-9.13-5.27-16.56-20.95-16.56-34.95,0-8.17,2.47-14.27,6.78-16.76,3-1.74,6.55-1.52,10.28.63,9.12,5.27,16.55,21,16.55,35,0,8.16-2.47,14.27-6.78,16.75A8.51,8.51,0,0,1,382.64,304Zm-11.45-53.65a7.56,7.56,0,0,0-3.8,1c-3.93,2.27-6.28,8.22-6.28,15.9,0,13.68,7.2,29,16.06,34.08,3.39,2,6.6,2.18,9.27.64,3.93-2.27,6.28-8.21,6.28-15.89,0-13.69-7.2-29-16.05-34.09A11,11,0,0,0,371.19,250.39Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M360,251.72a24.33,24.33,0,0,0-2,6.09l-55.79-32.22,6.26-3.61Z"
          style="fill:#fff;opacity:0.4"
        ></path>
        <path
          d="M432.51,254.85c2.92-5.53,6.26-17.95,7.92-24.22,2.36-8.9-1.46-14.5-5.8-14.73-2.71.42-11.64,32.89-11.64,32.89-1,.79-14.57,7.68-18.93,9.41a3.42,3.42,0,0,1-2.42,0c-2.09-.76-3.52-.62-5.57-.82-1.61-.16-2.42-.73-2.63-.39a1.84,1.84,0,0,0,.79,2.37,13.14,13.14,0,0,0,2.8,1.22,17.09,17.09,0,0,1-5.41.73c-4.2-.09-5.71,1.73-2.45,6,2,2.66,5.31,3.5,12.7.95,2.12-.74,5-2.17,7.69-3.14,5.54-2,7-2.24,11.22-3.67C426.93,259.37,431.21,257.31,432.51,254.85Z"
          style="fill:#ffa8a7"
        ></path>
        <path
          d="M433,215.06c2.55.35,5,.92,6.51,3s2.72,5,1.24,12.95a124.3,124.3,0,0,1-4.54,16.48s-5.94,2.54-12.25-3.9l4.34-16.78S429,215.9,433,215.06Z"
          style="fill:#455a64"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
