import * as main from "../../services/mainservice";
import * as constant from "../../services/constant";
import * as serviceAPI from "../../services/APIservice";

export const enrichproductdetailActions = {
  namespaced: true,
  state: {
    data: null,
    att: null,
  },
  mutations: {
    mut_data(state, value) {
      state.data = value;
    },
    mut_att(state, value) {
      state.att = value;
    },
  },
  getters: {
    get_data(state) {
      return state.data;
    },
    get_att(state) {
      return state.att;
    },
  },
  actions: {
    async setnull({ commit }) {
      commit("mut_data", null);
      commit("mut_att", null);
    },
    async update_enrich({ commit }, value) {
      return new Promise(async (resolve, reject) => {
        try {
          let getAPI = await serviceAPI.call_API(
            "post",
            `update/enrich`,
            value,
            1
          );
          resolve("OK");
        } catch (error) {
          console.log(error);
          resolve(error);
        }
      });
    },
    async getpimenrichproduct_data({ commit }, value) {
      try {
        commit("mut_data", null);
        let data = {
          mode: "data",
          skcode: value,
        };

        let getAPI = await serviceAPI.call_API(
          "post",
          `product/getpimenrichproduct`,
          data,
          1
        );

        if (getAPI.data.length == 0) {
          throw `Data Not Found Product`;
        }
        getAPI.data[0].longDescEN = getAPI.data[0].longDescEN_1;
        getAPI.data[0].longDescTH = getAPI.data[0].longDescTH_1;
        const {
          linkForVdo,
          LinkforVDO2,
          images,
          ImageForFeed,
        } = getAPI.data[0];

        getAPI.data[0].linkForVdo_old = linkForVdo;
        getAPI.data[0].LinkforVDO2_old = LinkforVDO2;
        getAPI.data[0].images_old = images;

        getAPI.data[0].fileUploads =
          (await main.chechnull(images)) !== ""
            ? await setfileUploads(images)
            : [];

        let fileUploadsmain = [];
        if ((await main.chechnull(images)) !== "") {
          fileUploadsmain.push(getAPI.data[0].fileUploads[0]);
        }
        getAPI.data[0].fileUploadsmain = fileUploadsmain;
        getAPI.data[0].video = await getvideo([linkForVdo, LinkforVDO2]);
        // // -------------------------- end img ---------------------------------
        const { CATMMS, CATTWD, CATBNB, spcode } = getAPI.data[0];
        getAPI.data[0].CATMMS = CATMMS == null ? ":" : CATMMS;
        getAPI.data[0].CATTWD = CATTWD == null ? ":" : CATTWD;
        getAPI.data[0].CATBNB = CATBNB == null ? ":" : CATBNB;

        // ---------------- delivery_method------------------
        getAPI.data[0].delivery_method = getAPI.data[0].delivery_method === '0' ? '' : getAPI.data[0].delivery_method
        let delivery_method_list = String(getAPI.data[0].delivery_method).split('|')
        if (delivery_method_list[0] === '') {
          delivery_method_list.splice(0, 1);
        }
        getAPI.data[0].delivery_method_list = delivery_method_list

        commit("mut_data", getAPI.data[0]);
        this.dispatch("enrichproductdetailActions/reSetupImg");

      } catch (error) {
        console.log(error);
        alert(error);
        // commit("mut_data", null);
      }
    },
    async reSetupImg({ commit }) {
      console.log('reSetupImg');
      let data = this.getters["enrichproductdetailActions/get_data"];
      let img_list = []
      let { fileUploads } = data

      for await (let v of fileUploads) {
        let { name, link } = v
        img_list.push({ name, link })
      }
      // console.log(fileUploads);
      let getb64 = await convert_imgbase64url(img_list);
      // console.log(getb64);
      for await (let v of getb64) {
        let { name, img: imgB64, size } = v
        let index = data.fileUploads.findIndex(e => String(e.name).trim() === String(name).trim())
        if (index !== -1) {
          data.fileUploads[index].b64.img = imgB64
          data.fileUploads[index].b64.size = size
        }
      }

      commit("mut_data", data);
    },
    async getpimenrichproduct_att({ commit }, value) {
      try {
        commit("mut_att", null);
        let data = {
          mode: "attr",
          skcode: value,
        };

        let getAPI = await serviceAPI.call_API(
          "post",
          `product/getpimenrichproduct`,
          data,
          1
        );

        commit("mut_att", getAPI.data);
      } catch (error) {
        console.log(error);
        alert(error);
        commit("mut_att", null);
      }
    },
    async setgeneral({ commit }, value) {
      let setgeneral = this.getters["enrichproductdetailActions/get_data"];

      if (setgeneral !== null) {
        const {
          prNameTH,
          prNameEN,
          // promotextTH,
          // promotextEN,
          // metaTitleTH,
          // metaTitleEN,
          // metaKeywordsTH,
          // metaKeywordsEN,
          // metaDescriptionTH,
          // metaDescriptionEN,
          showOnWeb,
          visible,
          installationService,
          installmentPayment,
        } = value;

        setgeneral.prNameTH = prNameTH;
        setgeneral.prNameEN = prNameEN;
        // setgeneral.promotextTH = promotextTH
        // setgeneral.promotextEN = promotextEN
        // setgeneral.metaTitleTH = metaTitleTH
        // setgeneral.metaTitleEN = metaTitleEN
        // setgeneral.metaKeywordsTH = metaKeywordsTH
        // setgeneral.metaKeywordsEN = metaKeywordsEN
        // setgeneral.metaDescriptionTH = metaDescriptionTH
        // setgeneral.metaDescriptionEN = metaDescriptionEN
        setgeneral.showOnWeb = showOnWeb;
        setgeneral.visible = visible;
        setgeneral.installationService = installationService;
        setgeneral.installmentPayment = installmentPayment;

        commit("mut_data", setgeneral);
      }
    },

    async setimagevideo({ commit }, value) {
      let setgeneral = this.getters["enrichproductdetailActions/get_data"];

      if (setgeneral !== null) {
        const {
          LinkforVDO2,
          fileUploads,
          fileUploadsmain,
          images,
          img360_show,
          img360_link,
          linkForVdo,
          video,
        } = value;

        // if (setgeneral.linkForVdo_old == undefined) {
        //     setgeneral.linkForVdo_old = linkForVdo
        // }
        // if (setgeneral.LinkforVDO2_old == undefined) {
        //     setgeneral.LinkforVDO2_old = LinkforVDO2
        // }
        // if (setgeneral.images_old == undefined) {
        //     setgeneral.images_old = images
        // }

        // let setimages = ''
        // if (fileUploads.length > 0) {

        // }

        setgeneral.LinkforVDO2 = video[1] !== undefined ? video[1].link : "";
        setgeneral.fileUploads = fileUploads;
        let _fileUploadsmain = [];
        if (fileUploads.length > 0) {
          _fileUploadsmain.push(fileUploads[0]);
        }
        // console.log(fileUploads);
        setgeneral.fileUploadsmain = _fileUploadsmain;
        // console.log(_fileUploadsmain[0].b64.img);
        setgeneral.images = images;
        setgeneral.img360_show = img360_show;
        setgeneral.img360_link = img360_link;
        setgeneral.linkForVdo = video[0] !== undefined ? video[0].link : "";
        setgeneral.video = video;

        commit("mut_data", setgeneral);
      }
    },
    async setdesc({ commit }, value) {
      let setgeneral = this.getters["enrichproductdetailActions/get_data"];

      if (setgeneral !== null) {
        const { longDescEN, longDescTH, shortDesc1EN, shortDesc1TH } = value;

        setgeneral.longDescEN = longDescEN;
        setgeneral.longDescTH = longDescTH;
        setgeneral.shortDesc1EN = shortDesc1EN;
        setgeneral.shortDesc1TH = shortDesc1TH;

        commit("mut_data", setgeneral);
      }
    },
    async setother({ commit }, value) {
      let setgeneral = this.getters["enrichproductdetailActions/get_data"];

      if (setgeneral !== null) {
        const {
          _bulky,
          _fragile,
          _explosives_flammable,
          _toxic_chemical,
          _sharp_weapon,
          _assemble,
          delivery_method,
          delivery_method_list, warranty_w,
          sub_warranty,
          type_sub_warranty,
          warranty_Remark,
        } = value;

        setgeneral.bulky = _bulky == true ? 1 : 0;
        setgeneral.fragile = _fragile == true ? 1 : 0;
        setgeneral.explosives_flammable = _explosives_flammable == true ? 1 : 0;
        setgeneral.toxic_chemical = _toxic_chemical == true ? 1 : 0;
        setgeneral.sharp_weapon = _sharp_weapon == true ? 1 : 0;
        setgeneral.assemble = _assemble == true ? 1 : 0;
        setgeneral.delivery_method_list = delivery_method_list;
        setgeneral.delivery_method = delivery_method_list.join('|');

        setgeneral.warranty_w = warranty_w;
        setgeneral.sub_warranty = sub_warranty;
        setgeneral.type_sub_warranty = type_sub_warranty;
        setgeneral.delivery_method_list = delivery_method_list;
        setgeneral.warranty_Remark = warranty_Remark;

        commit("mut_data", setgeneral);
      }
    },
    async setremark({ commit }, value) {
      let setgeneral = this.getters["enrichproductdetailActions/get_data"];
      if (setgeneral !== null) {
        const { remarkId } = value;
        if (String(remarkId) == "null" || String(remarkId) == "false") {
          setgeneral.remarkId = "0";
        } else {
          setgeneral.remarkId = "1";
        }
        commit("mut_data", setgeneral);
      }
    },
  },
};

async function setfileUploads(data) {
  let datatrim;
  let _trim = [];
  let arr = [];
  if (data !== "") {
    datatrim = data.split("|");
    for await (var [i, v] of datatrim.entries()) {
      if (v !== "") {
        let link = "";
        let mylink = `${constant.URL_TWD_IMG}web/${v}`;

        // if (constant.MODE == "pro") {
        link = mylink;
        // } else {
        //   var http = new XMLHttpRequest();
        //   http.open("HEAD", mylink, false);
        //   http.send();
        //   if (http.status == 200) {
        //     // --------- link new server ------
        //     link = mylink;
        //   } else {
        //     link = `${constant.API_URL_IMG}${v}`;
        //   }
        // }

        //  link = `${constant.API_URL_IMG}${v}`;
        // console.log(constant.API_URL_IMG);
        // console.log(constant.URL_TWD_IMG);
        // console.log(constant.MODE);

        let name = v.split("/")[v.split("/").length - 1];
        arr.push({ link: link, name: name });
      }
    }

    // let getb64 = await convert_imgbase64url(arr);
    // for await (var [index, val] of getb64.entries()) {
    //   let data = {
    //     file: { file: val.link, name: val.name },
    //     name: val.name,
    //     link: val.link,
    //     b64: { img: val.img, size: val.size },
    //     fixed: false,
    //     index: i,
    //     save: false,
    //   };
    //   _trim.push(data);
    // }

    for await (var val of arr) {

      let data = {
        file: { file: val.link, name: val.name },
        name: val.name,
        link: val.link,
        b64: { img: '', size: { w: 0, h: 0 } },
        fixed: false,
        index: i,
        save: false,
      };
      _trim.push(data);
    }
  }
  // console.log(datatrim);

  return _trim;
  // for await ( var [] ){}
}

function convert_imgbase64url(file) {
  return new Promise(async (resolve, reject) => {
    try {
      let data = [];
      let getAPI = await serviceAPI.call_API_NO_LOAD(
        "post",
        `imgb64/getimgb64url`,
        { url: file },
        1
      );

      for await (var [i, v] of getAPI.data.entries()) {
        // console.log(v);
        if (v.b64 == '') { data.push({ img: v.b64, size: { h: 0, w: 0 }, name: v.name, link: v.link }); }
        else {
          let size = await calsize(v.b64);

          data.push({ img: v.b64, size: size, name: v.name, link: v.link });
        }

      }

      resolve(data);
    } catch (error) {

      reject(error);
    }
  });
}

async function calsize(imgb64) {
  return new Promise(async (resolve, reject) => {
    var image = new Image();
    var height;
    var width;
    image.src = imgb64;

    image.onload = function () {
      height = this.height;
      width = this.width;

      resolve({ h: height, w: width });
    };
  });
}

async function getvideo(link) {
  return new Promise(async (resolve, reject) => {
    let data = [];
    let url;
    for await (var [i, v] of link.entries()) {
      if (v !== "" && v !== null && v !== "null") {
        url = `https://www.youtube.com/embed/${main.youtube(v)}`;
        // let position = v.search("watch?v");
        // if (position !== -1) {
        //     url = `https://www.youtube.com/embed/${v.split("v=")[1].split("&")[0]}`
        // }
        // else { url = `https://www.youtube.com/embed/${v.split("/")[v.split("/").length - 1]}` }

        data.push({ index: i, link: url, fixed: false });
      }
    }

    resolve(data);
  });
}

async function getremarkdetail(remarkId) {
  return new Promise(async (resolve, reject) => {
    let resolve_data = { explain: "", remarkHtml: "" };

    let condition =
      remarkId !== null && (remarkId !== "") & (remarkId !== "null");

    if (condition) {
      let data = { remarkId: remarkId };
      let getAPI = await serviceAPI.call_API(
        "post",
        `remark/getpimremark`,
        data,
        1
      );

      let getdata = getAPI.data[0];
      resolve_data = {
        explain: getdata.explain,
        remarkHtml: getdata.remarkHtml,
      };
    }
    resolve(resolve_data);
  });
}
