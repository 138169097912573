<template>
  <div ref="ApexCharts" class="chart1 m-0 p-0"></div>
</template>

<script>
import ApexCharts from "apexcharts";
export default {
  name: "ApexCharts",
  props:['option_chart'],
  data() {
    return {
    //   options1: {
    //     chart: {
    //       height: 280,
    //       type: "radialBar",
    //     },
    //     series: [67, 84, 97, 61],
    //     plotOptions: {
    //       radialBar: {
    //         dataLabels: {
    //           total: {
    //             show: true,
    //             label: "TOTAL",
    //           },
    //         },
    //       },
    //     },
    //     labels: ["TEAM A", "TEAM B", "TEAM C", "TEAM D"],
    //   },
    };
  },
  mounted() {
    this.genChart();
  },
  methods: {
    async genChart() {
      let _this = this;
      new ApexCharts(_this.$refs.ApexCharts, this.option_chart).render();
    },
  },
};
</script>

<style scoped>
.chart1 {
  width: 100%;
  height: auto;
}
</style>
