import * as main from '../../services/mainservice';
import * as constant from '../../services/constant';
import * as serviceAPI from "../../services/APIservice";

export const progressActions = {
    namespaced: true,
    state: {
        numall: 0,
        numload: 0


    },
    mutations: {
        mut_numall(state, value) {
            state.numall = value;
        },
        mut_numload(state, value) {
            state.numload = value;
        },

    },
    getters: {
        get_numall(state) {
            return state.numall;
        },
        get_numload(state) {
            return state.numload;
        },

    },
    actions: {
        async renumload({ commit }) {
            commit('mut_numload', 0)
        },
        async addnumload({ commit }) {
            let get = this.getters["progressActions/get_numload"];
            get++
            commit('mut_numload', get)
        },
        async setnumall({ commit }, value) {

            commit('mut_numall', value)
        },



    },
};