<template>
  <div>
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },

    type: {
      type: String,
      default: "doughnut",
    },
    chartKey: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.renderChart();
  },
  beforeDestroy() {
    this.destroyChart();
  },
  methods: {
    renderChart() {
      const ctx = this.$refs.chart.getContext("2d");
      this.chart = new Chart(ctx, {
        type: this.type,
        data: {
          labels: this.data.map((item) => item.label),
          datasets: [
            {
              data: this.data.map((item) => item.value),
              backgroundColor: [
                "rgb(75, 192, 192)",
                "rgba(114, 124, 245, 0.85)",
              ],
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
        },
      });
    },
    destroyChart() {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
    },
  },
  watch: {
    data() {
      this.chart.data.labels = this.data.map((item) => item.label);
      this.chart.data.datasets[0].data = this.data.map((item) => item.value);
      this.chart.update();
    },
  },
};
</script>
