export const search = {
    async table(objects, find, mode) {
        return new Promise((resolve, reject) => {
            var results = [];
            if (!!find) {

                var toSearch = String(find)
                    .trim()
                    .toLocaleUpperCase()
                    .replace(/ /g, "");

                for (var i = 0; i < objects.length; i++) {
                    var index = String(objects[i][mode])
                        .trim()
                        .toLocaleUpperCase()
                        .replace(/ /g, "");
                    if (index.indexOf(toSearch) != -1) {
                        results.push(objects[i]);
                    }
                }

                resolve({ res: results, len: results.length })
            }
            

        })

    }
}