import * as main from '../../services/mainservice';
import * as constant from '../../services/constant';
import * as serviceAPI from "../../services/APIservice";
import { nav_menu } from '../../services/dropdown';
export const breadcrumbActions = {
    namespaced: true,
    state:
    {
        items: null,

    }
    ,
    mutations: {
        mut_items(state, value) {

            state.items = value;
        },


    },
    getters: {
        get_items(state) {
            return state.items;
        },

    },
    actions: {

        async set_breadcrumb({ commit }, val) {

            let obj = await find_menu(val)

            commit('mut_items', obj)


        },

    },

};


async function find_menu(val) {
    return new Promise(async (resolve, reject) => {

        for await (var v of nav_menu) {


            if (v.routename == val) {
                let data = [{ text: v.name, active: true }]
                resolve(data)
            }
            else if (v.item.length > 0) {

                let obj = v.item
                for await (var va of obj) {
                    if (va.item.length > 0) {
                        let obj2 = va.item
                        for await (var va2 of obj2) {
                            if (va2.routename == val) {

                                let data = [{ text: v.name, active: true }, { text: va.name, active: true }, { text: va2.name, active: true }]
                                resolve(data)
                            }
                        }
                    }
                    else if (va.routename == val) {

                        let data = [{ text: v.name, active: true }, { text: va.name, active: true }]
                        resolve(data)
                    }
                }
            }
        }
    })
}