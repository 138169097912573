// import AuthService from '../services/auth.service';
import * as main from '../../services/mainservice';
import * as constant from '../../services/constant';
import * as serviceAPI from "../../services/APIservice";

import store from '@/store/index'
export const attributeProductDetailAction = {
    namespaced: true,
    state: {
        val: [],
        master: [],
        lastChange: null
    },
    mutations: {
        mut_val(state, value) {
            state.val = value;
        },
        mut_master(state, value) {
            state.master = value;
        },
        mut_lastChange(state, value) {
            state.lastChange = value;
        },


    },
    getters: {
        get_val(state) {
            return state.val;
        },
        get_master(state) {
            return state.master;
        },
        get_lastChange(state) {
            return state.lastChange;
        },



    },

    actions: {
        reval({ commit }) {

            commit('mut_val', [])
            commit('mut_master', [])
            commit('mut_lastChange', null)

        },
        setmaster({ commit }, value) {

            let getter = this.getters["attributeProductDetailAction/get_master"]

            if (!!value && !!value?.attrGroupId) {
                if (getter.length === 0) { getter.push(value) }
                else {
                    let index = getter.findIndex(e => String(e.attrGroupId) === String(value.attrGroupId))

                    if (index == -1) { getter.push(value) }
                }

            }
            commit('mut_master', getter)

        },
        setval({ commit }, value) {
            let getter = this.getters["attributeProductDetailAction/get_val"]

            if (!!value && !!value?.attrGroupId) {
                if (getter.length === 0) { getter.push(value) }
                else {
                    let index = getter.findIndex(e => String(e.attrGroupId) === String(value.attrGroupId))

                    if (index == -1) { getter.push(value) }
                }

            }

            commit('mut_val', getter)

        },
        async changeval({ commit }, value) {

            let getter_m = this.getters["attributeProductDetailAction/get_master"]
            let getter = this.getters["attributeProductDetailAction/get_val"]

            let data_value = value
            // let ii = 0

            for await (let v of getter_m) {
                let { attrGroupId, attrId } = value
                let { data } = v

                let index_data = data.findIndex(e => String(e.attrId).trim() === String(attrId).trim())
                let index_master = getter_m.findIndex(e => String(e.attrGroupId).trim() === String(v.attrGroupId).trim())
                // console.log(`${index_master}:${index_data}`);
                if (index_data !== -1 && index_master !== -1) {

              

                    // console.log(`befor : ${v.attrGroupId}`);

                    delete data_value.attrGroupId;
                    let new_data_value = { ...data_value, attrGroupId: v.attrGroupId }

                    // console.log(new_data_value);
                    getter[index_master].data[index_data] = new_data_value

                    // console.log(`after : ${v.attrGroupId}`);
                    commit('mut_val', getter)
                    // console.log('Change');

                }

            }

            commit('mut_lastChange', value)
        },


    },
};