import * as constant from "../../services/constant";
import * as serviceAPI from "../../services/APIservice";

export const boughtTogetherActions = {
  namespaced: true,
  state: {
    Data_Group_All: null,
    Data_Product_All: null,
  },
  mutations: {
    MUT_Group_All(state, value) {
      state.Data_Group_All = value;
    },
    MUT_Product_All(state, value) {
      state.Data_Product_All = value;
    },
  },
  getters: {
    GET_Group_All(state) {
      return state.Data_Group_All;
    },
    GET_Product_All(state) {
      return state.Data_Product_All;
    },
  },
  actions: {
    async getGroupData({ commit }) {
      try {
        let data = { mode: "Group", GroupId: "", Skcode: "" };
        let getAPI = await serviceAPI.call_API(
          "post",
          `manage/getboughttogethelist`,
          data,
          1
        );
        var datas = getAPI.data;
        commit("MUT_Group_All", datas);
      } catch (error) {
        commit("MUT_Group_All", null);
      }
    },
    async getProductData({ commit }) {
      try {
        let data = { mode: "Skcode", GroupId: "", Skcode: "" };
        let getAPI = await serviceAPI.call_API(
          "post",
          `manage/getboughttogethelist`,
          data,
          1
        );
        var datas = getAPI.data;
        commit("MUT_Product_All", datas);
      } catch (error) {
        commit("MUT_Product_All", null);
      }
    },
  },
};
