<template>
  <div class="container-fluid m-0 p-0">
    <div
      class="row m-0 p-0 justify-content-center align-items-center"
      style="height: 100vh"
    >
      <div class="col-10 col-xl-6 mb-0 mb-xl-5">
        <div class="row justify-content-center">
          <div
            class="
              col-10 col-xl-7
              px-5
              shadow
              bg-white
              rounded
              position-relative
            "
          >
            <p class="font-600 fonttitle my-4">Sign In</p>
            <div class="row justify-content-center mt-4">
              <div class="col-11">
                <div class="form-group shadow">
                  <input
                    type="text"
                    class="form-control"
                    v-model="user.name"
                    placeholder="กรุณาใส่ชื่อผู้ใช้งาน / Username"
                  />
                </div>
                <div class="form-group shadow">
                  <input
                    type="password"
                    class="form-control"
                    v-model="user.password"
                    placeholder="กรุณาใส่รหัสผ่าน / Password"
                  />
                </div>
                <button class="btn bt-main w-100 mb-3 shadow">
                  Sign in
                </button>
                <div
                  class="
                    d-flex
                    flex-rowjustify-content-center
                    mb-3
                    align-items-center
                  "
                >
                  <div class="border-or" style="width: 45%"></div>
                  <div
                    class="text-center m-0 p-0 or  border-rounded-pill-or"
                    style="width: 10%"
                  >
                    OR
                  </div>
                  <div class="border-or" style="width: 45%"></div>
                </div>
                <!-- <div class="row align-items-center px-3">
                  <div class="col-5 border-or"></div>
                  <div class="col-2 or rounded-circle border h-100"></div>
                  <div class="col-5 border-or"></div>
                </div> -->
                <div class="row mx-0 mb-5 p-0 text-center">
                  <div class="col-12 mb-3">
                    <img
                      type="button"
                      src="../assets/windows.png"
                      alt=""
                      style="width: 10%"
                      @click="ms_login()"
                    />
                    <span
                      class="px-1 text-muted"
                      type="button"
                      @click="ms_login()"
                      >x</span
                    >
                    <img
                      type="button"
                      src="../assets/pwbsm.png"
                      alt=""
                      style="width: 10%"
                      @click="ms_login()"
                    />
                  </div>
                  <div class="col-12 font-12s">
                    <p class="font-500">
                      <span class="text-muted">Welcome to </span
                      ><span class="color-main font-600">Power Buy Portal</span>
                    </p>
                  </div>
                </div>
                <!-- <button
                  class="btn btn-ms w-100 mt-2 mb-4 shadow"
                  @click="ms_login()"
                >
                  CHG Account
                </button> -->
                <!-- <img
                  src="../assets/please.png"
                  alt=""
                  class="img-l img-fluid d-none d-xl-block"
                />
                <img
                  src="../assets/Beyond Character_BNB-02.png"
                  alt=""
                  class="img-r img-fluid d-none d-xl-block"
                /> -->
                <SvgLonin
                  class="img-r img-fluid d-none d-xl-block color-team"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgLonin from "@/components/svg/LoginSvg.vue";
import * as serviceAPI from "../services/APIservice";
import * as serviceMain from "../services/mainservice";

export default {
  name: "Login",
  components: { SvgLonin },
  data() {
    return {
      disabledlogin: false,
      branch: "",
      branchapi: "0",
      datastore: [],
      eyepassword: false,
      user: {
        name: "",
        password: "",
        login: false,
      },
    };
  },
  watch: {
    checkAuth: function(value) {
      if (value) {
        this.cklogIn();
      }
    },
  },
  mounted() {
    this.cklogIn();
  },
  computed: {
    checkAuth() {
      return this.$store.getters["loginActions/GET_AUTH"];
    },
  },
  methods: {
    // async dologin() {
    //   try {
    //     let vali = await this.validate();
    //     if (vali !== "OK") {
    //       throw vali;
    //     }

    //     let data = {
    //       username: this.user.name,
    //       password: this.user.password,
    //     };
    //     let getAPI = await serviceAPI.call_API(
    //       "post",
    //       "ms-chg/login_user",
    //       data
    //     );
    //     this.$router.push({ name: "Redirect", query: { token: getAPI.data } });
    //   } catch (error) {
    //     this.$serviceMain.showErrorAlert(
    //       this,
    //       error.message == undefined ? error : error.message
    //     );
    //   }
    // },
    async validate() {
      return new Promise(async (resolve, reject) => {
        let { name, password } = this.user;
        if (!!!name) {
          resolve("Please enter username");
        }
        if (!!!password) {
          resolve("Please enter password");
        }
        resolve("OK");
      });
    },

    ms_login() {
      window.location.href = this.$cons.API_LOGIN_URL;
    },
    async getstoreid() {
      this.$store.dispatch("loginActions/getStoreId");
    },

    cklogIn: async function() {
      if (this.$store.getters["loginActions/GET_AUTH"]) {
        this.$router.push("/");
        // this.$router.push({ name: "CustomerList" });
      }
    },
    async forget() {
      try {
        throw "ขออภัย ระบบยังไม่เปิดให้ใช้งานในส่วนนี้";
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
  },
};
</script>

<style scoped>
.fonttitle {
  font-size: 1.25em;
}
.bg {
  /* background-color: #b7292f; */
  background-color: #b9343b;
}
@media screen and (max-width: 1200px) {
  .bg-md {
    /* background-color: #c03940; */
    background-color: #ebebeb;
    height: 100vh;
  }
  .img {
    border: 8px solid #c03940;
  }
}
.img-l {
  position: absolute;
  top: 50%;
  left: -15%;
  width: 30%;
  transform: translate(-50%, -10%);
}
/* .img-r {
  position: absolute;
  top: 50%;
  left: 115%;
  width: 30%;
  transform: translate(-50%, 50%);
} */
.img-r {
  position: absolute;
  top: 20%;
  left: 115%;
  width: 60%;
  transform: translate(-50%, 50%);
}
</style>
