var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper",attrs:{"id":"app"}},[_c('Header',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.$store.getters['loginActions/GET_AUTH'] &&
        _vm.$route.name !== 'NotFound' &&
          _vm.$route.name !== 'NotFound404' &&
          _vm.$route.name !== 'ProductInfo-ProductQR-Preview'
    ),expression:"\n      $store.getters['loginActions/GET_AUTH'] &&\n        $route.name !== 'NotFound' &&\n          $route.name !== 'NotFound404' &&\n          $route.name !== 'ProductInfo-ProductQR-Preview'\n    "}]}),_c('b-breadcrumb',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.$store.getters['loginActions/GET_AUTH'] &&
        _vm.$route.name !== 'NotFound' &&
        _vm.$route.name !== 'Home' &&
        _vm.$route.name !== 'NotRole' &&
        _vm.$route.name !== 'view Product' &&
        _vm.$route.name !== 'SelectBu'
    ),expression:"\n      $store.getters['loginActions/GET_AUTH'] &&\n        $route.name !== 'NotFound' &&\n        $route.name !== 'Home' &&\n        $route.name !== 'NotRole' &&\n        $route.name !== 'view Product' &&\n        $route.name !== 'SelectBu'\n    "}],staticClass:"m-0 pb-0 font-500 breadcrumb-bg ",attrs:{"items":_vm.$store.getters['breadcrumbActions/get_items']}}),_c('b-overlay',{staticStyle:{"height":"100%","width":"100%"},attrs:{"show":_vm.$store.getters['loadingActions/GET_load'],"spinner-variant":"danger","spinner-type":"grow"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{class:[("sk-circle-" + (_vm.$store.getters['loginActions/GET_BU']))]},[_c('div',{staticClass:"sk-circle1 sk-child"}),_c('div',{staticClass:"sk-circle2 sk-child"}),_c('div',{staticClass:"sk-circle3 sk-child"}),_c('div',{staticClass:"sk-circle4 sk-child"}),_c('div',{staticClass:"sk-circle5 sk-child"}),_c('div',{staticClass:"sk-circle6 sk-child"}),_c('div',{staticClass:"sk-circle7 sk-child"}),_c('div',{staticClass:"sk-circle8 sk-child"}),_c('div',{staticClass:"sk-circle9 sk-child"}),_c('div',{staticClass:"sk-circle10 sk-child"}),_c('div',{staticClass:"sk-circle11 sk-child"}),_c('div',{staticClass:"sk-circle12 sk-child"})])]},proxy:true}])},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }