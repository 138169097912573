import { call_API } from '../services/APIservice'
export async function getUrgentLogEnrich(params) {
    return new Promise(async (resolve, reject) => {
        try {
            let getAPI = await call_API(
                "post",
                `product/getUrgentLogEnrich`,
                params,
                1
            );
            resolve(getAPI)
        } catch (error) {
            console.log(error);
            reject(error)
        }
    })
}


export async function check_enrich(params) {
    return new Promise(async (resolve, reject) => {
        try {
            let getAPI = await call_API(
                "post",
                `product/check_enrich`,
                params,
                1
            );
            resolve(getAPI)
        } catch (error) {
            console.log(error);
            reject(error)
        }
    })
}

export async function updateSKU_qr(params) {
    return new Promise(async (resolve, reject) => {
        try {
            let getAPI = await call_API(
                "post",
                `product/updateSKU_qr`,
                params,
                1
            );
            resolve(getAPI)
        } catch (error) {
            console.log(error);
            reject(error)
        }
    })
}


export async function get_productQR_list() {
    return new Promise(async (resolve, reject) => {
        try {
            let getAPI = await call_API(
                "post",
                `product/get_productQR_list`,
                {},
                1
            );
            resolve(getAPI)
        } catch (error) {
            console.log(error);
            reject(error)
        }
    })
}

export async function get_enrich_qr() {
    return new Promise(async (resolve, reject) => {
        try {
            let getAPI = await call_API(
                "post",
                `product/get_enrich_qr`,
                {},
                1
            );
            resolve(getAPI)
        } catch (error) {
            console.log(error);
            reject(error)
        }
    })
}

export async function get_pim_product_qr(params) {
    return new Promise(async (resolve, reject) => {
        try {
            let getAPI = await call_API(
                "post",
                `product/get_pim_product_qr`,
                params,
                1
            );
            resolve(getAPI)
        } catch (error) {
            console.log(error);
            reject(error)
        }
    })
}

export async function getpimenrichproduct_qr(params) {
    return new Promise(async (resolve, reject) => {
        try {
            let getAPI = await call_API(
                "post",
                `product/getpimenrichproduct_qr`,
                params,
                1
            );
            resolve(getAPI)
        } catch (error) {
            console.log(error);
            reject(error)
        }
    })
}
export async function getpimenrichproduct(params) {
    return new Promise(async (resolve, reject) => {
        try {
            let getAPI = await call_API(
                "post",
                `product/getpimenrichproduct`,
                params,
                1
            );
            resolve(getAPI)
        } catch (error) {
            console.log(error);
            reject(error)
        }
    })
}
export async function updatepimproduct_qr(params) {
    return new Promise(async (resolve, reject) => {
        try {
            let getAPI = await call_API(
                "post",
                `product/updatepimproduct_qr`,
                params,
                1
            );
            resolve(getAPI)
        } catch (error) {
            console.log(error);
            reject(error)
        }
    })
}