module.exports.nav_menu = [
  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-fill" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
        <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
      </svg>`,
    name: "Home Page",
    routename: "Home",
    item: [],
  },
  // {
  //     icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-check" viewBox="0 0 16 16">
  //     <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
  //   </svg>`,
  //     name: "Attribute",
  //     routename: "Attribute",
  //     item: [
  //         {

  //             name: "Attributes",
  //             routename: "Attributes",
  //             item: []
  //         },
  //         {

  //             name: "Attribute Set",
  //             routename: "Attributeset",
  //             item: []
  //         },

  //         {

  //             name: "Properties",
  //             routename: "PropertiesAttribute",
  //             item: []
  //         },

  //     ]
  // },
  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag-check-fill" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0zm-.646 5.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
      </svg>`,
    name: "Products",
    routename: "Products",
    item: [
      {
        name: "Enrich Product",
        routename: "Enrich Product",
        item: [],
      },
      {
        name: "Product On Web",
        routename: "ProductOnWeb",
        item: [],
      },
      {
        name: "QR Info",
        routename: "ProductInfo",
        item: [
          {
            name: "Product QR",
            routename: "ProductInfo-ProductQR",
            item: [],
          },
          {
            name: "Genarate QR",
            routename: "ProductInfo-QR",
            item: [],
          },
          {
            name: "Add SKU QR",
            routename: "ProductInfo-SKUQR",
            item: [],
          },
        ],
      },
      // {
      //     name: "Product Last Update",
      //     routename: "Product Last Update",
      //     item: []
      // },
      {
        name: "Link Product Files List",
        routename: "ManageLinkContent",
        item: [],
      },
      {
        name: "Tracking Supplier",
        routename: "Tracking Supplier",
        item: [],
      },
      // {
      //     name: "My Product",
      //     routename: "My Product",
      //     item: []
      // }
    ],
  },

  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app-indicator" viewBox="0 0 16 16">
        <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
        <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
      </svg>`,
    name: "Manage",
    routename: "Manage",
    item: [
      {
        name: "Remarks Message",
        routename: "ManageRemarkMessage",
        item: [],
      },
      // {
      //     name: "Remarks",
      //     routename: "Remarks",
      //     item: []
      // },
      // {
      //   name: "Attributes Group",
      //   routename: "Attribute-group",
      //   item: [],
      // },

      {
        name: "Attributes",
        routename: "Attributes",
        item: [
          {
            name: "Add Attributes Group",
            routename: "Attribute-group",
            item: [],
          },
          {
            name: "Add Attributes",
            routename: "Attributes",
            item: [],
          },
          {
            name: "Mapping Products",
            routename: "Attributes-Products",
            item: [],
          },
          {
            name: "Attribute Value Product",
            routename: "Attribute_Value_Product",
            item: [],
          },
          {
            name: "Mapping SPU",
            routename: "Mapping SPU",
            item: [],
          },
        ],
      },

      {
        name: "Contact Vendor",
        routename: "ManageVendor",
        item: [],
      },
      {
        name: "Data Conditions",
        routename: "Data Conditions",
        item: [],
      },
      {
        name: "On-Off On Web",
        routename: "On-Off-OnWeb",
        item: [],
      },
      // {ManageImage
      //   name: "Image 360",
      //   routename: "ManageImage360",
      //   item: [],
      // },
      {
        name: "Manage Image",
        routename: "ManageImage",
        item: [
          {
            name: "Image 360",
            routename: "ManageImage360",
            item: [],
          },
          {
            name: "Image Brand",
            routename: "ManageImageBrand",
            item: [],
          },
        ],
      },
      {
        name: "Upload EN Language",
        routename: "ManagePimData",
        item: [
          {
            name: "Upload EN Language",
            routename: "ManagePimData",
            item: [],
          },
          {
            name: "Upload EN Attribute",
            routename: "UploadAttributeEN",
            item: [],
          },
        ],
      },
      {
        name: "Bought Together",
        routename: "Bought Together",
        item: [],
      },
      {
        name: "DeliveryMethod",
        routename: "DeliveryMethod",
        view: "ALL",
        item: [
          {
            name: "Upload Delivery Method",
            routename: "DeliveryMethod",
            item: [],
          },
        ],
      },
      {
        name: "Warranty",
        routename: "Warranty",
        item: [],
      },
      {
        name: "Sort Category WEB",
        routename: "UploadCategory",
        item: [],
      },
    ],
  },

  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-richtext-fill" viewBox="0 0 16 16">
        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM7 6.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V9.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V9s1.54-1.274 1.639-1.208zM5 11h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1zm0 2h3a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1z"/>
      </svg>`,
    name: "Report",
    routename: "Report",
    item: [
      {
        name: "Download CSV Data",
        routename: "DownloadCSVData",
        item: [],
      },
    ],
  },
  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
      </svg>`,
    name: "User",
    routename: "User",
    item: [
      {
        name: "Manage User",
        routename: "ManageUser",
        item: [],
      },
    ],
  },
  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 24 24">
      <path d="m24,7v12.126c0,2.283-1.542,4.274-3.751,4.842-.083.021-.167.032-.25.032-.445,0-.852-.3-.968-.751-.138-.535.185-1.08.72-1.217,1.324-.341,2.249-1.536,2.249-2.906v-9.126h-3c-.553,0-1-.448-1-1s.447-1,1-1h3v-1c0-1.654-1.346-3-3-3H5c-1.654,0-3,1.346-3,3v1h3c.552,0,1,.448,1,1s-.448,1-1,1h-3v9.126c0,1.37.925,2.565,2.249,2.906.535.137.857.683.719,1.217-.137.535-.68.857-1.217.719-2.208-.568-3.751-2.559-3.751-4.842V7C0,4.243,2.243,2,5,2h1v-1c0-.552.448-1,1-1s1,.448,1,1v1h8v-1c0-.552.447-1,1-1s1,.448,1,1v1h1c2.757,0,5,2.243,5,5Zm-4.5,9.5c0,4.142-3.358,7.5-7.5,7.5s-7.5-3.358-7.5-7.5,3.358-7.5,7.5-7.5,7.5,3.358,7.5,7.5Zm-3.5,1.876c0-1.288-.923-2.377-2.193-2.588l-3.286-.548c-.302-.05-.521-.309-.521-.616,0-.344.28-.624.624-.624h2.644c.356,0,.688.192.867.5.274.479.886.642,1.366.365.478-.277.642-.888.364-1.366-.534-.925-1.53-1.5-2.598-1.5h-.268c0-.552-.448-1-1-1s-1,.448-1,1h-.376c-1.447,0-2.624,1.177-2.624,2.624,0,1.288.922,2.377,2.192,2.588l3.286.548c.302.05.521.309.521.616,0,.344-.28.624-.624.624h-2.644c-.356,0-.688-.192-.867-.5-.277-.479-.889-.643-1.366-.365-.479.277-.642.888-.365,1.366.535.925,1.53,1.499,2.598,1.499h.268c0,.552.448,1,1,1s1-.448,1-1h.376c1.447,0,2.624-1.177,2.624-2.624Z"/>
    </svg>`,
    name: "Market Place",
    routename: "MKP",
    item: [
      {
        name: "Check Orders",
        routename: "CheckOrders",
        item: [],
      },
      // {
      //   name: "Check Status",
      //   routename: "CheckStatus",
      //   item: [],
      // },
      {
        name: "Upload Product Installation Service",
        routename: "UploadSkuInstall",
        item: [],
      },
      {
        name: "Upload Stock Pre-Order",
        routename: "UploadStockMKP_PreOrder",
        item: [],
      },
      {
        name: "Upload Stock Red-Text",
        routename: "UploadStockMKP_RedText",
        item: [],
      },
      {
        name: "OfficeMate Manage Product",
        routename: "OFM_ManageProduct",
        item: [],
      },
    ],
  },
  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fingerprint" viewBox="0 0 16 16">
        <path d="M8.06 6.5a.5.5 0 0 1 .5.5v.776a11.5 11.5 0 0 1-.552 3.519l-1.331 4.14a.5.5 0 0 1-.952-.305l1.33-4.141a10.5 10.5 0 0 0 .504-3.213V7a.5.5 0 0 1 .5-.5Z"/>
        <path d="M6.06 7a2 2 0 1 1 4 0 .5.5 0 1 1-1 0 1 1 0 1 0-2 0v.332c0 .409-.022.816-.066 1.221A.5.5 0 0 1 6 8.447c.04-.37.06-.742.06-1.115V7Zm3.509 1a.5.5 0 0 1 .487.513 11.5 11.5 0 0 1-.587 3.339l-1.266 3.8a.5.5 0 0 1-.949-.317l1.267-3.8a10.5 10.5 0 0 0 .535-3.048A.5.5 0 0 1 9.569 8Zm-3.356 2.115a.5.5 0 0 1 .33.626L5.24 14.939a.5.5 0 1 1-.955-.296l1.303-4.199a.5.5 0 0 1 .625-.329Z"/>
        <path d="M4.759 5.833A3.501 3.501 0 0 1 11.559 7a.5.5 0 0 1-1 0 2.5 2.5 0 0 0-4.857-.833.5.5 0 1 1-.943-.334Zm.3 1.67a.5.5 0 0 1 .449.546 10.72 10.72 0 0 1-.4 2.031l-1.222 4.072a.5.5 0 1 1-.958-.287L4.15 9.793a9.72 9.72 0 0 0 .363-1.842.5.5 0 0 1 .546-.449Zm6 .647a.5.5 0 0 1 .5.5c0 1.28-.213 2.552-.632 3.762l-1.09 3.145a.5.5 0 0 1-.944-.327l1.089-3.145c.382-1.105.578-2.266.578-3.435a.5.5 0 0 1 .5-.5Z"/>
        <path d="M3.902 4.222a4.996 4.996 0 0 1 5.202-2.113.5.5 0 0 1-.208.979 3.996 3.996 0 0 0-4.163 1.69.5.5 0 0 1-.831-.556Zm6.72-.955a.5.5 0 0 1 .705-.052A4.99 4.99 0 0 1 13.059 7v1.5a.5.5 0 1 1-1 0V7a3.99 3.99 0 0 0-1.386-3.028.5.5 0 0 1-.051-.705ZM3.68 5.842a.5.5 0 0 1 .422.568c-.029.192-.044.39-.044.59 0 .71-.1 1.417-.298 2.1l-1.14 3.923a.5.5 0 1 1-.96-.279L2.8 8.821A6.531 6.531 0 0 0 3.058 7c0-.25.019-.496.054-.736a.5.5 0 0 1 .568-.422Zm8.882 3.66a.5.5 0 0 1 .456.54c-.084 1-.298 1.986-.64 2.934l-.744 2.068a.5.5 0 0 1-.941-.338l.745-2.07a10.51 10.51 0 0 0 .584-2.678.5.5 0 0 1 .54-.456Z"/>
        <path d="M4.81 1.37A6.5 6.5 0 0 1 14.56 7a.5.5 0 1 1-1 0 5.5 5.5 0 0 0-8.25-4.765.5.5 0 0 1-.5-.865Zm-.89 1.257a.5.5 0 0 1 .04.706A5.478 5.478 0 0 0 2.56 7a.5.5 0 0 1-1 0c0-1.664.626-3.184 1.655-4.333a.5.5 0 0 1 .706-.04ZM1.915 8.02a.5.5 0 0 1 .346.616l-.779 2.767a.5.5 0 1 1-.962-.27l.778-2.767a.5.5 0 0 1 .617-.346Zm12.15.481a.5.5 0 0 1 .49.51c-.03 1.499-.161 3.025-.727 4.533l-.07.187a.5.5 0 0 1-.936-.351l.07-.187c.506-1.35.634-2.74.663-4.202a.5.5 0 0 1 .51-.49Z"/>
      </svg>`,
    name: "Admin IT",
    routename: "Admin_IT",
    item: [
      {
        name: "Role User",
        routename: "Role-User",
        item: [],
      },
      {
        name: "Permission User",
        routename: "Permission-User",
        item: [],
      },
      {
        name: "Monitor Enrich SKU",
        routename: "Monitor-Enrich-SKU",
        item: [],
      },
      {
        name: "History Login",
        routename: "HistoryLogin",
        item: [],
      },
      {
        name: "Check MMS to BIS",
        routename: "CHECK-MMS-BIS",
        item: [],
      },
    ],
  },
];

// {
//     icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
//     <path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
//     <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
//   </svg>`,
//     name: "Home Page",
//     routename: "Home",
//     item: []
// },
// {
//     icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
//     <path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
//     <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
//   </svg>`,
//     name: "Master",
//     routename: "Master",
//     item: [
//         {

//             name: "Brand",
//             routename: "Brand",
//             item: []
//         },
//         {

//             name: "Series / Model",
//             routename: "Series_Model",
//             item: []
//         },
//         {

//             name: "Attribute",
//             routename: "Attribute",
//             item: [
//                 {

//                     name: "Attributes",
//                     routename: "Attributes"
//                 },
//                 {

//                     name: "Attribute Sets",
//                     routename: "Attribute_Sets"
//                 }
//             ]
//         },
//         {

//             name: "Unit",
//             routename: "Unit",
//             item: []
//         },
//         {

//             name: "Color",
//             routename: "Color",
//             item: []
//         },
//         {

//             name: "Channel",
//             routename: "Channel",
//             item: []
//         },
//         {

//             name: "Category",
//             routename: "Category",
//             item: [
//                 {

//                     name: "Hybrid Category",
//                     routename: "Hybrid_Category"
//                 },
//                 {

//                     name: "JDA Category",
//                     routename: "JDA_Category"
//                 },
//                 {

//                     name: "Inventory Category",
//                     routename: "Inventory_Category"
//                 },
//                 {

//                     name: "Online Category",
//                     routename: "Online_Category"
//                 }
//             ]
//         }
//     ]
// },
// {
//     icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box2-fill" viewBox="0 0 16 16">
//     <path d="M3.75 0a1 1 0 0 0-.8.4L.1 4.2a.5.5 0 0 0-.1.3V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.1-.3L13.05.4a1 1 0 0 0-.8-.4h-8.5ZM15 4.667V5H1v-.333L1.5 4h6V1h1v3h6l.5.667Z"/>
//   </svg>`,
//     name: "Product",
//     routename: "Product",
//     item: [
//         {

//             name: "Register New Product",
//             routename: "Register_New_Product",
//             item: []
//         },
//         {

//             name: "Product Information",
//             routename: "Product_Information",
//             item: []
//         }
//     ]
// },
// {
//     icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
//     <path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
//     <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
//   </svg>`,
//     name: "Product Data",
//     routename: "Product_Data",
//     item: [
//         {

//             name: "Edit Status & Remark",
//             routename: "Edit_Status_Remark",
//             item: []
//         },
//         {

//             name: "Edit Last Vendor",
//             routename: "Edit_Last_Vendor",
//             item: []
//         }
//     ]
// },
// {
//     icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
//     <path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
//     <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
//   </svg>`,
//     name: "Product Pricing",
//     routename: "Product_ricing",
//     item: [
//         {

//             name: "Edit Normal Price",
//             routename: "Edit_Normal_Price",
//             item: []
//         }
//     ]
// },
// {
//     icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
//     <path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
//     <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
//   </svg>`,
//     name: "Product Catalog",
//     routename: "Product_Catalog",
//     item: [
//         {

//             name: "Catalog",
//             routename: "Catalog",
//             item: [
//                 {

//                     name: "Catalog Type",
//                     routename: "Catalog_Type"
//                 },
//                 {

//                     name: "Catalog Name",
//                     routename: "Catalog_Name"
//                 }
//             ]
//         },
//         {

//             name: "Edit Product Catalog",
//             routename: "Edit_Product_Catalog",
//             item: []
//         }
//     ]
// },
// {
//     icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
//     <path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
//     <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
//   </svg>`,
//     name: "Document",
//     routename: "Document",
//     item: [
//         {

//             name: "Request Document",
//             routename: "Request_Document",
//             item: []
//         }
//     ]
// },
// {
//     icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
//     <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
//   </svg>`,
//     name: "User",
//     routename: "User",
//     item: [
//         {

//             name: "Operator Team",
//             routename: "Operator_eam",
//             item: []
//         },
//         {

//             name: "Vendor Information",
//             routename: "Vendor_Information",
//             item: []
//         }
//     ]
// },
// {
//     icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fingerprint" viewBox="0 0 16 16">
//     <path d="M8.06 6.5a.5.5 0 0 1 .5.5v.776a11.5 11.5 0 0 1-.552 3.519l-1.331 4.14a.5.5 0 0 1-.952-.305l1.33-4.141a10.5 10.5 0 0 0 .504-3.213V7a.5.5 0 0 1 .5-.5Z"/>
//     <path d="M6.06 7a2 2 0 1 1 4 0 .5.5 0 1 1-1 0 1 1 0 1 0-2 0v.332c0 .409-.022.816-.066 1.221A.5.5 0 0 1 6 8.447c.04-.37.06-.742.06-1.115V7Zm3.509 1a.5.5 0 0 1 .487.513 11.5 11.5 0 0 1-.587 3.339l-1.266 3.8a.5.5 0 0 1-.949-.317l1.267-3.8a10.5 10.5 0 0 0 .535-3.048A.5.5 0 0 1 9.569 8Zm-3.356 2.115a.5.5 0 0 1 .33.626L5.24 14.939a.5.5 0 1 1-.955-.296l1.303-4.199a.5.5 0 0 1 .625-.329Z"/>
//     <path d="M4.759 5.833A3.501 3.501 0 0 1 11.559 7a.5.5 0 0 1-1 0 2.5 2.5 0 0 0-4.857-.833.5.5 0 1 1-.943-.334Zm.3 1.67a.5.5 0 0 1 .449.546 10.72 10.72 0 0 1-.4 2.031l-1.222 4.072a.5.5 0 1 1-.958-.287L4.15 9.793a9.72 9.72 0 0 0 .363-1.842.5.5 0 0 1 .546-.449Zm6 .647a.5.5 0 0 1 .5.5c0 1.28-.213 2.552-.632 3.762l-1.09 3.145a.5.5 0 0 1-.944-.327l1.089-3.145c.382-1.105.578-2.266.578-3.435a.5.5 0 0 1 .5-.5Z"/>
//     <path d="M3.902 4.222a4.996 4.996 0 0 1 5.202-2.113.5.5 0 0 1-.208.979 3.996 3.996 0 0 0-4.163 1.69.5.5 0 0 1-.831-.556Zm6.72-.955a.5.5 0 0 1 .705-.052A4.99 4.99 0 0 1 13.059 7v1.5a.5.5 0 1 1-1 0V7a3.99 3.99 0 0 0-1.386-3.028.5.5 0 0 1-.051-.705ZM3.68 5.842a.5.5 0 0 1 .422.568c-.029.192-.044.39-.044.59 0 .71-.1 1.417-.298 2.1l-1.14 3.923a.5.5 0 1 1-.96-.279L2.8 8.821A6.531 6.531 0 0 0 3.058 7c0-.25.019-.496.054-.736a.5.5 0 0 1 .568-.422Zm8.882 3.66a.5.5 0 0 1 .456.54c-.084 1-.298 1.986-.64 2.934l-.744 2.068a.5.5 0 0 1-.941-.338l.745-2.07a10.51 10.51 0 0 0 .584-2.678.5.5 0 0 1 .54-.456Z"/>
//     <path d="M4.81 1.37A6.5 6.5 0 0 1 14.56 7a.5.5 0 1 1-1 0 5.5 5.5 0 0 0-8.25-4.765.5.5 0 0 1-.5-.865Zm-.89 1.257a.5.5 0 0 1 .04.706A5.478 5.478 0 0 0 2.56 7a.5.5 0 0 1-1 0c0-1.664.626-3.184 1.655-4.333a.5.5 0 0 1 .706-.04ZM1.915 8.02a.5.5 0 0 1 .346.616l-.779 2.767a.5.5 0 1 1-.962-.27l.778-2.767a.5.5 0 0 1 .617-.346Zm12.15.481a.5.5 0 0 1 .49.51c-.03 1.499-.161 3.025-.727 4.533l-.07.187a.5.5 0 0 1-.936-.351l.07-.187c.506-1.35.634-2.74.663-4.202a.5.5 0 0 1 .51-.49Z"/>
//   </svg>`,
//     name: "Admin IT",
//     routename: "Admin_IT",
//     item: [
//         {

//             name: "Master Data",
//             routename: "Master_Data",
//             item: [
//                 {

//                     name: "Attribute Mechanic",
//                     routename: "Attribute_Mechanic"
//                 }
//             ]
//         },
//         {

//             name: "User",
//             routename: "UserAdmin",
//             item: [
//                 {

//                     name: "Vendor User",
//                     routename: "Vendor_User"
//                 },
//                 {

//                     name: "Operator User",
//                     routename: "Operator_User"
//                 },
//                 {

//                     name: "CHG User",
//                     routename: "Chguser"
//                 }
//             ]
//         },
//         {

//             name: "Approval",
//             routename: "Approval",
//             item: [
//                 {

//                     name: "Rule",
//                     routename: "Rule"
//                 }
//             ]
//         }
//     ]
// }
