import Vue from "vue";
import Vuex from "vuex";
import { loginActions } from "../store/actions/loginActions";
import { selectedActions } from "./actions/selectedActions";
import { enrichproductActions } from "./actions/enrichproductActions";
import { loadingActions } from "./actions/loadingActions";
import { serviceActions } from "./actions/serviceActions";
import { categoryActions } from "./actions/categoryActions";
import { enrichproductdetailActions } from "./actions/enrichproductdetailActions";
import { assigntasksActions } from "./actions/assigntasksActions";
import { breadcrumbActions } from "./actions/breadcrumbActions";
import { OnOffOnWebActions } from "./actions/OnOffOnWebActions";
import { remarkMessageActions } from "./actions/remarkMessageActions";
import { Image360Actions } from "./actions/Image360Actions";
import { UploadEnActions } from "./actions/UploadEnActions";
import { progressActions } from "./actions/progressActions";
import { attributeProductDetailAction } from "./actions/attributeProductDetailAction";
import { SPUProductActions } from "./actions/SPUProductActions";
import { boughtTogetherActions } from "./actions/boughtTogetherActions";
import { ProductQRActions } from "./actions/ProductQRActions";
import { enrichproductdetailQRActions } from "./actions/enrichproductdetailQRActions";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    loginActions: loginActions,
    selectedActions: selectedActions,
    loadingActions: loadingActions,
    serviceActions: serviceActions,
    categoryActions: categoryActions,
    enrichproductActions: enrichproductActions,
    enrichproductdetailActions: enrichproductdetailActions,
    assigntasksActions: assigntasksActions,
    breadcrumbActions: breadcrumbActions,
    OnOffOnWebActions: OnOffOnWebActions,
    remarkMessageActions: remarkMessageActions,
    Image360Actions: Image360Actions,
    UploadEnActions: UploadEnActions,
    progressActions: progressActions,
    attributeProductDetailAction: attributeProductDetailAction,
    SPUProductActions: SPUProductActions,
    boughtTogetherActions: boughtTogetherActions,
    ProductQRActions: ProductQRActions,
    enrichproductdetailQRActions: enrichproductdetailQRActions
  },
});
