<template>
  <div id="header" class="bfont">
    <div>
      <b-navbar
        toggleable="lg"
        type="dark"
        variant=""
        class="shadow-sm headercolor"
      >
        <b-navbar-toggle target="nav-collapse">
          <!-- start menu bar  -->
          <b-button v-b-toggle.menubar variant="outline-dark"
            ><b-icon icon="list" aria-hidden="true"> </b-icon>
          </b-button>
          <!--end start menu bar  -->
        </b-navbar-toggle>

        <b-navbar-brand href="#" class="mainimg" @click="gotohome()">
          <!-- <div class="m-0 p-3"></div> -->

          <span
            class="pb-2 pt-1 px-4    rounded"
            :class="
              $cons.MODE === 'production'
                ? 'bg-img-header '
                : 'bg-img-header-uat'
            "
          >
            <img class="img-fluid" src="@/assets/PWB.svg" alt=""
          /></span>
          <!-- <img  class="img-fluid bg-img-header py-1 px-4 rounded" src="@/assets/PWB.svg" alt="" /> -->
          <span class="title ml-2 d-none d-xl-inline-block color-main">
            <span
              class="  font-900"
              :class="`text-main-${$store.getters['loginActions/GET_BU']}`"
              >P</span
            >roduct
            <span
              class="  font-900"
              :class="`text-main-${$store.getters['loginActions/GET_BU']}`"
              >I</span
            >nformation
            <span
              class="  font-900"
              :class="`text-main-${$store.getters['loginActions/GET_BU']}`"
              >M</span
            >angement</span
          >
        </b-navbar-brand>

        <b-navbar-nav class="ml-auto" right>
          <div
            class="dropdown  n dropdown-toggle "
            type="button"
            :class="`text-main-${$store.getters['loginActions/GET_BU']}`"
          >
            <span
              class="  myavatar"
              v-html="icon.person"
              :class="`text-main-${$store.getters['loginActions/GET_BU']}`"
            ></span
            ><span class="d-none d-xl-inline-block ml-2 titlenavright">
              {{ $store.getters["loginActions/GET_USER"].firstname }}
              {{ $store.getters["loginActions/GET_USER"].lastname }}</span
            >

            <div class="dropdown-content">
              <!-- <p type="button" class="m-0 p-2 item" @click="ChangePassword()">
                เปลี่ยนรหัสผ่าน
              </p> -->

              <p type="button" class="my-0 px-3 py-2 item" @click="logout()">
                <span v-html="this.$svg_icon.Boxarrowright" class="mx-1"></span
                >Sign Out
              </p>
            </div>
          </div>
        </b-navbar-nav>
        <!-- <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>

            <template #button-content>
              <em class="titlenavright"
                >{{ $store.getters["loginActions/GET_USER"].firstname }}
              </em>
            </template>

            <b-dropdown-item href="#" @click="ChangePassword()"
              >เปลี่ยนรหัสผ่าน</b-dropdown-item
            >

            <b-dropdown-item href="#" @click="logout()"
              >Sign Out</b-dropdown-item
            >

          </b-nav-item-dropdown>
        </b-navbar-nav> -->
      </b-navbar>
    </div>

    <Navbar class="" v-if="$route.name !== 'SelectBu'" />
    <Menubar />
  </div>
</template>

<script>
import Menubar from "./Menubar.vue";
import Navbar from "./Navbar.vue";
export default {
  name: "Header",
  components: { Menubar, Navbar },
  data() {
    return {
      icon: {
        person: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
</svg>`,
      },
      user: this.$serviceMain.parseJwtByLocalStorage("user"),
      showmenubar: false,
      myimg: `@/assets/${this.$store.getters["serviceActions/get_bu"]}.png`,
    };
  },
  created() {
    this.user = this.$serviceMain.parseJwtByLocalStorage("user");
  },
  methods: {
    async logout() {
      let getUser = this.$serviceMain.readCookietoken(this.$cons.TOKEN_KEY);
      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        `คุณต้องการออกจากระบบ/ Sing Out?`
      );

      // if (confirm) {
      //   await this.$store.dispatch("loginActions/logoutaction");
      //   window.location.href = this.$cons.API_LOGOUT_URL;
      // }

      if (confirm) {
        console.log(getUser);
        let { usertype } = getUser;
        if (
          String(usertype)
            .toLocaleLowerCase()
            .trim() === "userlogin"
        ) {
          await this.$store.dispatch("loginActions/logoutaction");
          this.$router.push({ path: "/" });
          window.location.reload();
        } else {
          await this.$store.dispatch("loginActions/logoutaction");
          window.location.href = this.$cons.API_LOGOUT_URL;
        }
      }
    },
    gotohome() {
      this.$router.push({ name: "Home" });
    },
    switchbu() {
      this.$router.push({ name: "SelectBu" });
    },
    ChangePassword() {
      var mykey = this.$serviceMain.Base64.encode(this.user.QTUSERNAME);

      this.$router.push({
        name: "ChangePassword",
        params: { ckey: mykey },
      });
      // this.$router.push({ name: "ChangePassword" });
    },
  },
};
</script>

<style scoped>
.title {
  /* color: rgb(0, 0, 0); */
  color: #747474e0;
  font-size: 17px;
  /* font-weight: bold; */
}
.mainimg {
  padding: 0px;
  margin: 0px;
}
.mainimg img {
  margin-left: 10px;
  height: 5vh;
  /* border-radius: 2px; */
  /* background-color: rgb(255, 255, 255); */
}
.stylenavbar {
  border-bottom: 0.5px solid #dadcdfe8;
}
.headercolor {
  background-color: #ffffff;
}

.titlenavright {
  color: #6c757de0;
}
.stylestorename {
  font-size: 15px;
  font-weight: bold;
  color: #747474e0;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.myavatar {
  border-radius: 25px;
  background: #eaeaeae0;
  padding: 5px;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.item:hover {
  color: #696969;
}
@media screen and (max-width: 1200px) {
  .dropdown-content {
    display: none;
    position: absolute;
    top: 180%;
    right: 0%;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    /* padding: 12px 16px; */
    z-index: 1;
  }
}
/* xl size ---- */
@media screen and (min-width: 1200px) {
  .dropdown-content {
    display: none;
    position: absolute;

    right: 0%;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    /* padding: 12px 16px; */
    z-index: 1;
  }
}
</style>
