// import AuthService from '../services/auth.service';
// import * as main from '../../services/mainservice';
import * as constant from "../../services/constant";
import * as serviceAPI from "../../services/APIservice";

export const UploadEnActions = {
  namespaced: true,
  state: {
    PimData: null,
    ProductData: null,
  },
  mutations: {
    MUT_PIM_DATA(state, value) {
      state.PimData = value;
    },
    MUT_PROD_DATA(state, value) {
      state.ProductData = value;
    },
  },
  getters: {
    GET_PIM_DATA(state) {
      return state.PimData;
    },
    GET_PROD_DATA(state) {
      return state.ProductData;
    },
  },
  actions: {
    async getPimProductLanguage({ commit }) {
      try {
        console.log("getPimProductLanguage");
        let data = {};
        let getAPI = await serviceAPI.call_API(
          "post",
          `manage/getPimDataMultiLanguage`,
          data,
          1
        );
        // console.log(getAPI.data);
        let datas = getAPI.data;
        commit("MUT_PIM_DATA", datas);
      } catch (error) {
        console.log(error);
      }
    },
    async getPimProductNoLanguage({ commit }) {
      try {
        let data = {};
        let getAPI = await serviceAPI.call_API(
          "post",
          `manage/getProductNoLanguage`,
          data,
          1
        );
        let datas = getAPI.data;
        commit("MUT_PROD_DATA", datas);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
