import store from '../store/index'

export function roleaction(role) {

    let getrole = store.getters['loginActions/GET_USER'].role

    let check = Object.values(getrole).find((obj) => {
        return String(obj.role_name).trim() == String(role).trim()

    })

    return check == undefined ? false : true
}