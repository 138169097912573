import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import { authrole } from "./rolerouter";
import Login from "../views/Login.vue";
import SelectBu from "../views/SelectBu.vue";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  // {
  //   path: "/select_bu",
  //   name: "SelectBu",
  //   component: SelectBu,
  //   meta: { requiresAuth: true },
  // },

  {
    path: "/redirect",
    name: "Redirect",
    meta: {},
    beforeEnter: async (to, from, next) => {
      try {
        if (to.query.token) {
          // let getToken = to.query.token;
          let getToken = decodeURI(to.query.token);
          store.dispatch("loginActions/loginaction", getToken);
          next({ name: "Home" });
        } else {
          next({ name: "Login" });
        }
      } catch (error) {
        next({ name: "Login" });
      }
    },
    component: Login,
  },
  { path: "/", redirect: "/home" },

  {
    path: "/home",
    name: "Home",
    meta: { requiresAuth: true },
    component: Home,
  },
  {
    path: "/brand",
    name: "Brand",
    meta: { requiresAuth: true, roles: true },
    component: Home,
  },

  {
    path: "/product/:id",
    name: "Productdetail",
    meta: { requiresAuth: true },
    component: () => import("../views/Productdetail.vue"),
  },
  {
    path: "/chg-user",
    name: "Chguser",
    meta: { requiresAuth: true },
    component: () => import("../views/AdminIT/Chguser.vue"),
  },
  // ---------------------------- AdminIT --------------------------
  {
    path: "/permission-user",
    name: "Permission-User",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/AdminIT/Permission_User.vue"),
  },
  {
    path: "/role-user",
    name: "Role-User",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/AdminIT/Role_User.vue"),
  },
  {
    path: "/manage-user",
    name: "ManageUser",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/User/ManageUser.vue"),
  },
  {
    path: "/history-login",
    name: "HistoryLogin",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/AdminIT/HistoryLogin.vue"),
  },
  {
    path: "/monitorenrichsku",
    name: "Monitor-Enrich-SKU",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/AdminIT/MonitorEnrichSKU.vue"),
  },

  {
    path: "/create-user-login",
    name: "CREATE-USER-LOGIN",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/AdminIT/CreateUserLogin.vue"),
  },
  {
    path: "/check-mss-to-bis",
    name: "CHECK-MMS-BIS",
    meta: { requiresAuth: false, roles: false },
    component: () => import("../views/AdminIT/Check_MSS_BIS.vue"),
  },
  // --------------------------Attribute------------------
  {
    path: "/properties-Attribute",
    name: "PropertiesAttribute",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/Attribute/Properties.vue"),
  },
  // --------------------------Manage------------------
  {
    path: "/remarks",
    name: "Remarks",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/Manage/Remarks.vue"),
  },
  {
    path: "/manageremark",
    name: "Manageremark",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/Manage/Manageremark"),
  },
  {
    path: "/managelinkcontent",
    name: "ManageLinkContent",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/Manage/ManageLinkContent"),
  },
  {
    path: "/managevendor",
    name: "ManageVendor",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/Manage/ManageVendor"),
  },
  {
    path: "/On-Off-OnWeb",
    name: "On-Off-OnWeb",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/Manage/OnOffOnWeb"),
  },
  {
    path: "/On-Off-OnWeb/add",
    name: "AddOnOff",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/Manage/AddOnOff"),
  },
  {
    path: "/ManageImage360",
    name: "ManageImage360",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/Manage/ManageImage360.vue"),
  },
  {
    path: "/ManageImageBrand",
    name: "ManageImageBrand",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/Manage/ManageImageBrand.vue"),
  },
  {
    path: "/ManagePimData",
    name: "ManagePimData",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/Manage/ManagePimData.vue"),
  },
  {
    path: "/UploadAttributeEN",
    name: "UploadAttributeEN",
    meta: { requiresAuth: true, roles: true },
    component: () =>
      import("../views/Manage/AttributeUploadEN/UploadAttributeEN.vue"),
  },
  {
    path: "/attribute-group",
    name: "Attribute-group",
    meta: { requiresAuth: true, roles: true },
    component: () =>
      import("../views/Manage/AttributesGroup/AttributesGroup.vue"),
  },
  {
    path: "/attribute-group/create",
    name: "Attribute-group-Create",
    meta: { requiresAuth: true, roles: true },
    component: () =>
      import("../views/Manage/AttributesGroup/AttributesGroupCreate.vue"),
  },
  {
    path: "/attribute-group/edit",
    name: "Attribute-group-Edit",
    meta: { requiresAuth: true, roles: true },
    component: () =>
      import("../views/Manage/AttributesGroup/AttributesGroupEdit.vue"),
  },
  {
    path: "/attributes",
    name: "Attributes",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/Manage/Attributes/Attributes.vue"),
  },
  {
    path: "/attributes/create",
    name: "Attributes-Create",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/Manage/Attributes/AttributesCreate.vue"),
  },
  {
    path: "/attributes/edit",
    name: "Attributes-Edit",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/Manage/Attributes/AttributesEdit.vue"),
  },

  {
    path: "/attributes/products",
    name: "Attributes-Products",
    meta: { requiresAuth: true, roles: true },
    component: () =>
      import("../views/Manage/AttributesProducts/AttributesProductsList.vue"),
  },
  {
    path: "/attributes/products/:skcode",
    name: "Attributes-Products-Detail",
    meta: { requiresAuth: true, roles: true },
    component: () =>
      import("../views/Manage/AttributesProducts/AttributesProductsDetail.vue"),
  },
  {
    path: "/attributes/products_v1/:skcode",
    name: "Attributes-Products-Detail-v1",
    meta: { requiresAuth: true, roles: true },
    component: () =>
      import(
        "../views/Manage/AttributesProducts/AttributesProductsDetail_v1.vue"
      ),
  },

  {
    path: "/attributes/valueproducts/",
    name: "Attribute_Value_Product",
    meta: { requiresAuth: true, roles: true },
    component: () =>
      import(
        "../views/Manage/AttributeValueProduct/AttributeValueProductList.vue"
      ),
  },
  {
    path: "/mapping-spu",
    name: "Mapping SPU",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/Manage/MappingSPU.vue"),
  },
  {
    path: "/boughttogether",
    name: "Bought Together",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/Manage/BoughtTogether.vue"),
  },
  {
    path: "/deliverymethod",
    name: "DeliveryMethod",
    meta: { requiresAuth: false, roles: true },
    component: () =>
      import("../views/Manage/DeliveryMethod/DeliveryMethod.vue"),
  },
  {
    path: "/warranty",
    name: "Warranty",
    meta: { requiresAuth: false, roles: true },
    component: () => import("../views/Manage/Warranty/Warranty.vue"),
  },
  {
    path: "/manage/sort-category-web",
    name: "UploadCategory",
    meta: { requiresAuth: true, roles: true },
    component: () => import("@/views/Manage/UploadCategory.vue"),
  },
  // ----------------------- Products-------------------
  {
    path: "/enrich-product",
    name: "Enrich Product",
    meta: { requiresAuth: true, roles: true },
    component: () => import("@/views/Products/EnrichProduct.vue"),
  },
  {
    path: "/Product-On-Web",
    name: "ProductOnWeb",
    meta: { requiresAuth: true, roles: true },
    component: () => import("@/views/Products/ProductOnWeb.vue"),
  },
  {
    path: "/enrich-product/:skcode",
    name: "Enrich Product Detail",
    meta: { requiresAuth: true, roles: true },
    component: () => import("@/views/Products/EnrichProductDetail.vue"),
  },
  {
    path: "/view-product/:skcode",
    name: "view Product",
    meta: { requiresAuth: true, roles: true },
    component: () => import("@/views/Products/ViewProduct.vue"),
  },
  {
    path: "/product-last-update",
    name: "Product Last Update",
    meta: { requiresAuth: true, roles: true },
    component: () => import("@/views/Products/ProductLastUpdate.vue"),
  },
  {
    path: "/my-product",
    name: "My Product",
    meta: { requiresAuth: true },
    component: () => import("@/views/Products/MyProduct.vue"),
  },
  {
    path: "/tracking-supplier",
    name: "Tracking Supplier",
    meta: { requiresAuth: true, roles: true },
    component: () => import("@/views/Products/TrackingSupplier.vue"),
  },
  {
    path: "/ProductInfo/GenarateQR",
    name: "ProductInfo-QR",
    meta: { requiresAuth: true, roles: true },
    component: () => import("@/views/Products/ProductInfo/GenarateQR.vue"),
  },
  {
    path: "/ProductInfo/ProductQR",
    name: "ProductInfo-ProductQR",
    meta: { requiresAuth: true, roles: true },
    component: () => import("@/views/Products/ProductInfo/ProductQR.vue"),
  },
  {
    path: "/ProductInfo/ProductQR/content/:skcode",
    name: "ProductInfo-ProductQR-Content",
    meta: { requiresAuth: true, roles: true },
    component: () =>
      import("@/views/Products/ProductInfo/ProductQR/Content.vue"),
  },
  {
    path: "/ProductInfo/ProductQR/preview/:skcode",
    name: "ProductInfo-ProductQR-Preview",
    meta: { requiresAuth: true, roles: true },
    component: () => import("@/views/Products/ProductInfo/Preview.vue"),
  },
  {
    path: "/ProductInfo/SKU_QR",
    name: "ProductInfo-SKUQR",
    meta: { requiresAuth: true, roles: true },
    component: () => import("@/views/Products/ProductInfo/SKUQR.vue"),
  },

  // ------------------------------- Market Place --------------------
  {
    path: "/market-place/upload-sku-install",
    name: "UploadSkuInstall",
    meta: { requiresAuth: true, roles: true },
    component: () => import("@/views/MarketPlace/UploadSkuInstall/index.vue"),
  },
  {
    path: "/market-palce/check-order",
    name: "CheckOrders",
    meta: { requiresAuth: true, roles: true },
    component: () => import("@/views/MarketPlace/CheckOrders/index.vue"),
  },
  {
    path: "/market-place/check-status",
    name: "CheckStatus",
    meta: { requiresAuth: true, roles: true },
    component: () => import("@/views/MarketPlace/CheckStatus/index.vue"),
  },
  {
    path: "/market-place/upload-stock/pre-order",
    name: "UploadStockMKP_PreOrder",
    meta: { requiresAuth: true, roles: true },
    component: () =>
      import("@/views/MarketPlace/UploadStockPreOrder/index.vue"),
  },
  {
    path: "/market-place/upload-stock/red-text",
    name: "UploadStockMKP_RedText",
    meta: { requiresAuth: true, roles: true },
    component: () => import("@/views/MarketPlace/UploadStockRedText/index.vue"),
  },
  {
    path: "/market-place/officemate/manage-product",
    name: "OFM_ManageProduct",
    meta: { requiresAuth: true, roles: true },
    component: () =>
      import("@/views/MarketPlace/OfficeMateManageProduct/index.vue"),
  },
  // ------------------------------- setting --------------------
  {
    path: "/data-conditions",
    name: "Data Conditions",
    meta: { requiresAuth: true, roles: true },
    component: () => import("@/views/Setting/Dataconditions.vue"),
  },
  // ----------------------- other -----------

  {
    path: "/notrole",
    name: "NotRole",
    meta: { requiresAuth: true },
    component: () => import("../views/NotRole.vue"),
  },

  {
    path: "/ManageRemarkMessage",
    name: "ManageRemarkMessage",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/Manage/ManageRemarkMessage.vue"),
  },
  {
    path: "/UpdateProductRemark",
    name: "UpdateProductRemark",
    meta: { requiresAuth: true },
    component: () =>
      import("../components/Manage/RemarkMessage/UpdateProductRemark.vue"),
  },
  {
    path: "/DownloadCSVData",
    name: "DownloadCSVData",
    meta: { requiresAuth: true, roles: true },
    component: () => import("../views/Report/DownloadCSVData"),
  },
  {
    path: "/NotFound404",
    name: "NotFound404",
    meta: { requiresAuth: false },
    component: () => import("../views/NotFound404.vue"),
  },
  {
    path: "*",
    name: "NotFound",
    meta: { requiresAuth: true },
    component: () => import("../views/NotFound.vue"),
  },
  // {
  //   path: '/ChangePassword/:ckey',
  //   name: 'ChangePassword',
  //   props: true,
  //   component: () => import(/* webpackChunkName: "about" */ '../views/ChangePassword.vue'),
  //   meta: { requiresAuth: false },
  // },
  // {
  //   path: '/usermanage',
  //   name: 'usermanage',
  //   meta: { requiresAuth: true },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/UserManage.vue'),

  // },
  // {
  //   path: '/addUser',
  //   name: 'addUser',
  //   meta: { requiresAuth: true },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/addUser.vue'),
  // },
  // {

  //   path: '/ChangePasswordUser/:ckey',
  //   name: 'ChangePasswordUser',
  //   meta: { requiresAuth: true },
  //   props: true,
  //   component: () => import(/* webpackChunkName: "about" */ '../views/ChangePasswordUser.vue'),
  // },

  // {
  //   path: '/UserRespon',
  //   name: 'UserRespon',
  //   meta: { requiresAuth: true },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/UserRespon.vue'),
  // },
  // {
  //   path: '/editUser/:userid',
  //   name: 'editUser',
  //   props: true,
  //   component: () => import(/* webpackChunkName: "about" */ '../views/editUser.vue'),
  //   meta: { requiresAuth: false },
  // },
  // {
  //   path: '/PetitionDetail/:ConsentNo',
  //   name: 'PetitionDetail',
  //   props: true,
  //   component: () => import(/* webpackChunkName: "about" */ '../views/PetitionDetail.vue'),
  //   meta: { requiresAuth: false },
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title =
    to.name == "Home"
      ? `${process.env.VUE_APP_TITLE}`
      : `${String(process.env.VUE_APP_TITLE).split(" ")[0]} | ${String(
          to.name.replace(/[_-]/g, " ")
        ).toLocaleUpperCase()}`;
  store.dispatch("loginActions/checkauth");
  store.dispatch("breadcrumbActions/set_breadcrumb", to.name);

  // store.dispatch('loginActions/setkey')
  // store.dispatch("loginActions/followkey");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["loginActions/GET_AUTH"]) {
      router.push({ name: "Login" });
    }
    // else if (!!!store.getters["loginActions/GET_BU"] && to.name !== 'SelectBu' && to.name !== 'Login') {

    //   router.push({ name: "SelectBu" });

    // }
  }
  // if (!!!store.getters["loginActions/GET_BU"] && to.name !== 'SelectBu' && to.name !== 'Login') {

  //   router.push({ name: "SelectBu" });

  // }
  if (to.matched.some((record) => record.meta.roles)) {
    let role = await authrole(to.name);
    if (process.env.NODE_ENV == "dev") {
      console.log(role);
    }

    if (!role) {
      next({
        name: "NotRole",
      });
      return;
    }
  }

  next(); // make sure to always call next()!
});

export default router;
