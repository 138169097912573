// import AuthService from '../services/auth.service';
import * as main from '../../services/mainservice';
import * as constant from '../../services/constant';
import * as serviceAPI from "../../services/APIservice";

import store from '@/store/index'
const user = main.readCookietoken(constant.TOKEN_KEY);
let exp = main.readCookieEncrypt('exp');
let bu = 'PWB'
const menu = localStorage.getItem('menu');
let time
const initialState = user ?
  {
    auth: true,
    user: user,
    bu_mode: bu
    , exp: exp
    , menu: JSON.parse(menu)

  } :
  {
    auth: false,
    user: null,
    bu_mode: null
    , exp: null
    , menu: []

  };

export const loginActions = {
  namespaced: true,
  state: initialState,
  mutations: {
    MUT_USER(state, value) {
      state.user = value;
    },
    MUT_LOGIN(state, value) {
      state.auth = value;
    },
    MUT_LOGOUT(state, value) {
      state.auth = false;
    },
    MUT_EXP(state, value) {
      state.exp = value;
    },
    MUT_BU(state, value) {
      state.bu_mode = value;
    },
    MUT_MENU(state, value) {
      state.menu = value;
    },

  },
  getters: {
    GET_USER(state) {
      return state.user;
    },
    GET_AUTH(state) {
      return state.auth;
    },
    GET_EXP(state) {
      return state.exp;
    },
    GET_BU(state) {
      return state.bu_mode;
    },
    GET_MENU(state) {
      return state.menu;
    },

  },

  actions: {
    checkauth({ commit }) {
      const user = main.readCookietoken(constant.TOKEN_KEY);

      if (user == null) {
        commit('MUT_LOGIN', false)
      }
    },

    async loginaction({ commit, dispatch }, para_token) {
      // -------------- timestamp------------------
      let tk = await main.decodeJwt(para_token)
      let iat = new Date()
      let exp = await timestamp(tk.exp)
      let diff = (exp - iat) / 1000 / 60
      // -------------- timestamp------------------
      main.saveCookiemintoken(constant.TOKEN_KEY, para_token, diff)

      main.saveCookieEncryptMin('exp', diff, diff)
      let getUser = main.readCookietoken(constant.TOKEN_KEY)
      let ckexp = main.readCookieEncrypt('exp');
      commit('MUT_LOGIN', true)
      commit('MUT_USER', getUser)
      commit('MUT_EXP', ckexp)


      // ------------------- reload  ตอน login เสมอ เผื่อมีอัพเดท font end ------
      // location.reload();
      // ------------------- reload  ตอน login เสมอ เผื่อมีอัพเดท font end ------

    },
    logoutaction({ commit }) {

      localStorage.clear();
      deleteAllCookies()
      commit('MUT_LOGOUT')
      commit('MUT_USER', null)



    },
    async setBu({ commit, dispatch }, { val, menu }) {

      let exp = main.readCookieEncrypt('exp');

      main.saveCookieEncryptMin('bu', val, exp)
      commit('MUT_BU', val)

      localStorage.setItem('menu', JSON.stringify(menu));
      commit('MUT_MENU', menu)
    },

  },
};

function timestamp(in_time) {
  return new Promise((resolve, reject) => {
    let unix_timestamp = in_time
    var date = new Date(unix_timestamp * 1000);

    var year = date.getFullYear();
    var month = date.getMonth();
    var day = date.getDate();

    var hours = date.getHours();

    var minutes = "0" + date.getMinutes();

    var seconds = "0" + date.getSeconds();

    var time = `${year}-${month}-${day} ${hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2)}`;
    let formattedTime = new Date(time.split(' ')[0].split('-')[0], time.split(' ')[0].split('-')[1], time.split(' ')[0].split('-')[2], time.split(' ')[1].split(':')[0], time.split(' ')[1].split(':')[1], time.split(' ')[1].split(':')[2])
    resolve(formattedTime)
  });

}

function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}