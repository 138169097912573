import * as main from '../../services/mainservice';
import * as constant from '../../services/constant';
import * as serviceAPI from "../../services/APIservice";

export const assigntasksActions = {
    namespaced: true,
    state:
    {
        copyWr_Qc: null,
        copyWr_Creator: null,
        graphicDsn_Qc: null,
        missing_Data: null,
        req_Image: null,
        dataassign: null,
        data_log_enrich: null
    }
    ,
    mutations: {
        mut_copyWr_Qc(state, value) {

            state.copyWr_Qc = value;
        },
        mut_copyWr_Creator(state, value) {

            state.copyWr_Creator = value;
        },
        mut_graphicDsn_Qc(state, value) {

            state.graphicDsn_Qc = value;
        },
        mut_missing_Data(state, value) {

            state.missing_Data = value;
        },
        mut_req_Image(state, value) {

            state.req_Image = value;
        },
        mut_dataassign(state, value) {

            state.dataassign = value;
        },
        mut_data_log_enrich(state, value) {

            state.data_log_enrich = value;
        },


    },
    getters: {
        get_copyWr_Qc(state) {
            return state.copyWr_Qc;
        },
        get_copyWr_Creator(state) {
            return state.copyWr_Creator;
        },
        get_graphicDsn_Qc(state) {
            return state.graphicDsn_Qc;
        },
        get_missing_Data(state) {
            return state.missing_Data;
        },
        get_req_Image(state) {
            return state.req_Image;
        },
        get_dataassign(state) {
            return state.dataassign;
        },
        get_data_log_enrich(state) {
            return state.data_log_enrich;
        },
    },
    actions: {

        async call_copyWr_Qc({ commit }) {
            try {
                commit('mut_copyWr_Qc', null)
                let data = {
                    permission_id: '892b01ea-ed27-43da-b25e-d09d0b3cb035'

                };

                let getAPI = await serviceAPI.call_API(
                    "post",
                    `access/getuseraccess`,
                    data,
                    1
                );



                commit('mut_copyWr_Qc', getAPI.data)

            } catch (error) {
                console.log(error);
                alert(error)
                commit('mut_copyWr_Qc', null)
            }
        },
        async call_copyWr_Creator({ commit }) {
            try {
                commit('mut_copyWr_Creator', null)
                let data = {
                    permission_id: '21ee592c-39b9-4a58-abff-11607b93d04e'

                };

                let getAPI = await serviceAPI.call_API(
                    "post",
                    `access/getuseraccess`,
                    data,
                    1
                );



                commit('mut_copyWr_Creator', getAPI.data)

            } catch (error) {
                console.log(error);
                alert(error)
                commit('mut_copyWr_Creator', null)
            }
        },
        async call_graphicDsn_Qc({ commit }) {
            try {
                commit('mut_graphicDsn_Qc', null)
                let data = {
                    permission_id: '747bb93f-d278-415c-8329-edf9b0566097'

                };

                let getAPI = await serviceAPI.call_API(
                    "post",
                    `access/getuseraccess`,
                    data,
                    1
                );



                commit('mut_graphicDsn_Qc', getAPI.data)

            } catch (error) {
                console.log(error);
                alert(error)
                commit('mut_graphicDsn_Qc', null)
            }
        },
        async call_req_Image({ commit }) {
            try {
                commit('mut_req_Image', null)
                let data = {
                    mode: 'image'

                };

                let getAPI = await serviceAPI.call_API(
                    "post",
                    `access/getdatacontent`,
                    data,
                    1
                );



                commit('mut_req_Image', getAPI.data)

            } catch (error) {
                console.log(error);
                alert(error)
                commit('mut_req_Image', null)
            }
        },
        async call_missing_Data({ commit }) {
            try {
                commit('mut_missing_Data', null)
                let data = {
                    mode: 'data'

                };

                let getAPI = await serviceAPI.call_API(
                    "post",
                    `access/getdatacontent`,
                    data,
                    1
                );



                commit('mut_missing_Data', getAPI.data)

            } catch (error) {
                console.log(error);
                alert(error)
                commit('mut_missing_Data', null)
            }
        },
        async call_dataassign({ commit }) {
            try {

                commit('mut_dataassign', null)
                commit('mut_data_log_enrich', null)

                let getter = this.getters['enrichproductdetailActions/get_data']
                if (getter !== null) {

                    const { assign_Id, spcode, skCode } = getter
                    let data = {
                        assign_Id: assign_Id,
                        vendor_Id: spcode
                    };

                    let getAPI = await serviceAPI.call_API(
                        "post",
                        `access/getuseraccessbyid`,
                        data,
                        1
                    );

                    let getAPI_log_enrich = await serviceAPI.call_API(
                        "post",
                        `access/getLastApporve`,
                        { skCode: skCode },
                        1
                    );

                    if (getAPI.data.length > 0) {
                        let data = getAPI.data[0]
                        commit('mut_dataassign', data)
                    }

                    if (getAPI_log_enrich.data.length > 0) {
                        let data = getAPI_log_enrich.data[0]
                        commit('mut_data_log_enrich', data)
                    }

                }


            } catch (error) {
                console.log(error);
                alert(error)
                commit('mut_dataassign', null)
                commit('mut_data_log_enrich', null)
            }
        },
        async call_dataassign_again({ commit }) {
            try {

                let getter = await this.getters['enrichproductdetailActions/get_data']

                if (getter !== null) {
                    const { assign_Id, spcode } = getter
                    let data = {
                        assign_Id: assign_Id,
                        vendor_Id: spcode
                    };

                    let getAPI = await serviceAPI.call_API(
                        "post",
                        `access/getuseraccessbyid`,
                        data,
                        1
                    );

                    if (getAPI.data.length > 0) {
                        let data = getAPI.data[0]

                        let { copyWr_Qc_Status, graphicDsn_Qc_Status, contactVd_Cw_Status, contactVd_Gd_Status, copyWr_Creator_Status,
                            graphicDsn_Creator_Status, senior_copyWr_Staus, senior_graphicDsn_Status, missing_Data_Done, req_Image_Done,
                            push_data_already, push_img_already


                            , copyWr_Qc, graphicDsn_Qc, contactVd_Cw, contactVd_Gd, copyWr_Creator, graphicDsn_Creator, senior_copyWr,
                            senior_graphicDsn, push_data_already__user, push_img_already_user } = data
                        let getter_qc = await this.getters['assigntasksActions/get_dataassign']

                        getter_qc.copyWr_Qc_Status = String(copyWr_Qc_Status).trim() == 'Y' ? copyWr_Qc_Status : getter_qc.copyWr_Qc_Status
                        getter_qc.graphicDsn_Qc_Status = String(graphicDsn_Qc_Status).trim() == 'Y' ? graphicDsn_Qc_Status : getter_qc.graphicDsn_Qc_Status
                        getter_qc.contactVd_Cw_Status = String(contactVd_Cw_Status).trim() == 'Y' ? contactVd_Cw_Status : getter_qc.contactVd_Cw_Status
                        getter_qc.contactVd_Gd_Status = String(contactVd_Gd_Status).trim() == 'Y' ? contactVd_Gd_Status : getter_qc.contactVd_Gd_Status
                        // getter_qc.copyWr_Creator_Status = String(copyWr_Creator_Status).trim() == 'Y' ? copyWr_Creator_Status : getter_qc.copyWr_Creator_Status
                        // getter_qc.graphicDsn_Creator_Status = String(graphicDsn_Creator_Status).trim() == 'Y' ? graphicDsn_Creator_Status : getter_qc.graphicDsn_Creator_Status
                        // getter_qc.senior_copyWr_Staus = String(senior_copyWr_Staus).trim() == 'Y' ? senior_copyWr_Staus : getter_qc.senior_copyWr_Staus
                        // getter_qc.senior_graphicDsn_Status = String(senior_graphicDsn_Status).trim() == 'Y' ? senior_graphicDsn_Status : getter_qc.senior_graphicDsn_Status

                        getter_qc.copyWr_Creator_Status = String(copyWr_Creator_Status).trim() 
                        getter_qc.graphicDsn_Creator_Status = String(graphicDsn_Creator_Status).trim()  
                        getter_qc.senior_copyWr_Staus = String(senior_copyWr_Staus).trim() 
                        getter_qc.senior_graphicDsn_Status = String(senior_graphicDsn_Status).trim() 

                        getter_qc.missing_Data_Done = String(missing_Data_Done).trim() == 'Y' ? missing_Data_Done : getter_qc.missing_Data_Done

                      

                        getter_qc.req_Image_Done = String(req_Image_Done).trim() == 'Y' ? req_Image_Done : getter_qc.req_Image_Done
                        getter_qc.push_data_already = String(push_data_already).trim() == 'Y' ? push_data_already : getter_qc.push_data_already
                        getter_qc.push_img_already = String(push_img_already).trim() == 'Y' ? push_img_already : getter_qc.push_img_already

                        getter_qc.copyWr_Qc = String(copyWr_Qc).trim() !== '' ? copyWr_Qc : getter_qc.copyWr_Qc
                        getter_qc.graphicDsn_Qc = String(graphicDsn_Qc).trim() !== '' ? graphicDsn_Qc : getter_qc.graphicDsn_Qc
                        getter_qc.contactVd_Cw = String(contactVd_Cw).trim() !== '' ? contactVd_Cw : getter_qc.contactVd_Cw
                        getter_qc.contactVd_Gd = String(contactVd_Gd).trim() !== '' ? contactVd_Gd : getter_qc.contactVd_Gd
                        getter_qc.copyWr_Creator = String(copyWr_Creator).trim() !== '' ? copyWr_Creator : getter_qc.copyWr_Creator
                        getter_qc.graphicDsn_Creator = String(graphicDsn_Creator).trim() !== '' ? graphicDsn_Creator : getter_qc.graphicDsn_Creator
                        getter_qc.senior_copyWr = String(senior_copyWr).trim() !== '' ? senior_copyWr : getter_qc.senior_copyWr
                        getter_qc.senior_graphicDsn = String(senior_graphicDsn).trim() !== '' ? senior_graphicDsn : getter_qc.senior_graphicDsn
                        getter_qc.push_data_already__user = String(push_data_already__user).trim() !== '' ? push_data_already__user : getter_qc.push_data_already__user
                        getter_qc.push_img_already_user = String(push_img_already_user).trim() !== '' ? push_img_already_user : getter_qc.push_img_already_user
                        commit('mut_dataassign', getter_qc)
                    }

                }


            } catch (error) {
                console.log(error);
                alert(error)
                commit('mut_dataassign', null)
            }
        },
        dataassign_null({ commit }) {
            commit('mut_dataassign', null)
        },
        async set_AssignTasks({ commit }, val) {


            let getter = this.getters['assigntasksActions/get_dataassign']
            if (getter !== null) {
                const { timeLine, graphicDsn_Qc, copyWr_Creator, copyWr_Qc } = val
                getter.timeLine = timeLine
                getter.graphicDsn_Qc = graphicDsn_Qc
                getter.copyWr_Creator = copyWr_Creator
                getter.copyWr_Qc = copyWr_Qc
                commit('mut_dataassign', getter)
            }


        },
        async set_preqc({ commit }, val) {

            let getter = this.getters['assigntasksActions/get_dataassign']
            if (getter !== null) {

                const { qty_Corners, select_missing_Data, other_missing_data, select_req_Image, other_req_Image, graphicDsn_Qc, remark,
                    copyWr_Qc,
                    copyWr_Qc_Status,
                    graphicDsn_Qc_Status, missing_Data_Done,
                    req_Image_Done, remark1
                } = val
                let find_missing_data = select_missing_Data.findIndex((e) => e == "OTHER");
                let find_req_Imag = select_req_Image.findIndex((e) => e == "OTHER");
                let _missing_data = []
                let _req_Imag = []
                for await (var i of select_missing_Data) {
                    _missing_data.push(i)
                }
                if (find_missing_data !== -1) {

                    _missing_data[find_missing_data] = `OTHER:${other_missing_data}`
                }


                for await (var i of select_req_Image) {
                    _req_Imag.push(i)
                }
                if (find_req_Imag !== -1) {

                    _req_Imag[find_req_Imag] = `OTHER:${other_req_Image}`
                }

                getter.missing_Data = _missing_data.toString()
                // getter.progress_Action = progress_Action
                // getter.qty_Corners = qty_Corners
                // getter.recrieve_Date = recrieve_Date
                getter.qty_Corners = qty_Corners;
                getter.req_Image = _req_Imag.toString()
                getter.graphicDsn_Qc = graphicDsn_Qc
                getter.copyWr_Qc = copyWr_Qc
                getter.remark = remark
                getter.remark1 = remark1
                getter.copyWr_Qc_Status = copyWr_Qc_Status
                getter.graphicDsn_Qc_Status = graphicDsn_Qc_Status
                getter.missing_Data_Done = missing_Data_Done == true || missing_Data_Done == 'Y' ? 'Y' : 'N'
                getter.req_Image_Done = req_Image_Done == true || req_Image_Done == 'Y' ? 'Y' : 'N'
                commit('mut_dataassign', getter)
            }


        },

        async set_Contactvendor({ commit }, val) {

            let getter = this.getters['assigntasksActions/get_dataassign']
            if (getter !== null) {

                const { qty_Corners, progress_Action, contact_vendor_Status, recrieve_Date, receive_more_image, missing_Data_Check,
                    req_Image_Check,
                    contactVd_Cw_Status,
                    contactVd_Gd_Status,
                    contactVd_Cw,
                    contactVd_Gd,
                    select_missing_Data_Check,
                    select_req_Image_Check, get_missing_Data,
                    get_req_Image, } = val

                let _select_missing_Data_Check = []
                for await (var d of get_missing_Data) {

                    let find = select_missing_Data_Check.findIndex(e => e == d)

                    if (find == -1) { _select_missing_Data_Check.push('') }
                    else { _select_missing_Data_Check.push(d) }

                }

                let _select_req_Image_Check = []
                for await (var d of get_req_Image) {

                    let find = select_req_Image_Check.findIndex(e => e == d)

                    if (find == -1) { _select_req_Image_Check.push('') }
                    else { _select_req_Image_Check.push(d) }

                }


                getter.missing_Data_Check = _select_missing_Data_Check.toString();
                getter.req_Image_Check = _select_req_Image_Check.toString();
                getter.contactVd_Cw_Status = contactVd_Cw_Status == 'Y' ? 'Y' : _select_missing_Data_Check.toString() == get_missing_Data.toString() && get_missing_Data.toString() !== '' ? 'Y' : "N";
                getter.contactVd_Gd_Status = contactVd_Gd_Status == 'Y' ? 'Y' : _select_req_Image_Check.toString() == get_req_Image.toString() && get_req_Image.toString() !== '' ? 'Y' : "N";
                getter.contactVd_Gd = contactVd_Gd;
                getter.contactVd_Cw = contactVd_Cw;




                getter.qty_Corners = qty_Corners;
                getter.progress_Action = progress_Action;
                getter.contact_vendor_Status = contact_vendor_Status
                getter.recrieve_Date = recrieve_Date
                getter.receive_more_image = receive_more_image
                commit('mut_dataassign', getter)
            }


        },

        async set_Assignreq({ commit }, val) {

            let getter = this.getters['assigntasksActions/get_dataassign']
            if (getter !== null) {
                const { missing_Data, progress_Action, qty_Corners, recrieve_Date, req_Image, user_email, to_email, cc_email, subject, } = val
                getter.missing_Data = await main.chechnull(missing_Data).toString()
                getter.progress_Action = progress_Action
                getter.qty_Corners = qty_Corners
                getter.recrieve_Date = recrieve_Date
                getter.req_Image = await main.chechnull(req_Image).toString()
                getter.user_email = user_email
                getter.to_email = to_email
                getter.cc_email = cc_email
                getter.subject = subject
                commit('mut_dataassign', getter)
            }


        },

    },

};


