// import AuthService from '../services/auth.service';
// import * as main from '../../services/mainservice';
import * as constant from '../../services/constant';
import * as serviceAPI from "../../services/APIservice";


export const selectedActions = {
  namespaced: true,
  state: {
    selectedStatus: null,
    selectedStoreGroup: null,
    selectedDSRLogCancel: null
  },
  mutations: {
    MUT_SELECTED_STATUS(state, value) {
      state.selectedStatus = value;
    },
    MUT_SELECTED_STORE_GROUP(state, value) {
      state.selectedStoreGroup = value;
    },
    MUT_SELECTED_DSR_LOG_CANCEL(state, value) {
      state.selectedDSRLogCancel = value;
    }
  },
  getters: {
    GET_SELECTED_STATUS(state) {
      return state.selectedStatus;
    },
    GET_SELECTED__STORE_GROUP(state) {
      return state.selectedStoreGroup;
    },
    GET_SELECTED_DSR_LOG_CANCEL(state) {
      return state.selectedDSRLogCancel;
    }
  },
  actions: {
    async getSelectedStatus({ commit }) {
      try {
        let data = {};
        let getAPI = await serviceAPI.call_API("get", "dsr/GetSelectedStatus", data, "auth");
        var datas = getAPI.data
        // console.log(datas);
        commit('MUT_SELECTED_STATUS', datas)
      } catch (error) {
        commit('MUT_SELECTED_STATUS', null)
      }
    },
    async getSelectedStoreByStaffGroup({ commit, getters }) {
      try {
        let store = this.getters['loginActions/GET_FULLUSER']
        let data = {
          StoreId: store.QTSTOREID,
          GroupId: store.QTGROUPID
        };
        let getAPI = await serviceAPI.call_API("post", "dsr/GetDataSelectedStoreByStaffGroup", data, "auth");
        var datas = getAPI.data
        commit('MUT_SELECTED_STORE_GROUP', datas)
      } catch (error) {
        commit('MUT_SELECTED_STORE_GROUP', null)
      }
    }, 
    async getSelectedDSRLogCancel({ commit }) {
      try {
        let data = {};
        let getAPI = await serviceAPI.call_API("get", "dsr/getdsrlogcancel", data, "auth");
        var datas = getAPI.data
        // console.log(datas);
        commit('MUT_SELECTED_DSR_LOG_CANCEL', datas)
      } catch (error) {
        commit('MUT_SELECTED_DSR_LOG_CANCEL', null)
      }
    },
  },
};