
// export const API_URL = "http://localhost:5000/api/";
// export const imageUrl = "http://localhost:5000/";
// export const API_URL = "http://10.77.2.20:2022/api/";
// export const imageUrl = "http://10.77.2.20:2022/";
export const API_URL = process.env.VUE_APP_API_URL;
export const TOKEN_KEY = process.env.VUE_APP_TOKEN_KEY;
export const API_LOGIN_URL = process.env.VUE_APP_API_LOGIN_URL;
export const API_LOGOUT_URL = process.env.VUE_APP_API_LOGOUT_URL;
export const API_URL_IMG = process.env.VUE_APP_API_URL_IMG;
export const API_IMG_UPLOAD = process.env.VUE_APP_API_IMG_UPLOAD;
export const URL_TWD = process.env.VUE_APP_URL_TWD;
export const URL_TWD_IMG = process.env.VUE_APP_URL_TWD_IMG;
export const MODE = process.env.VUE_APP_MODE;
export const URL_360 = process.env.VUE_APP_URL_360;
