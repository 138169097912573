<template>
  <div class="container-fluid m-0 p-0 bfont shadow d-none d-lg-inline-block">
    <nav
      class="navbar navbar-expand-lg "
      :class="
        $cons.MODE === 'production'
          ? `navcolor-${$store.getters['loginActions/GET_BU']}`
          : `navcolor-uat-${$store.getters['loginActions/GET_BU']}`
      "
    >
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav mr-auto row w-100 justify-content-around">
          <li class="nav-item" v-for="(e, i) in $store.getters['loginActions/GET_MENU']" :key="i + 'h'">
            <div
              class="m-0 p-0   align-items-center"
              type="button"
              :class="[
                e.item.length > 0 ? 'dropdown dropdown-toggle' : '',
                `navtitle-${$store.getters['loginActions/GET_BU']}`,
              ]"
              @click="e.item.length == 0 ? goto(e.routename) : ''"
            >
              <span v-html="e.icon" class="mx-1"></span>
              <span class="m-0 p-0">{{ e.name }}</span>
              <!-- ------- box 1------- -->
              <div class="dropdown-content m-0 p-0">
                <div
                  class="m-0 px-0 py-2 "
                  :class="`item-${$store.getters['loginActions/GET_BU']}`"
                  v-for="(e1, i1) in e.item"
                  :key="i1 + 'b'"
                >
                  <div
                    class="m-0 p-0  "
                    :class="[
                      e1.item.length > 0 ? 'dropdown-menu w-100' : '',
                      `item-${$store.getters['loginActions/GET_BU']}`,
                    ]"
                    @click="e1.item.length == 0 ? goto(e1.routename) : ''"
                  >
                    <span class="px-3 py-2 w-100">
                      <span
                        v-html="icon.l"
                        v-if="$nav_menu.length / 2 <= i && e1.item.length > 0"
                      ></span>
                      {{ e1.name
                      }}<span
                        class="text-right"
                        v-html="icon.r"
                        v-if="$nav_menu.length / 2 > i && e1.item.length > 0"
                      ></span>
                    </span>

                    <div
                      :class="
                        $nav_menu.length / 2 <= i
                          ? 'dropdown-menu-content-lelt'
                          : 'dropdown-menu-content-rigth'
                      "
                    >
                      <div
                        class="m-0 p-0"
                        v-for="(e2, i2) in e1.item"
                        :key="i2 + 'body'"
                      >
                        <div
                          class="m-0 py-2 px-3  "
                          :class="
                            `item-${$store.getters['loginActions/GET_BU']}`
                          "
                          @click="goto(e2.routename)"
                        >
                          {{ e2.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ------- end box 1------- -->
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
   
      icon: {
        l: `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
  <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
</svg>`,
        r: `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
  <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
</svg>`,
      },
    };
  },
  created() {
    
  },
  methods: {
 
    goto(name) {
      this.$router.push({ name: name });
    },
  },
};
</script>

<style scoped>
/* .navtitle:hover {
  color: white;
} */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;

  right: 0%;
  background-color: #f9f9f9;
  color: black;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* padding: 12px 16px; */
  border-radius: 5px 5px 5px 5px; /*  t t b b */
  z-index: 1200;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content div .dropdown-menu {
  position: relative;
  display: inline-block;
  border-style: none;
  background-color: transparent;
}

.dropdown-menu-content-lelt {
  display: none;
  position: absolute;
  top: 0;
  right: 100%;
  background-color: #f9f9f9;
  color: black;
  min-width: 160px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.2);
  /* padding: 12px 16px; */
  border-radius: 5px 5px 5px 5px; /*  t t b b */
  /* z-index: 1; */
}
.dropdown-menu-content-rigth {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #f9f9f9;
  color: black;
  min-width: 160px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.2);
  /* padding: 12px 16px; */
  border-radius: 5px 5px 5px 5px; /*  t t b b */
  /* z-index: 1; */
}

.dropdown-content .dropdown-menu:hover .dropdown-menu-content-rigth {
  display: block;
}
.dropdown-content .dropdown-menu:hover .dropdown-menu-content-lelt {
  display: block;
}
</style>
