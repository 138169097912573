<template>
  <b-sidebar
    id="menubar"
    aria-labelledby="sidebar-no-header-title"
    no-header
    shadow
    backdrop
    bg-variant="white"
  >
    <template>
      <!-- <b-navbar
        toggleable="lg"
        type="dark"
        variant=""
        class="stylenavbar headercolor"
      >
        <b-button variant="outline-dark" @click="hide"
          ><b-icon icon="list" aria-hidden="true"></b-icon
        ></b-button>
      </b-navbar> -->
      <div class="p-3" style="font-size: 14px">
        <nav class="mb-3">
          <b-nav vertical>
            <b-nav-item v-for="(e, i) in $store.getters['loginActions/GET_MENU']" :key="i + 'h'">
              <p
                @click="
                  e.item.length == 0
                    ? goto(e.routename)
                    : settoggle(e.routename)
                "
                class="font-600 font-1-2s "
                :class="[
                  e.item.length > 0 ? ' ' : '',
                  $route.name == e.routename
                    ? String($cons.MODE) == 'pro'
                      ? `textselect-${$store.getters['loginActions/GET_BU']}`
                      : `textselect-uat-${$store.getters['loginActions/GET_BU']}`
                    : 'textunselect',
                ]"
                v-b-toggle="e.routename"
              >
                <span v-html="e.icon"> </span>
                <span class="ml-2">{{ e.name }} </span>
              </p>

              <b-collapse visible v-if="e.item.length > 0" :id="e.routename">
                <div
                  class="ml-5 p-0"
                  v-for="(e1, i1) in e.item"
                  :key="i1 + 'b'"
                >
                  <p
                    @click="
                      e1.item.length == 0
                        ? goto(e1.routename)
                        : setsuntoggle(e1.routename)
                    "
                    class="font-500"
                    :class="[
                      e1.item.length > 0 ? ' ' : '',
                      $route.name == e1.routename ||
                      subtogglename == e1.routename ||
                      e1.item.some((x) => x.routename == $route.name)
                        ? String($cons.MODE) == 'pro'
                          ? `textselect-${$store.getters['loginActions/GET_BU']}`
                          : `textselect-uat-${$store.getters['loginActions/GET_BU']}`
                        : 'textunselect',
                    ]"
                    v-b-toggle="e1.routename"
                  >
                    {{ e1.name }}
                  </p>
                  <b-collapse v-if="e1.item.length > 0" :id="e1.routename">
                    <div
                      class="ml-3 p-0"
                      v-for="(e2, i2) in e1.item"
                      :key="i2 + 'body'"
                    >
                      <p
                        @click="goto(e2.routename)"
                        :class="[
                          $route.name == e2.routename
                            ? String($cons.MODE) == 'pro'
                              ? `textselect-${$store.getters['loginActions/GET_BU']}`
                              : `textselect-uat-${$store.getters['loginActions/GET_BU']}`
                            : 'textunselect',
                        ]"
                      >
                        {{ e2.name }}
                      </p>
                    </div>
                  </b-collapse>
                </div>
              </b-collapse>
            </b-nav-item>
          </b-nav>
        </nav>
      </div>
    </template>
    <template #footer="{ hide }">
      <b-button block squared size="sm" variant="danger" @click="hide, logout()"
        >Sign Out/ออกจากระบบ</b-button
      >
    </template>
  </b-sidebar>
</template>

<script>
export default {
  name: "Menubar",
  data() {
    return {
    
      togglename: "",
      subtogglename: "",
      findsomesubroute: "",
      user: this.$serviceMain.parseJwtByLocalStorage("user"),
    };
  },
 
  mounted() {
    this.somesubroute();

    // ------
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      if (collapseId == "menubar" && !isJustShown) {
        if (this.togglename != "") {
          this.$root.$emit("bv::toggle::collapse", `${this.togglename}`);
        }
        if (this.subtogglename != "") {
          this.$root.$emit("bv::toggle::collapse", `${this.subtogglename}`);
        }

        this.togglename = "";
        this.subtogglename = "";
      }
    });
    // --------
  },
  watch: {},
  computed: {},
  methods: {
 
    async somesubroute() {
      this.findsomesubroute = "";
      let routename = this.$route.name;
      let ietm = this.$store.getters['loginActions/GET_MENU'];
      for (var [i, v] of ietm.entries()) {
        if (v.item.length > 0) {
          let _item = v.item;
          if (_item.length > 0) {
            for (var [k, e] of _item.entries()) {
              if (e.item.length > 0) {
                let data = e.item;
                for (var [u, w] of data.entries()) {
                  if (w.routename == routename) {
                    // console.log(w.routename);
                    // console.log(v.name);
                    this.findsomesubroute = v.name;
                  }
                }
              }
            }
          }
        }
      }
    },
    settoggle(name) {
      if (this.togglename != name) {
        this.$root.$emit("bv::toggle::collapse", `${this.togglename}`);
        this.$root.$emit("bv::toggle::collapse", `${this.subtogglename}`);
        this.subtogglename = "";
      }

      // this.togglename = name;
    },
    setsuntoggle(name) {
      if (this.subtogglename != name) {
        this.$root.$emit("bv::toggle::collapse", `${this.subtogglename}`);
      }
      this.subtogglename = name;
    },
    goto(pathname) {
      if (this.$route.name != pathname) this.$router.push({ name: pathname });
      this.hidemenubar();

      this.somesubroute();
    },
    hidemenubar() {
      this.$root.$emit("bv::toggle::collapse", "menubar");
      if (this.togglename != "") {
        this.$root.$emit("bv::toggle::collapse", `${this.togglename}`);
      }
      if (this.subtogglename != "") {
        this.$root.$emit("bv::toggle::collapse", `${this.subtogglename}`);
      }

      this.togglename = "";
      this.subtogglename = "";
    },

    async logout() {
      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        `คุณต้องการออกจากระบบ/ Sing Out?`
      );

      if (confirm) {
        await this.$store.dispatch("loginActions/logoutaction");
        window.location.href = this.$cons.API_LOGOUT_URL;
      }
    },
  },
};
</script>

<style scoped>
.stylenavbar {
  border-bottom: 0.5px solid #dadcdfe8;
}
.headercolor {
  background-color: #ffffff;
}
.mainimg {
  padding: 0px;
}
.mainimg img {
  margin-left: 10px;
  height: 35px;
  /* border-radius: 2px; */
  /* background-color: rgb(255, 255, 255); */
}
</style>
