// import AuthService from '../services/auth.service';
import * as main from '../../services/mainservice';
import * as constant from '../../services/constant';
import * as serviceAPI from "../../services/APIservice";

import store from '@/store/index'
export const serviceActions = {
    namespaced: true,
    state: {
        bulist: [],
        bu: null
    },
    mutations: {
        mut_bulist(state, value) {
            state.bulist = value;
        },
        mut_bu(state, value) {
            state.bu = value;
        }

    },
    getters: {
        get_bulist(state) {
            return state.bulist;
        },
        get_bu(state) {
            return state.bu;
        },



    },

    actions: {

        setbu({ commit }, value) {
            // console.log(value);
            // let data = {}
            // if (value == null) { data = null }
            // else {
            //     data = {
            //         bu: value,
            //         keybu: value.substr(0, 1).toUpperCase()
            //     }
            // }


            commit('mut_bu', value)

            let listbu = []

            if (value == 'BNB') {
                listbu = [{ keybu: 'B', bu: 'BNB' }, { keybu: 'V', bu: 'VFIX' }]
            }
            else if (value == 'TWD') {
                listbu = [{ keybu: 'T', bu: 'TWD' }, { keybu: 'V', bu: 'VFIX' }]
            } else if (value == 'VFIX') {
                listbu = store.getters['serviceActions/get_bulist']
            }
            else {
                listbu = []

            }

            store.dispatch('serviceActions/setbulist', listbu)
        },
        setbulist({ commit }, value) {

            commit('mut_bulist', value)


        },


    },
};