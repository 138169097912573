<template>
  <div class="container-fluid m-0 p-0 w-100">
    <div class="row m-0 p-3  w-100  justify-content-center">
      <div class="col-12 col-xl-8 my-3 shadow p-3 mb-3 bg-white rounded">
        <p class="font-1-2s font-600">Select business for manage</p>
        <b-table
          :empty-text="'ไม่พบข้อมูล / No Data'"
          :fields="fields"
          :items="items"
          outlined
          hover
          show-empty
          responsive
          class="font-0-9s"
        >
          <template #cell(name)="row">
            <div class="d-flex flex-row align-items-center">
              <img class="box-img-bu" :src="row.item.img" alt="" />
              <p class="my-0 p-0 font-600">{{ row.item.name }}</p>
            </div>
          </template>
          <template #cell(action)="row">
            <button class="btn btn-sm bt-main" :disabled="row.item.key==='AT1'" @click="select(row.item)">
              Select
            </button>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import logoat1 from "../assets/logoat1.jpg";
import twdlogo1 from "../assets/twdlogo1.png";
export default {
  name: "SelectBu",
  data() {
    return {
      fields: [
        { key: "name", label: "Business name " },
        {
          key: "action",
          label: " ",
          class: "text-right ",
          tdClass: "align-middle",
        },
      ],
      items: [
        { name: "Thaiwatsadu", img: twdlogo1, key: "TWD" },
         { name: "Auto1", img: logoat1, key: "AT1" },
      ],
    };
  },
  mounted() {},
  methods: {
    select(e) {
      let { key } = e;
      let menu = [];
      if (key === "TWD") {
        menu = this.$nav_menu;
      } else if (key === "AT1") {
        menu = this.$nav_menuAT1;
      }
      this.$store.dispatch("loginActions/setBu", {val:key,menu});
      this.checkBu();
    },
    async checkBu() {
      let bu = this.$store.getters["loginActions/GET_BU"];
      if (!!bu) {
        this.$router.push("/");
      }
      this.changeFavicon(bu);
    },
    changeFavicon(bu) {
      let favicon = document.querySelector('link[rel="icon"]');

      // if (!favicon) {
      //   favicon = document.createElement("link");
      //   favicon.setAttribute("rel", "icon");
      //   favicon.setAttribute("type", "image/png");
      //   document.head.appendChild(favicon);
      // }

      // let url = "/twd.ico";
      // if (bu === "TWD") {
      //   url = "/twd.ico";
      // } else if (bu === "AT1") {
      //   url = "/at1.ico";
      // }
      // favicon.setAttribute("href", url);
    },
  },
};
</script>

<style></style>
