<template>
  <b-modal id="ModalWaitApprove" class="m-0 p-0" hide-header hide-footer scrollable size="lg">
    <!-- {{datalist[0].remarkRejectCW}} -->
    <div class="row m-0 p-0">
      <div v-if="datalist === null" class="col-12">
        <p class="text-center font-0-7s text-muted font-600">
          ----- ไม่มีข้อมูล/No Data -----
        </p>
      </div>

      <div v-else class="col-12 my-3 font-0-8s">
        <div class="row mx-0 mb-2
         " v-for="(e, i) in datalist" :key="i">
          <div class="col-10">
            {{ `No.${i + 1} : ${e.skCode}` }} : {{ e.prNameTH }}
            <div class="mt-2 text-twd" v-if="content == 'RCW'">
              <b-collapse :id="`collapse-${i}`">
                <b-card>
                  <div v-for="(value, i) in String(e.RejectCW).split(',')" :key="i">
                    <span>&#8226;</span> {{ value }}
                  </div>
                  <div v-for="(value2, k) in String(e.Record).split(',')" :key="k">
                    <span>&#8226;</span> {{ value2 }}
                  </div>
                </b-card>
              </b-collapse>
            </div>
            <div class="mt-2 text-twd" v-if="content == 'RGD'">
              <b-collapse :id="`collapse-${i}`">
                <b-card>
                  <div v-for="(value, i) in String(e.RejectGD).split(',')" :key="i">
                    <span>&#8226;</span> {{ value }}
                  </div>
                  <div v-for="(value2, k) in String(e.Record).split(',')" :key="k">
                    <span>&#8226;</span> {{ value2 }}
                  </div>
                </b-card>
              </b-collapse>
            </div>
          </div>
          <div class="col-2">
            <div v-if="content === 'RCW' || content === 'RGD'">
              <p @click="toggleCollapses([`collapse-${i}`])" type="button" title="คลิกเพื่อดูรายละเอียดการปฏิเสธ"
                class="h6 mb-2 text-twd">ดูหมายเหตุ<b-icon animation="throb" icon=" exclamation-circle-fill"
                  variant="danger"></b-icon></p>
            </div>
            <b-button class="btn btn-sm bt-main" v-b-toggle.collapse-2 size="sm"
              @click="edit(e.skCode)">รายละเอียด</b-button>
          </div>
          <div class="col-12">
            <hr />
          </div>
        </div>
      </div>
      <div class="col-12 text-right">
        <button type="button" class="btn  bt mx-1" @click="hide">ยกเลิก</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalWaitApprove",
  props: ["content"],
  data() {
    return {
      datalist: null,
    };
  },
  mounted() {
    this.getChartWaitApprovalAction();
  },
  methods: {
    toggleCollapses(ids) {
      ids.forEach((id) => {
        this.$root.$emit('bv::toggle::collapse', id)
      })
    },
    edit(e) {
      this.$router.push({
        name: "Enrich Product Detail",
        params: { skcode: e },
      });
    },
    async getChartWaitApprovalAction() {
      try {
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `dartboard/getChartWaitApprovalAction`,
          { content: this.content },
          1
        );

        this.datalist = getAPI.data;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    redata() {
      this.datalist = [];
    },

    hide() {
      this.redata();
      this.$bvModal.hide("ModalWaitApprove");
    },
  },
};
</script>

<style></style>
