<template>
  <div class="container-fluid">
    <div class="row mt-3 p-3 justify-content-center">
      <div class="col-12 col-lg-8 px-5">
        <div class="row m-0 align-items-center">
          <div class="col-12 col-lg-6 m-0">
            <p class="text-main font-600 font-2s">ยินดีต้อนรับ</p>
            <p class="font-1-2s">
              คุณ {{ $store.getters["loginActions/GET_USER"].firstname }}
              {{ $store.getters["loginActions/GET_USER"].lastname }}
            </p>
            <p>
              สู่ระบบการจัดการข้อมูลผลิตภัณฑ์
              <span class="font-1-2s text-main font-600">Power Buy</span>
            </p>
          </div>
          <div class="col-6 d-none d-lg-block m-0">
            <!-- <ApexCharts :option_chart="option_chart" :key="rechart + 30" /> -->
          </div>
        </div>

        <div class="row m-0 p-2 w-100">
          <div class="col-6 col-xl-4 w-100 p-2 mb-1">
            <div class="shadow-sm chart_horver p-2 bg-white rounded h-100">
              <b-overlay
                :show="load"
                :spinner-variant="
                  `main-${$store.getters['loginActions/GET_BU']}`
                "
                spinner-type="grow"
                spinner-small
              >
                <div class="row m-0 p-0">
                  <div
                    class="col-6 m-0 p-1 h-100"
                    type="button"
                    @click="callModaidartboardEnrich('')"
                  >
                    <p class="font-0-7s text-muted font-600 m-0">
                      New Products
                    </p>
                    <p class="font-600 text-muted m-0">
                      {{ new Intl.NumberFormat().format(numchart.dataNew) }}
                      <span class="font-0-6s">Month</span>
                    </p>
                    <p class="text-main font-600">
                      {{
                        new Intl.NumberFormat().format(numchart.total_dataNew)
                      }}
                      <span class="font-0-7s">Total SKU</span>
                    </p>
                  </div>
                  <div class="col-6 m-0 p-1 h-100">
                    <ChartJs
                      :data="chart.dataNew"
                      :option="chart.option"
                      type="bar"
                      :key="rechart"
                    />
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
          <!-- -------------------------------------- -->
          <div class="col-6 col-xl-4 w-100 p-2 mb-1">
            <div class="shadow-sm chart_horver p-2 bg-white rounded h-100">
              <b-overlay
                :show="load"
                :spinner-variant="
                  `main-${$store.getters['loginActions/GET_BU']}`
                "
                spinner-type="grow"
                spinner-small
              >
                <div class="row m-0 p-0">
                  <div
                    class="col-6 m-0 p-1 h-100"
                    type="button"
                    @click="callModaidartboardEnrich('I')"
                  >
                    <p class="font-0-7s text-muted font-600 m-0">Follow Data</p>
                    <p class="font-600 text-muted m-0">
                      {{ new Intl.NumberFormat().format(numchart.dataFollow) }}
                      <span class="font-0-6s">Month</span>
                    </p>
                    <p class="text-main font-600">
                      {{
                        new Intl.NumberFormat().format(
                          numchart.total_dataFollow
                        )
                      }}
                      <span class="font-0-7s">Total SKU</span>
                    </p>
                  </div>
                  <div class="col-6 m-0 p-1 h-100">
                    <ChartJs
                      :data="chart.dataFollow"
                      :option="chart.option"
                      type="bar"
                      :key="rechart + 5"
                    />
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
          <!-- -------------------------------------- -->
          <div class="col-6 col-xl-4 w-100 p-2 mb-1">
            <div class="shadow-sm chart_horver p-2 bg-white rounded h-100">
              <b-overlay
                :show="load"
                :spinner-variant="
                  `main-${$store.getters['loginActions/GET_BU']}`
                "
                spinner-type="grow"
                spinner-small
              >
                <div class="row m-0 p-0">
                  <div
                    class="col-6 m-0 p-1 h-100"
                    type="button"
                    @click="callModaidartboardEnrich('Y')"
                  >
                    <p class="font-0-7s text-muted font-600 m-0">
                      Write Content
                    </p>
                    <p class="font-600 text-muted m-0">
                      {{ new Intl.NumberFormat().format(numchart.dataWrite) }}
                      <span class="font-0-6s">Month</span>
                    </p>
                    <p class="text-main font-600">
                      {{
                        new Intl.NumberFormat().format(numchart.total_dataWrite)
                      }}
                      <span class="font-0-7s">Total SKU</span>
                    </p>
                  </div>
                  <div class="col-6 m-0 p-1 h-100">
                    <ChartJs
                      :data="chart.dataWrite"
                      :option="chart.option"
                      type="bar"
                      :key="rechart + 10"
                    />
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
          <!-- -------------------------------------- -->
          <div class="col-6 col-xl-4 w-100 p-2 mb-1">
            <div class="shadow-sm chart_horver p-2 bg-white rounded h-100">
              <b-overlay
                :show="load"
                :spinner-variant="
                  `main-${$store.getters['loginActions/GET_BU']}`
                "
                spinner-type="grow"
                spinner-small
              >
                <div class="row m-0 p-0">
                  <div
                    class="col-6 m-0 p-1 h-100"
                    type="button"
                    @click="callModaidartboardEnrich('P')"
                  >
                    <p class="font-0-7s text-muted font-600 m-0">
                      Wait Approve
                    </p>
                    <p class="font-600 text-muted m-0">
                      {{ new Intl.NumberFormat().format(numchart.dataApprove) }}
                      <span class="font-0-6s">Month</span>
                    </p>
                    <p class="text-main font-600">
                      {{
                        new Intl.NumberFormat().format(
                          numchart.total_dataApprove
                        )
                      }}
                      <span class="font-0-7s">Total SKU</span>
                    </p>
                  </div>
                  <div class="col-6 m-0 p-1 h-100">
                    <ChartJs
                      :data="chart.dataApprove"
                      :option="chart.option"
                      type="bar"
                      :key="rechart + 15"
                    />
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
          <!-- ----------------------------------- -->
          <div class="col-6 col-xl-4 w-100 p-2 mb-1">
            <div
              class="shadow-sm chart_horver p-2 bg-white rounded h-100"
              @click="$router.push({ name: 'ManageVendor' })"
            >
              <b-overlay
                :show="load"
                :spinner-variant="
                  `main-${$store.getters['loginActions/GET_BU']}`
                "
                spinner-type="grow"
                spinner-small
              >
                <div class="row m-0 p-0">
                  <div class="col-6 m-0 p-1 h-100" type="button">
                    <p class="font-0-7s text-muted font-600 m-0">
                      Vendor Not Added Yet
                    </p>
                    <p class="font-600 text-muted m-0">
                      {{
                        new Intl.NumberFormat().format(numchart.notAddVendor)
                      }}
                    </p>
                    <p class="text-main font-600 font-0-7s">Vendor</p>
                  </div>
                  <div class="col-6 m-0 p-1 h-100">
                    <ChartJs
                      :data="chartdoughnut"
                      :option="optionsdoughnut"
                      type="doughnut"
                      :key="rechart + 20"
                    />
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
          <!-- ----------------------------------- -->
          <div class="col-6 col-xl-4 w-100 p-2 mb-1">
            <div
              class="shadow-sm chart_horver p-2 bg-white rounded h-100"
              @click="
                $router.push({
                  name: 'ManageUser',
                  query: { mode: 'permission', find: 'no permission' },
                })
              "
            >
              <b-overlay
                :show="load"
                :spinner-variant="
                  `main-${$store.getters['loginActions/GET_BU']}`
                "
                spinner-type="grow"
                spinner-small
              >
                <div class="row m-0 p-0">
                  <div class="col-6 m-0 p-1 h-100" type="button">
                    <p class="font-0-7s text-muted font-600 m-0">
                      Not Assign Permission
                    </p>
                    <p class="font-600 text-muted m-0">
                      {{
                        new Intl.NumberFormat().format(
                          numchart.count_no_permission
                        )
                      }}
                    </p>
                    <p class="text-main font-600 font-0-7s">User</p>
                  </div>
                  <div class="col-6 m-0 p-1 h-100">
                    <ChartJs
                      :data="chartdoughntu_nopermiss"
                      :option="optionsdoughnut"
                      type="doughnut"
                      :key="rechart + 20"
                    />
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
          <!-- ----------------------------------- -->
          <div class="col-6 col-xl-4 w-100 p-2 mb-1">
            <div
              class="shadow-sm chart_horver p-2 bg-white rounded h-100"
              @click="callModalWaitApprove('CW')"
            >
              <b-overlay
                :show="load"
                :spinner-variant="
                  `main-${$store.getters['loginActions/GET_BU']}`
                "
                spinner-type="grow"
                spinner-small
              >
                <div class="row m-0 p-0">
                  <div class="col-6 m-0 p-1 h-100" type="button">
                    <p class="font-0-7s text-muted font-600 m-0">
                      Wait CW Approve
                    </p>
                    <p class="font-600 text-muted m-0">
                      {{ new Intl.NumberFormat().format(numchart.appcw) }}
                    </p>
                    <p class="text-main font-600 font-0-7s">SKU</p>
                  </div>
                  <div class="col-6 m-0 p-1 h-100">
                    <ChartJs
                      :data="chartdoughnut_appcw"
                      :option="optionsdoughnut"
                      type="doughnut"
                      :key="rechart + 30"
                    />
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
          <!-- ----------------------------------- -->
          <div class="col-6 col-xl-4 w-100 p-2 mb-1">
            <div
              class="shadow-sm chart_horver p-2 bg-white rounded h-100"
              @click="callModalWaitApprove('GD')"
            >
              <b-overlay
                :show="load"
                :spinner-variant="
                  `main-${$store.getters['loginActions/GET_BU']}`
                "
                spinner-type="grow"
                spinner-small
              >
                <div class="row m-0 p-0">
                  <div class="col-6 m-0 p-1 h-100" type="button">
                    <p class="font-0-7s text-muted font-600 m-0">
                      Wait GD Approve
                    </p>
                    <p class="font-600 text-muted m-0">
                      {{ new Intl.NumberFormat().format(numchart.appgd) }}
                    </p>
                    <p class="text-main font-600 font-0-7s">SKU</p>
                  </div>
                  <div class="col-6 m-0 p-1 h-100">
                    <ChartJs
                      :data="chartdoughntu_appgd"
                      :option="optionsdoughnut"
                      type="doughnut"
                      :key="rechart + 40"
                    />
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
          <!-- ----------------------------------- -->
          <!-- <div class="col-6 col-xl-4 w-100 p-2 mb-1">
            <div
              class="shadow-sm chart_horver p-2 bg-white rounded h-100"
              @click="callModalWaitApprove('R')"
            >
              <b-overlay
                :show="load"
                :spinner-variant="`main-${$store.getters['loginActions/GET_BU']}`"
                spinner-type="grow"
                spinner-small
              >
                <div class="row m-0 p-0">
                  <div class="col-6 m-0 p-1 h-100" type="button">
                    <p class="font-0-7s text-muted font-600 m-0">Reject</p>
                    <p class="font-600 text-muted m-0">
                      {{ new Intl.NumberFormat().format(numchart.reject) }}
                    </p>
                    <p class="text-main font-600 font-0-7s">SKU</p>
                  </div>
                  <div class="col-6 m-0 p-1 h-100">
                    <ChartJs
                      :data="chartdoughnut_reject"
                      :option="optionsdoughnut"
                      type="doughnut"
                      :key="rechart + 40"
                    />
                  </div>
                </div>
              </b-overlay>
            </div>
          </div> -->

          <div class="col-6 col-xl-4 w-100 p-2 mb-1">
            <div class="shadow-sm chart_horver p-2 bg-white rounded h-100">
              <b-overlay
                :show="load"
                :spinner-variant="
                  `main-${$store.getters['loginActions/GET_BU']}`
                "
                spinner-type="grow"
                spinner-small
              >
                <div class="row m-0 p-0">
                  <div
                    class="col-3 m-0 p-1 h-100 "
                    data-toggle="tooltip"
                    data-placement="top"
                    title="ดูรายละเอียดสินค้า Reject CW"
                    type="button"
                    @click="callModalWaitApprove('RCW')"
                  >
                    <p class="font-0-7s text-muted font-600 m-0">Reject CW</p>
                    <p class="font-600 text-muted m-0">
                      {{ new Intl.NumberFormat().format(count_reject_cw) }}
                    </p>
                    <p class="text-main font-600 font-0-7s">SKU</p>
                  </div>
                  <div
                    class="col-3 m-0 p-1 h-100"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="ดูรายละเอียดสินค้า Reject GD"
                    type="button"
                    @click="callModalWaitApprove('RGD')"
                  >
                    <p class="font-0-7s text-muted font-600 m-0">Reject GD</p>
                    <p class="font-600 text-muted m-0">
                      {{ new Intl.NumberFormat().format(count_reject_gd) }}
                    </p>
                    <p class="text-main font-600 font-0-7s">SKU</p>
                  </div>
                  <div class="col-6 m-0 p-1 h-100">
                    <DonutChart
                      :data="donut_reject"
                      type="doughnut"
                      :chart-key="rechart + 40"
                    />
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>

          <!-- ------------------------------------->
        </div>
      </div>
      <div class="col-12 col-lg-4 mt-4">
        <HomeSvg class="color-team" />
        <!-- <img src="@/assets/hi.png" alt="" class="img-fluid" /> -->
        <!-- <img src="@/assets/Beyond Character_BNB-03.png" class="right" alt="" /> -->
        <!-- <carousel
          autoplay
          loop
          :autoplayTimeout="carousel.autoplayTimeout"
          :perPage="carousel.perPage"
          :paginationEnabled="carousel.paginationEnabled"
        >
          <slide v-for="(e, i) in img" :key="i">
            <img :src="e.img" alt="" />
          </slide>
        </carousel> -->
      </div>
    </div>
    <ModaidartboardEnrich :key="reModaidartboardEnrich" :enrich="enrich" />
    <ModalWaitApprove :key="reModalWaitApprove" :content="content" />
  </div>
</template>

<script>
import HomeSvg from "@/components/svg/HomeSvg";
import ChartJs from "@/components/common/ChartJs.vue";
import ApexCharts from "@/components/common/ApexCharts .vue";
import ModaidartboardEnrich from "@/components/Home/ModaidartboardEnrich.vue";
import ModalWaitApprove from "@/components/Home/ModalWaitApprove.vue";
import DonutChart from "@/components/common/DonutChart.vue";
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "Home",
  components: {
    Carousel,
    Slide,
    ChartJs,
    ModaidartboardEnrich,
    ModalWaitApprove,
    ApexCharts,
    DonutChart,
    HomeSvg,
  },
  data() {
    return {
      donut_reject: [],
      load: true,
      rechart: Date.now(),
      reModaidartboardEnrich: Date.now(),
      reModalWaitApprove: Date.now() + 10,
      enrich: "",
      content: "",
      count_reject_cw: 0,
      count_reject_gd: 0,
      numchart: {
        dataFollow: 0,
        dataWrite: 0,
        dataApprove: 0,
        dataNew: 0,
        total_dataFollow: 0,
        total_dataWrite: 0,
        total_dataApprove: 0,
        total_dataNew: 0,
        notAddVendor: 0,
        count_no_permission: 0,
        appcw: 0,
        appgd: 0,
        reject: 0,
      },
      option_chart: {
        chart: {
          height: 300,
          type: "radialBar",
        },
        series: [0, 0, 0, 0],
        fill: {
          colors: [
            "rgb(255, 159, 64)",
            "rgb(255, 205, 86)",
            "rgb(75, 192, 192)",
            "rgba(114, 124, 245, 0.85)",
          ],
        },

        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "0.8em",
              },
              value: {
                fontSize: "0.7em",
                formatter: function(val, w) {
                  // console.log(val);
                  // console.log(w.config.series);
                  let arr_data = w.globals.series;
                  let arr_dataPercent = w.globals.seriesPercent;
                  let index = arr_data.findIndex((e) => e == val);
                  // console.log(arr_data);
                  // console.log(index);

                  return `(${val})${parseFloat(
                    arr_dataPercent[index][0]
                  ).toFixed(2)}%`;
                },
              },
              total: {
                fontSize: "0.8em",
                show: true,
                label: "TOTAL",
                formatter: function(w) {
                  // console.log(w);
                  let total = w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);

                  let totalPercent = w.globals.seriesPercent.reduce((a, b) => {
                    // console.log(a);
                    // console.log(b);
                    return a + b[0];
                  }, 0);
                  return `(${total}) ${new Intl.NumberFormat("th", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(totalPercent)} %`;
                },
              },
            },
          },
        },
        colors: [
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgba(114, 124, 245, 0.85)",
        ],
        labels: [
          "New Products",
          "Follow Data",
          "Write Content",
          "Wait Approve",
        ],
      },
      chartdoughnut: {
        labels: [""],
        datasets: [
          {
            label: "",
            data: [0],
            backgroundColor: ["rgb(75, 192, 192)"],
            hoverOffset: 4,
          },
        ],
      },
      chartdoughntu_nopermiss: {
        labels: [""],
        datasets: [
          {
            label: "",
            data: [0],
            backgroundColor: ["rgba(114, 124, 245, 0.85)"],
            hoverOffset: 4,
          },
        ],
      },
      chartdoughnut_appcw: {
        labels: [""],
        datasets: [
          {
            label: "",
            data: [0],
            backgroundColor: ["rgb(75, 192, 192)"],
            hoverOffset: 4,
          },
        ],
      },
      chartdoughnut_reject: {
        labels: [""],
        datasets: [
          {
            label: "",
            data: [0],
            backgroundColor: ["rgb(75, 192, 192)"],
            hoverOffset: 4,
          },
        ],
      },
      chartdoughntu_appgd: {
        labels: [""],
        datasets: [
          {
            label: "",
            data: [0],
            backgroundColor: ["rgba(114, 124, 245, 0.85)"],
            hoverOffset: 4,
          },
        ],
      },
      optionsdoughnut: {
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, value) {
              return value.datasets[0].data[0];
            },
          },
        },
      },

      chart: {
        dataNew: {
          labels: ["", "", "", "", "", "", "", ""],
          datasets: [
            {
              data: [9, 9, 9, 9, 9, 9, 9, 9],
              minBarLength: 1,
              backgroundColor: [
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
              ],
              borderColor: [
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
              ],
              borderWidth: 1,
              borderRadius: 20,
              borderSkipped: false,
            },
          ],
        },
        dataFollow: {
          labels: ["", "", "", "", "", "", "", ""],
          datasets: [
            {
              data: [9, 9, 9, 9, 9, 9, 9, 9],
              minBarLength: 1,
              backgroundColor: [
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
              ],
              borderColor: [
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
              ],
              borderWidth: 1,
              borderRadius: 20,
              borderSkipped: false,
            },
          ],
        },
        dataWrite: {
          labels: ["", "", "", "", "", "", "", ""],
          datasets: [
            {
              data: [9, 9, 9, 9, 9, 9, 9, 9],
              minBarLength: 1,
              backgroundColor: [
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
              ],
              borderColor: [
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
                "rgb(75, 192, 192)",
              ],
              borderWidth: 1,
              borderRadius: 20,
              borderSkipped: false,
            },
          ],
        },
        dataApprove: {
          labels: ["", "", "", "", "", "", "", ""],
          datasets: [
            {
              data: [9, 9, 9, 9, 9, 9, 9, 9],
              minBarLength: 1,
              backgroundColor: [
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
              ],
              borderColor: [
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
                "rgba(114, 124, 245, 0.85)",
              ],
              borderWidth: 1,
              borderRadius: 20,
              borderSkipped: false,
            },
          ],
        },
        option: {
          responsive: true,
          tooltips: {
            callbacks: {
              title: function(tooltipItem, data) {
                return "";
              },
              label: (item) => `${item.xLabel} : ${item.yLabel}`,
            },
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,

                  callback: function(value, index, values) {
                    return value;
                  },
                },
                display: false,
              },
            ],
            xAxes: [
              {
                display: false,
              },
            ],
          },
        },
      },

      carousel: { autoplayTimeout: 6000, perPage: 1, paginationEnabled: false },

      img: [
        { img: require("@/assets/Beyond Character_BNB-02.png") },
        { img: require("@/assets/ok.png") },

        { img: require("@/assets/Beyond Character_BNB-03.png") },
        { img: require("@/assets/love.png") },

        { img: require("@/assets/Beyond Character_BNB-04.png") },
        { img: require("@/assets/please.png") },

        { img: require("@/assets/Beyond Character_BNB-05.png") },
        { img: require("@/assets/hi.png") },

        { img: require("@/assets/Beyond Character_BNB-06.png") },
        { img: require("@/assets/happy shopping.png") },
      ],
    };
  },
  watch: {
    check_role: function(v) {
      this.get_role();
    },
  },
  computed: {
    check_role: function() {
      return this.$store.getters["loginActions/GET_USER"].role;
    },
  },
  // mounted() {
  //   setTimeout(() => {
  //     this.get_role();
  //     this.get_home_chart_bar();
  //     this.getChartWaitApproval();
  //   }, 1000);
  // },
  async mounted() {
    setTimeout(async () => {
      await this.get_role();
      await this.get_home_chart_bar();
      await this.getChartWaitApproval();
      this.load = false;
    }, 1000);
  },
  methods: {
    get_role() {
      if (
        this.$store.getters["loginActions/GET_USER"].role !== undefined &&
        this.$store.getters["loginActions/GET_USER"].role.length == 0
      ) {
        this.$serviceMain.showErrorAlert(
          this,
          `กรุณาติดต่อผู้ดูเเล้วระบบเพื่อขอสิทธ์การใช้งาน / Please contact the administrator for access`
        );
      }
    },
    callModaidartboardEnrich(data) {
      this.enrich = data;
      this.reModaidartboardEnrich = Date.now();

      setTimeout(() => {
        this.$bvModal.show(`ModaidartboardEnrich`);
      }, 100);
    },
    callModalWaitApprove(data) {
      this.content = data;

      this.reModalWaitApprove = Date.now();

      setTimeout(() => {
        this.$bvModal.show(`ModalWaitApprove`);
      }, 100);
    },
    async getChartWaitApproval() {
      try {
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `dartboard/getChartWaitApproval`,
          {},
          1
        );

        let { waitcw, waitgd, reject, reject_cw, reject_gd } = getAPI.data[0];
        this.numchart.appcw = waitcw;
        this.numchart.appgd = waitgd;
        this.numchart.reject = reject;
        this.count_reject_cw = reject_cw;
        this.count_reject_gd = reject_gd;

        this.chartdoughnut_appcw.datasets[0].data[0] = waitcw;
        this.chartdoughntu_appgd.datasets[0].data[0] = waitgd;
        this.chartdoughnut_reject.datasets[0].data[0] = reject;
        this.donut_reject = [
          { label: "CW", value: reject_cw },
          { label: "GD", value: reject_gd },
        ];
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async get_home_chart_bar() {
      try {
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `dartboard/get_home_chart_bar`,
          {},
          1
        );
        // console.log(`api done : ${new Date()}`);
        let _follow_data = [];
        let _new_product = [];
        let _wait_approve = [];
        let _write_content = [];
        let _total_dataFollow = 0;
        let _total_dataWrite = 0;
        let _total_dataApprove = 0;
        let _total_dataNew = 0;
        let name = [];

        for await (var d of getAPI.data) {
          let {
            follow_data,
            new_product,
            wait_approve,
            write_content,
            Month_Name,
            count_reject,
          } = d;
          _follow_data.push(follow_data);
          _new_product.push(new_product);
          _wait_approve.push(wait_approve);
          _write_content.push(write_content);

          name.push(Month_Name);

          _total_dataFollow = _total_dataFollow + follow_data;
          _total_dataWrite = _total_dataWrite + write_content;
          _total_dataApprove = _total_dataApprove + wait_approve;
          _total_dataNew = _total_dataNew + new_product;
        }
        // console.log(_total_reject_cw + "," + _total_reject_gd);

        this.numchart = {
          dataFollow: _follow_data[_follow_data.length - 1],
          dataWrite: _write_content[_write_content.length - 1],
          dataApprove: _wait_approve[_wait_approve.length - 1],
          dataNew: _new_product[_new_product.length - 1],
          notAddVendor: getAPI.data[0].count_vd,
          count_no_permission: getAPI.data[0].count_no_permission,
          appcw: this.numchart.appcw,
          appgd: this.numchart.appgd,
          reject: this.numchart.reject,
        };

        this.chart.dataNew.datasets[0].data = _new_product;
        this.chart.dataFollow.datasets[0].data = _follow_data;
        this.chart.dataWrite.datasets[0].data = _write_content;
        this.chart.dataApprove.datasets[0].data = _wait_approve;

        this.chartdoughnut.datasets[0].data[0] = getAPI.data[0].count_vd;
        this.chartdoughntu_nopermiss.datasets[0].data[0] =
          getAPI.data[0].count_no_permission;

        this.chart.dataNew.labels = name;
        this.chart.dataFollow.labels = name;
        this.chart.dataWrite.labels = name;
        this.chart.dataApprove.labels = name;

        this.numchart.total_dataFollow = _total_dataFollow;
        this.numchart.total_dataWrite = _total_dataWrite;
        this.numchart.total_dataApprove = _total_dataApprove;
        this.numchart.total_dataNew = _total_dataNew;

        this.option_chart.series[0] = _total_dataNew;
        this.option_chart.series[1] = _total_dataFollow;
        this.option_chart.series[2] = _total_dataWrite;
        this.option_chart.series[3] = _total_dataApprove;

        this.load = false;
        //  console.log(`set Date done : ${new Date()}`);
        this.rechart = Date.now();
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style scoped>
img {
  width: auto;
  height: 50vh;
}
.left {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-50%, -40%);
}
.right {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
