import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueDragTree from 'vue-drag-tree'
import 'vue-drag-tree/dist/vue-drag-tree.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/my.scss'
import 'vue-cool-select/dist/themes/bootstrap.css'

import * as  serviceAPI from "./services/APIservice";
import * as  serviceMain from "./services/mainservice";
import * as constant from './services/constant';
import { nav_menu } from './services/dropdown';
import { nav_menuAT1 } from './services/dropdownAT1';
import { svg_icon } from './services/svg_icon';
import { roleaction } from './services/roleaction'
import { CoolSelectPlugin } from "vue-cool-select";
import { search } from './services/search'
import axios from 'axios';
import dateFormat from "dateformat";
import VueClipboard from 'vue-clipboard2'
import VueCarousel from 'vue-carousel';
import VueThreeSixty from 'vue-360'
import 'vue-360/dist/css/style.css'
import fileDownload from "js-file-download";
import "vue-multiselect/dist/vue-multiselect.min.css"



Vue.prototype.$serviceAPI = serviceAPI
Vue.prototype.$serviceMain = serviceMain
Vue.prototype.$cons = constant
Vue.prototype.$nav_menu = nav_menu
Vue.prototype.$nav_menuAT1 = nav_menuAT1
Vue.prototype.$svg_icon = svg_icon
Vue.prototype.$roleaction = roleaction
Vue.prototype.$axios = axios
Vue.prototype.$dateFormat = dateFormat
Vue.prototype.$fileDownload = fileDownload
Vue.prototype.$search = search

import Multiselect from 'vue-multiselect'

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

// register globally
Vue.component('multiselect', Multiselect)
Vue.component('VueJsonPretty', VueJsonPretty)

Vue.use(VueDragTree)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(VueCarousel);
Vue.use(VueThreeSixty)
Vue.use(CoolSelectPlugin);


import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
  confirmButtonColor: '#3866af',
  cancelButtonColor: '#ff7674',
};
Vue.use(VueSweetalert2, options)

import VCalendar from 'v-calendar';
Vue.use(VCalendar)



Vue.config.productionTip = false




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
