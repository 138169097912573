import * as main from '../../services/mainservice';
import * as constant from '../../services/constant';
import * as serviceAPI from "../../services/APIservice";

let initcategory = {
    lv1: { catname: '', catcode: '' },
    lv2: { catname: '', catcode: '' },
    lv3: { catname: '', catcode: '' },
    lv4: { catname: '', catcode: '' },
    catname: '',
    catcode: '',
    lvl: 1,
    selectnow: '',
}

let initcategoryremark = {
    lv1: { catname: '', catcode: '' },
    lv2: { catname: '', catcode: '' },
    lv3: { catname: '', catcode: '' },
    lv4: { catname: '', catcode: '' },
    catname: '',
    catcode: '',
    lvl: 1,
    selectnow: '',
}

let initcategoryweb = {
    lv1: { catname: '', catcode: '' },
    lv2: { catname: '', catcode: '' },
    lv3: { catname: '', catcode: '' },
    lv4: { catname: '', catcode: '' },
    catname: '',
    catcode: '',
    lvl: 1,
    selectnow: '',
}

export const categoryActions = {
    namespaced: true,
    state: {
        category: initcategory,
        categoryremark: initcategoryremark,
        initcategoryweb: initcategoryweb
    },
    mutations: {
        mut_category(state, value) {
            state.category = value;
        },
        mut_category_remark(state, value) {
            state.categoryremark = value;
        },
        mut_category_web(state, value) {
            state.initcategoryweb = value;
        },
    },
    getters: {
        get_category(state) {
            return state.category;
        },
        get_category_remark(state) {
            return state.categoryremark;
        },
        get_category_web(state) {
            return state.initcategoryweb;
        }
    },
    actions: {
        async setcategory({ commit }, value) {
            commit('mut_category', value)
        },
        async setcategoryremark({ commit }, value) {
            commit('mut_category_remark', value)
        },
        async resetcategory({ commit }) {
            commit('mut_category', initcategory)
        },
        async resetcategoryremark({ commit }) {
            commit('mut_category_remark', initcategoryremark)
        },
        // -------------web-------------

        async setcategoryweb({ commit }, value) {
            commit('mut_category_web', value)
        },
        async resetcategoryweb({ commit }) {
 
            commit('mut_category_web', initcategoryweb)
        },
    },
};