// import AuthService from '../services/auth.service';
// import * as main from '../../services/mainservice';
import * as constant from '../../services/constant';
import * as serviceAPI from "../../services/APIservice";
import { get_productQR_list } from '../../api/Product'

export const ProductQRActions = {
    namespaced: true,
    state: {
        list: []
    },
    mutations: {
        MUT_list(state, value) {
            state.list = value;
        },

    },
    getters: {
        GET_list(state) {
            return state.list;
        },

    },
    actions: {

        async call_get_productQR_list({ commit }) {
            return new Promise(async (resolve, reject) => {
                try {
                    let data = {};
                    let getAPI = await get_productQR_list()


                    commit('MUT_list', getAPI.data)
                    resolve('OK')

                } catch (error) {
                    commit('MUT_list', [])
                    reject(error)
                }
            })

        },

    },
};