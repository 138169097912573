import * as main from "../../services/mainservice";
import * as constant from "../../services/constant";
import * as serviceAPI from "../../services/APIservice";
import { getpimenrichproduct_qr } from '../../api/Product'
export const enrichproductdetailQRActions = {
  namespaced: true,
  state: {
    data: null,

  },
  mutations: {
    mut_data(state, value) {
      state.data = value;
    },

  },
  getters: {
    get_data(state) {
      return state.data;
    },

  },
  actions: {
    async setnull({ commit }) {
      commit("mut_data", null);
    },

    async getpimenrichproductQR_data({ commit }, value) {
      try {
        commit("mut_data", null);
        let data = {
          mode:'data',
          skCode: value,
        };

        let getAPI = await getpimenrichproduct_qr(data)

        if (getAPI.data.length == 0) {
          throw `Data Not Found Product`;
        }
        const {
          linkForVdo,
          LinkforVDO2,
          images,
          ImageForFeed,
        } = getAPI.data[0];

        getAPI.data[0].linkForVdo_old = linkForVdo;
        getAPI.data[0].LinkforVDO2_old = LinkforVDO2;
        getAPI.data[0].images_old = images;

        getAPI.data[0].fileUploads =
          (await main.chechnull(images)) !== ""
            ? await setfileUploads(images)
            : [];

        let fileUploadsmain = [];
        if ((await main.chechnull(images)) !== "") {
          fileUploadsmain.push(getAPI.data[0].fileUploads[0]);
        }
        getAPI.data[0].fileUploadsmain = fileUploadsmain;

        // // -------------------------- end img ---------------------------------


        // ---------------- delivery_method------------------


        commit("mut_data", getAPI.data[0]);
        this.dispatch("enrichproductdetailQRActions/reSetupImg");

      } catch (error) {
        console.log(error);
        alert(error);
        // commit("mut_data", null);
      }
    },

    async set_data({ commit }, val) {
      try {

        commit("mut_data", val);
      } catch (error) {
        console.log(error);
        alert(error);
      }
    },

    async reSetupImg({ commit }) {
      console.log('reSetupImg');
      let data = this.getters["enrichproductdetailQRActions/get_data"];
      let img_list = []
      let { fileUploads } = data

      for await (let v of fileUploads) {
        let { name, link } = v
        img_list.push({ name, link })
      }
      // console.log(fileUploads);
      let getb64 = await convert_imgbase64url(img_list);
      // console.log(getb64);
      for await (let v of getb64) {
        let { name, img: imgB64, size } = v
        let index = data.fileUploads.findIndex(e => String(e.name).trim() === String(name).trim())
        if (index !== -1) {
          data.fileUploads[index].b64.img = imgB64
          data.fileUploads[index].b64.size = size
        }
      }

      commit("mut_data", data);
    },

  },
};

async function setfileUploads(data) {
  let datatrim;
  let _trim = [];
  let arr = [];
  if (data !== "") {
    datatrim = data.split("|");
    for await (var [i, v] of datatrim.entries()) {
      if (v !== "") {
        let link = "";
        let mylink = `${constant.URL_TWD_IMG}web/${v}`;


        link = mylink;


        let name = v.split("/")[v.split("/").length - 1];
        arr.push({ link: link, name: name });
      }
    }

    for await (var val of arr) {

      let data = {
        file: { file: val.link, name: val.name },
        name: val.name,
        link: val.link,
        b64: { img: '', size: { w: 0, h: 0 } },
        fixed: false,
        index: i,
        save: false,
      };
      _trim.push(data);
    }
  }
  // console.log(datatrim);

  return _trim;
  // for await ( var [] ){}
}

function convert_imgbase64url(file) {
  return new Promise(async (resolve, reject) => {
    try {
      let data = [];
      let getAPI = await serviceAPI.call_API_NO_LOAD(
        "post",
        `imgb64/getimgb64url`,
        { url: file },
        1
      );

      for await (var [i, v] of getAPI.data.entries()) {
        // console.log(v);
        if (v.b64 == '') { data.push({ img: v.b64, size: { h: 0, w: 0 }, name: v.name, link: v.link }); }
        else {
          let size = await calsize(v.b64);

          data.push({ img: v.b64, size: size, name: v.name, link: v.link });
        }

      }

      resolve(data);
    } catch (error) {

      reject(error);
    }
  });
}

async function calsize(imgb64) {
  return new Promise(async (resolve, reject) => {
    var image = new Image();
    var height;
    var width;
    image.src = imgb64;

    image.onload = function () {
      height = this.height;
      width = this.width;

      resolve({ h: height, w: width });
    };
  });
}

async function getvideo(link) {
  return new Promise(async (resolve, reject) => {
    let data = [];
    let url;
    for await (var [i, v] of link.entries()) {
      if (v !== "" && v !== null && v !== "null") {
        url = `https://www.youtube.com/embed/${main.youtube(v)}`;
        // let position = v.search("watch?v");
        // if (position !== -1) {
        //     url = `https://www.youtube.com/embed/${v.split("v=")[1].split("&")[0]}`
        // }
        // else { url = `https://www.youtube.com/embed/${v.split("/")[v.split("/").length - 1]}` }

        data.push({ index: i, link: url, fixed: false });
      }
    }

    resolve(data);
  });
}

async function getremarkdetail(remarkId) {
  return new Promise(async (resolve, reject) => {
    let resolve_data = { explain: "", remarkHtml: "" };

    let condition =
      remarkId !== null && (remarkId !== "") & (remarkId !== "null");

    if (condition) {
      let data = { remarkId: remarkId };
      let getAPI = await serviceAPI.call_API(
        "post",
        `remark/getpimremark`,
        data,
        1
      );

      let getdata = getAPI.data[0];
      resolve_data = {
        explain: getdata.explain,
        remarkHtml: getdata.remarkHtml,
      };
    }
    resolve(resolve_data);
  });
}
