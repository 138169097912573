import * as constant from "../../services/constant";
import * as serviceAPI from "../../services/APIservice";

export const SPUProductActions = {
  namespaced: true,
  state: {
    All: null,
    Data_Normal: null,
    Data_Error: null,
  },
  mutations: {
    MUT_All(state, value) {
      state.All = value;
    },
    MUT_Data_Normal(state, value) {
      state.Data_Normal = value;
    },
    MUT_Data_Error(state, value) {
      state.Data_Error = value;
    },
  },
  getters: {
    GET_All(state) {
      return state.All;
    },
    GET_Data_Normal(state) {
      return state.Data_Normal;
    },
    GET_Data_Error(state) {
      return state.Data_Error;
    },
  },
  actions: {
    async getDataSPU({ commit }) {
      try {
        let data = { mode: "SPU" };
        let getAPI = await serviceAPI.call_API(
          "post",
          `attribute/get_spu_monitor`,
          data,
          1
        );
        var datas = getAPI.data;

        commit("MUT_All", datas);

        // ----- GET_In_Coming-----
        let data_normal = [];
        for await (var m of datas) {
          if (String(m.Error).trim() == "0") {
            data_normal.push(m);
          }
        }
        commit("MUT_Data_Normal", data_normal);

        // ----- GET_In_Progress-----
        let data_error = [];
        for await (var m of datas) {
          if (String(m.Error).trim() == "1") {
            data_error.push(m);
          }
        }
        commit("MUT_Data_Error", data_error);
      } catch (error) {
        commit("MUT_All", null);
        commit("MUT_Data_Normal", null);
        commit("MUT_Data_Error", null);
      }
    },
  },
};
