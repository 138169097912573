import * as serviceAPI from "../../services/APIservice";


export const remarkMessageActions = {
    namespaced: true,
    state: {
        All: null,
        In_Coming: null,
        In_Progress: null,
        Expired: null,
        Deleted: null,
        Error: null,
    },
    mutations: {
        MUT_All(state, value) {
            state.All = value;
        },
        MUT_In_Coming(state, value) {
            state.In_Coming = value;
        },
        MUT_Expired(state, value) {
            state.Expired = value;
        }
        ,
        MUT_In_Progress(state, value) {
            state.In_Progress = value;
        }
        , MUT_Deleted(state, value) {
            state.Deleted = value;
        },
        MUT_Error(state, value) {
            state.Error = value;
        }

    },
    getters: {
        GET_All(state) {
            return state.All;
        },
        GET_In_Progress(state) {
            return state.In_Progress;
        },
        GET_In_Coming(state) {
            return state.In_Coming;
        },
        GET_Expired(state) {
            return state.Expired;
        },
        GET_Deleted(state) {
            return state.Deleted;
        }
        ,
        GET_Error(state) {
            return state.Error;
        }
    },
    actions: {
        async getPimProductRemarkMessage({ commit }) {
            try {
                let data = {remarkId : ''};
                let getAPI = await serviceAPI.call_API("post",
                    `remark/pimproductremarkmessage`, data, 1);
                var datas = getAPI.data

                commit('MUT_All', datas)

                // ----- GET_In_Coming-----
                let data_in_coming = []
                for await (var m of datas) {

                    if (String(m.Status).trim() == 'In Coming') {

                        data_in_coming.push(m)
                    }
                }
                commit('MUT_In_Coming', data_in_coming)

                // ----- GET_In_Progress-----
                let data_in_Progress = []
                for await (var m of datas) {

                    if (String(m.Status).trim() == 'In Progress') {

                        data_in_Progress.push(m)
                    }
                }
                commit('MUT_In_Progress', data_in_Progress)

                // ----- GET_Expired-----
                let data_Expired = []
                for await (var m of datas) {

                    if (String(m.Status).trim() == 'Expired') {

                        data_Expired.push(m)
                    }
                }
                commit('MUT_Expired', data_Expired)

                // ----- GET_Deleted-----
                let data_Deleted = []
                for await (var m of datas) {

                    if (String(m.Status).trim() == 'Deleted') {

                        data_Deleted.push(m)
                    }
                }
                commit('MUT_Deleted', data_Deleted)
                // ----- GET_Error-----
                let data_Error = []
                for await (var m of datas) {

                    if (String(m.Status).trim() == 'Error') { data_Error.push(m) }

                }
                commit('MUT_Error', data_Error)

            } catch (error) {
                commit('MUT_All', null)
                commit('MUT_In_Coming', null)
                commit('MUT_In_Progress', null)
                commit('MUT_Expired', null)
                commit('MUT_Deleted', null)
                commit('MUT_Error', null)
            }
        },

    },
};