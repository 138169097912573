// import AuthService from '../services/auth.service';
import * as main from '../../services/mainservice';
import * as constant from '../../services/constant';
import * as serviceAPI from "../../services/APIservice";


export const loadingActions = {
    namespaced: true,
    state: { laoding: false },
    mutations: {
        load(state, value) {
            state.laoding = true;
        },
        unload(state, value) { state.laoding = false; }

    },
    getters: {
        GET_load(state) {
            return state.laoding;
        },


    },

    actions: {
        onload({ commit }) {

            commit('load' )


        },
        onunload({ commit }) {

            commit('unload' )


        },


    },
};