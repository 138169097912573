<template>
  <b-modal
    id="ModaidartboardEnrich"
    class="m-0 p-0"
    hide-header
    hide-footer
    scrollable
    size="lg"
  >
    <div class="row m-0 p-0">
      <div v-if="datalist.length == 0" class="col-12">
        <p class="text-center font-0-7s text-muted font-600">
          ----- ไม่มีข้อมูล/No Data -----
        </p>
      </div>
      <div v-else class="col-12 my-3 font-0-8s">
        <div
          class="row mx-0 mb-2
         "
          v-for="(e, i) in datalist"
          :key="i"
        >
          <!-- {{ `No. ${i + 1}` }} -->
          <div class="col-3  text-muted font-600">
            {{ `No.${i + 1} :  ${e.skCode}` }}
          </div>
          <div class="col-7  text-muted ">{{ e.prNameTH }}</div>
          <div class="col-2">
            <button
              type="button"
              class="btn btn-sm bt-main"
              @click="edit(e.skCode)"
            >
              รายละเอียด
            </button>
          </div>
          <div class="col-12"><hr /></div>
        </div>
      </div>
      <div class="col-12 text-right">
        <button type="button" class="btn  bt mx-1" @click="hide">ยกเลิก</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModaidartboardEnrich",
  props: ["enrich"],
  data() {
    return {
      datalist: [],
    };
  },
  mounted() {
    this.getHomeChartBarActionSku();
  },
  methods: {
    edit(e) {
      this.$router.push({
        name: "Enrich Product Detail",
        params: { skcode: e },
      });
    },
    async getHomeChartBarActionSku() {
      try {
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `dartboard/getHomeChartBarActionSku`,
          { enrich: this.enrich },
          1
        );

        this.datalist = getAPI.data;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    redata() {
      this.datalist = [];
    },

    hide() {
      this.redata();
      this.$bvModal.hide("ModaidartboardEnrich");
    },
  },
};
</script>

<style></style>
