import * as main from '../../services/mainservice';
import * as constant from '../../services/constant';
import * as serviceAPI from "../../services/APIservice";

let init = {
    page_num: 1,
    page_size: 25,
    onload: false,
    dataproduct: [],
    data: {
        mode: "skucode",
        find: "",
        lvl: 1,
        excel: "N",
        catcode: "",
        showonweb: ["''PWB''"],
        showonwebconvert: "",
        enrich: "",
        newproductnoly: false,
        editbydate: null,
        approvedbydate: null,
        writecontent: false,
    },
    data_qr: {
        mode: "skCode",
        find: "",
        lvl: 1,
        excel: "N",
        catcode: "",
        showonweb: ["''PWB''"],
        showonwebconvert: "",
        enrich: "",
        newproductnoly: false,
        editbydate: null,
        approvedbydate: null,
        writecontent: true,
        RowsOfPage: 25,
        PageNumber: 1,
    }
}

export const enrichproductActions = {
    namespaced: true,
    state:
        init
    ,
    mutations: {
        mut_page_num(state, value) {

            state.page_num = value;
        },
        mut_page_size(state, value) {

            state.page_size = value;
        },
        mut_dataproduct(state, value) {

            state.dataproduct = value;
        },
        mut_onload(state) {
            state.onload = true;
        },
        mut_unload(state) {
            state.onload = false;
        },
        mut_data(state, value) {
            state.data = value;
        },
        mut_data_qr(state, value) {
            state.data_qr = value;
        },

    },
    getters: {
        get_page_num(state) {
            return state.page_num;
        },
        get_page_size(state) {
            return state.page_size;
        },
        get_dataproduct(state) {
            return state.dataproduct;
        },
        get_onload(state) {
            return state.onload;
        }
        ,
        get_data(state) {
            return state.data;
        },
        get_data_qr(state) {
            return state.data_qr;
        }
    },
    actions: {
        setdata({ commit }, value) {
            let getters = this.getters['enrichproductActions/get_data']

            const { mode, find, lvl, excel, catcode, showonweb, showonwebconvert, enrich, newproductnoly, editbydate, approvedbydate, writecontent, RowsOfPage,
                PageNumber

            } = value

            getters.mode = mode;
            getters.find = find;
            getters.lvl = lvl;
            getters.excel = excel;
            getters.catcode = catcode;
            getters.showonweb = showonweb;
            getters.showonwebconvert = showonwebconvert;
            getters.enrich = enrich;
            getters.newproductnoly = newproductnoly;
            getters.editbydate = editbydate;
            getters.approvedbydate = approvedbydate;
            getters.writecontent = writecontent;
            getters.RowsOfPage = RowsOfPage;
            getters.PageNumber = PageNumber
            commit('mut_data', getters)


        },
        setdata_qr({ commit }, value) {
            let getters = this.getters['enrichproductActions/get_data_qr']

            const { mode,
                find,
                lvl,
                excel,
                catcode,
                showonweb,
                showonwebconvert,
                enrich,
                newproductnoly,
                editbydate,
                approvedbydate,
                writecontent,
                RowsOfPage,
                PageNumber,

            } = value

            getters.mode = mode;
            getters.find = find;
            getters.lvl = lvl;
            getters.excel = excel;
            getters.catcode = catcode;
            getters.showonweb = showonweb;
            getters.showonwebconvert = showonwebconvert;
            getters.enrich = enrich;
            getters.newproductnoly = newproductnoly;
            getters.editbydate = editbydate;
            getters.approvedbydate = approvedbydate;
            getters.writecontent = writecontent;
            getters.RowsOfPage = RowsOfPage;
            getters.PageNumber = PageNumber
            commit('mut_data_qr', getters)


        },
        async set_page_num({ commit }, value) {
            // console.log('----- set_page_num -----');
            // console.log(value);
            commit('mut_page_num', value)
        },
        async set_page_size({ commit }, value) {
            // console.log('----- set_page_size -----');
            // console.log(value);
            commit('mut_page_size', value)
        },
        async get_product({ commit }, value) {
            return new Promise(async (resolve, reject) => {
                try {
                    // console.log('----- get_product -----');
                    // console.log(value);
                    commit('mut_onload')
                    let getAPI = await serviceAPI.call_API(
                        "post",
                        `product/getpimproduct`,
                        value,
                        1
                    );
                    commit('mut_dataproduct', getAPI.data)
                    commit('mut_unload')
                    resolve(getAPI.data)
                } catch (error) {
                    console.log(error);
                    commit('mut_unload')
                    commit('mut_dataproduct', init.dataproduct)
                    reject(error)
                }
            })


        },


    },
};