<template>
  <div id="app" class="component-wrapper">
    <Header
      v-show="
        $store.getters['loginActions/GET_AUTH'] &&
          $route.name !== 'NotFound' &&
            $route.name !== 'NotFound404' &&
            $route.name !== 'ProductInfo-ProductQR-Preview'
      "
    />

    <b-breadcrumb
      v-show="
        $store.getters['loginActions/GET_AUTH'] &&
          $route.name !== 'NotFound' &&
          $route.name !== 'Home' &&
          $route.name !== 'NotRole' &&
          $route.name !== 'view Product' &&
          $route.name !== 'SelectBu'
      "
      class="m-0 pb-0 font-500 breadcrumb-bg "
      :items="$store.getters['breadcrumbActions/get_items']"
    ></b-breadcrumb>

    <b-overlay
      :show="$store.getters['loadingActions/GET_load']"
      spinner-variant="danger"
      spinner-type="grow"
      style="height: 100%; width: 100% ;"
    >
      <template #overlay>
        <div :class="[`sk-circle-${$store.getters['loginActions/GET_BU']}`]">
          <div class="sk-circle1 sk-child"></div>
          <div class="sk-circle2 sk-child"></div>
          <div class="sk-circle3 sk-child"></div>
          <div class="sk-circle4 sk-child"></div>
          <div class="sk-circle5 sk-child"></div>
          <div class="sk-circle6 sk-child"></div>
          <div class="sk-circle7 sk-child"></div>
          <div class="sk-circle8 sk-child"></div>
          <div class="sk-circle9 sk-child"></div>
          <div class="sk-circle10 sk-child"></div>
          <div class="sk-circle11 sk-child"></div>
          <div class="sk-circle12 sk-child"></div>
        </div>
        <!-- <div class="mainfont">Loading...</div> -->
      </template>

      <router-view></router-view>
    </b-overlay>
  </div>
</template>

<script>
import Header from "./components/layout/Header.vue";

export default {
  components: {
    Header,
  },
  data() {
    return {
      key: null,
      time: null,
      maincolor: "#b8292f",
      center: this.$serviceMain.parseJwtByLocalStorage("user"),
    };
  },
  watch: {
    check_role: function(v) {
      // this.get_role();
    },
  },
  mounted() {
    this.select();
    // this.get_role();
    this.changeFavicon();
  },
  computed: {
    check_role: function() {
      return this.$store.getters["loginActions/GET_USER"].role;
    },
  },
  methods: {
    select() {
      let menu = [];

      menu = this.$nav_menu;

      this.$store.dispatch("loginActions/setBu", { val: "PWB", menu });
      // this.checkBu();
    },
    get_role() {
      if (
        this.$store.getters["loginActions/GET_USER"].role !== undefined &&
        this.$store.getters["loginActions/GET_USER"].role.length == 0
      ) {
        this.$serviceMain.showErrorAlert(
          this,
          `กรุณาติดต่อผู้ดูเเล้วระบบเพื่อขอสิทธ์การใช้งาน / Please contact the administrator for access`
        );
      }
    },
    changeFavicon() {
      let favicon = document.querySelector('link[rel="icon"]');
      let bu = this.$store.getters["loginActions/GET_BU"];
      if (!favicon) {
        favicon = document.createElement("link");
        favicon.setAttribute("rel", "icon");
        favicon.setAttribute("type", "image/png");
        document.head.appendChild(favicon);
      }

      let url = "/favicon.ico";
      if (bu === "TWD") {
        url = "/twd.ico";
      } else if (bu === "AT1") {
        url = "/at1.ico";
      }
      favicon.setAttribute("href", url);
    },
  },
};
</script>

<style lang="scss">
.breadcrumb-bg {
  background-color: transparent !important;
}
//-------- tiny ----------
.mce-panel {
  border: 0 solid transparent !important;

  background-color: #fff !important;
}
//-------- end tiny ----------

// .input-group-text {
//   display: flex;
//   align-items: center;
//   padding: 0.375rem 0.75rem;
//   margin-bottom: 0;
//   font-size: 1rem;
//   font-weight: 400;
//   line-height: 1.5;
//   color: #495057 !important;
//   text-align: center;
//   white-space: nowrap;
//   background-color: #ffffff !important;
//   border: 1px solid #ced4da !important;
//   border-radius: 0.25rem;
// }

// --------------------scss---------------
$color-twd: #b8292f;
$color-bnb: #006e63;
$color-at1: #3866af;
$color-vfix: #ffc107;
$color-wow: #ee3038;
$color-df: #b8292f;
$color-dc: #b8292f;
$listBu: "TWD" $color-twd, "BNB" $color-bnb, "AT1" $color-at1,
  "VFIX" $color-vfix, "WOW" $color-wow, "null" $color-df, "DC" $color-dc,
  "HO" $color-dc;

$color-twdbg: #b8293010;
$color-bnbbg: #006e6311;
$color-at1bg: #3866af11;
$color-vfixbg: #ffc10721;
$color-wowbg: #ee303a15;
$color-dfbg: #b8293010;
$color-dcbg: #b8293010;
$listBubg: "TWD" $color-twdbg, "BNB" $color-bnbbg, "AT1" $color-at1bg,
  "VFIX" $color-vfixbg, "WOW" $color-wowbg, "null" $color-dfbg, "DC" $color-dcbg,
  "HO" $color-dcbg;
// ---------------------------------------------

@mixin titlenavleft($color) {
  color: $color !important;
  font-weight: bold;
  padding-top: 2px;
  margin-left: 8px;
}
@mixin bt($color) {
  background-color: $color !important;
  color: white !important;
  padding-left: 25px;
  padding-right: 25px;
}
@mixin border-l($color) {
  border-left: 3px solid $color !important;
}
@mixin border-r($color) {
  border-right: 3px solid $color !important;
}

@mixin border-b($color) {
  border-bottom: 1px solid $color !important;
}
@mixin bg($color) {
  background-color: $color;
}
// ---------------------------------------------

@each $buItemsbg in $listBubg {
  .bg-#{nth($buItemsbg, 1)} {
    @include bg(nth($buItemsbg, 2));
  }
}

@each $buItems in $listBu {
  .titlenavleft-#{nth($buItems, 1)} {
    @include titlenavleft(nth($buItems, 2));
  }

  .bt-#{nth($buItems, 1)} {
    @include bt(nth($buItems, 2));
  }
  .border-l-#{nth($buItems, 1)} {
    @include border-l(nth($buItems, 2));
  }
  .border-r-#{nth($buItems, 1)} {
    @include border-r(nth($buItems, 2));
  }
  .border-b-#{nth($buItems, 1)} {
    @include border-b(nth($buItems, 2));
  }
}
</style>
