import store from "../store/index";
import * as main from "../services/mainservice";
import * as constant from "../services/constant";
export function authrole(rountname) {
  return new Promise((resolve, reject) => {
    let getUser = main.readCookietoken(constant.TOKEN_KEY);

    let getrole = getUser.role;
    if (process.env.NODE_ENV == "dev") {
      console.log(rountname);
    }
    let check;
    switch (String(rountname).trim()) {
      case "Role-User":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Select Role";
        });
        break;
      case "Permission-User":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Select Permission";
        });
        break;
      case "ManageUser":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Select User";
        });
        break;
      case "PropertiesAttribute":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Select Properties";
        });
        break;
      case "Enrich Product":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Select Enrich Product";
        });
        break;
      case "view Product":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Select Enrich Product";
        });
        break;
      case "Enrich Product Detail":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Edit Enrich Product";
        });
        break;
      case "Remarks":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Select Remarks";
        });
        break;
      case "Manageremark":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Insert Remarks";
        });
        break;
      case "Data Conditions":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Setting Data Conditions";
        });
        break;
      case "Email":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Select Email";
        });
        break;
      case "ManageLinkContent":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Select LinkContent";
        });
        break;
      case "ManageVendor":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Select Contact Vendor";
        });
        break;
      case "On-Off-OnWeb":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "On-Off-OnWeb";
        });
        break;
      case "AddOnOff":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "On-Off-OnWeb";
        });
        break;
      case "HistoryLogin":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "IT";
        });
        break;
      case "ManageImage360":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Manage_img_360";
        });
        break;
      case "ManageImageBrand":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Manage_Image_Brand";
        });
        break;
      case "Tracking Supplier":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "View Tracking Supplier";
        });
        break;
      case "ManageRemarkMessage":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "ManageRemarkMessage";
        });
        break;
      case "ManagePimData":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "ManagePimData";
        });
        break;
      case "UploadAttributeEN":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "ManagePimData";
        });
        break;
      case "DownloadCSVData":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "DownloadCSVData";
        });
        break;
      case "Monitor-Enrich-SKU":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "IT";
        });
        break;
      case "Attribute-group":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Attribute-group";
        });
        break;
      case "Attribute-group-Create":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Attribute-group";
        });
        break;
      case "Attributes":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Attributes";
        });
        break;
      case "Attributes-Create":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Attributes";
        });
        break;
      case "Attributes-Edit":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Attributes";
        });
        break;
      case "Attribute-group-Edit":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Attributes";
        });
        break;
      case "Attributes-Products":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Attributes-Products";
        });
        break;
      case "Attributes-Products-Detail":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Attributes-Products";
        });
        break;
      case "Attributes-Products-Detail-v1":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "IT";
        });
        break;
      case "Attribute_Value_Product":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Attribute_Value_Product";
        });
        break;
      case "Mapping SPU":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Mapping_SPU";
        });
        break;
      case "Bought Together":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "BoughtTogether";
        });
        break;
      case "ProductOnWeb":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "ProductOnWeb";
        });
        break;
      case "CREATE-USER-LOGIN":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "IT";
        });
        break;
      case "ProductInfo-QR":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "ProductInfoQR";
        });
        break;
      case "ProductInfo-ProductQR":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "ProductInfoQR";
        });
        break;
      case "ProductInfo-ProductQR-Content":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "ProductInfoQR";
        });
        break;
      case "ProductInfo-ProductQR-Preview":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "ProductInfoQR";
        });
        break;
      case "ProductInfo-SKUQR":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "ProductInfoQR";
        });
        break;
      case "DeliveryMethod":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "DeliveryMethod";
        });
        break;
      case "Warranty":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Warranty";
        });
        break;
      case "UploadCategory":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "ManagePimData";
        });
        break;

      case "UploadSkuInstall":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Market Place";
        });
        break;
      case "CheckOrders":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Market Place";
        });
        break;
      case "CheckStatus":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Market Place";
        });
        break;
      case "UploadStockMKP_PreOrder":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Market Place";
        });
        break;
      case "UploadStockMKP_RedText":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Market Place";
        });
        break;
      case "OFM_ManageProduct":
        check = Object.values(getrole).find((obj) => {
          return String(obj.role_name).trim() == "Market Place";
        });
        break;
    }
    // console.log(check);
    resolve(check == undefined ? false : true);
  });
}
