<template>
  <div class="h-100 m-0 p-0">
    <!-- <canvas :id="id" ></canvas> -->
    <canvas class="bfont m-0 p-0 h-100" ref="ChartRef"  ></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
export default {
  // props: ["id","type", "data", "option"],
  props: ["type", "data", "option"],
  data: function () {
    return {
      // ChartData: {
      //   labels: ["0s", "10s", "20s", "30s", "40s", "50s", "60s"],
      //   datasets: this.dataset,
      // },
      // ChartOptions: {
      //   responsive: true,
      //   elements: {
      //     point: {
      //       radius: 0,
      //     },
      //   },
      //   legend: {
      //     display: true,
      //     position: "bottom",
      //     labels: {
      //       usePointStyle: true,
      //       fontColor: "#5A5F7D",
      //       color: true,
      //     },
      //   },
      // },
    };
  },
  mounted() {
    this.genChart();
  },
  methods: {
    async genChart() {
      let _this = this;
      let getuuid = this.ChartId;
      // let ctx = document.getElementById(getuuid).getContext('2d')
      let ctx = _this.$refs.ChartRef.getContext("2d");
      let bar = new Chart(ctx, {
        type: this.type,
        options: _this.option,
        data: _this.data,
      });
    },
    // genChart() {
    //   let _this = this;
    //   let ctx = document.getElementById(`${this.id}`).getContext("2d");
    //   let bar = new Chart(ctx, {
    //     type: this.type,
    //     options: _this.option,
    //     data: _this.data,
    //   });
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
</style>
